<template>
  <Modal
    :openModal="display"
    v-model="show"
    v-on:close-modal="$emit('close-modal')"
    :title="'Create Application'"
    class="col-6"
  >
    <template v-slot:content>
      <form
        autocomplete="off"
        action=""
        @submit.prevent="submitApplication"
        id="applicationForm"
      >
        <input type="hidden" autocomplete="false" />
        <ErrorAlert
          v-if="formErrors ? formErrors.length > 0 : false"
          :errors="formErrors"
          sticky
        />
        <div v-if="loanLimit" class="ml-2 mb-4">
          <b>
            <i> Loan Limit: {{ loanLimit.toLocaleString() }} </i>
          </b>
        </div>
        <span class="span">Select borrower</span>
        <Dropdown
          :options="options"
          v-on:selected="validateSelection"
          v-on:filter="getDropdownValues"
          :disabled="false"
          :maxItem="30"
          placeholder="Please select a borrower"
        >
        </Dropdown>

        <vue-form-generator
          :schema="formSchema"
          :model="formModel"
        ></vue-form-generator>
        <Button
          text="Submit"
          type="submit"
          styling="primary"
          validateBeforeSubmit
          :disabled="isDisabled"
        ></Button>
      </form>
    </template>
  </Modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Dropdown from 'vue-simple-search-dropdown';
import Modal from '../../components/Modal/Modal.vue';
import Button from '../../components/Button/Button.vue';
import {
  loanApplicationSchema,
  loanApplicationModel,
} from '../../formSchemas/applications/loanApplication';
import ErrorAlert from '../../components/Alert/ErrorAlert.vue';

export default {
  name: 'CreateApplication',
  emits: ['close-modal'],
  props: ['application', 'display'],
  components: { Modal, ErrorAlert, Button, Dropdown },
  data() {
    return {
      formModel: {},
      formSchema: {},
      formErrors: [],
      loanLimit: null,
      selected: '',
      options: [],
      isLoading: false,
    };
  },
  computed: {
    isDisabled() {
      return this.isLoading;
    },
    show: {
      get() {
        return this.display;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
    ...mapGetters('borrowers', ['borrowers']),
    schemaWithProducts() {
      const products = this.$store.getters['products/allProducts'];
      const productOptionsWithId = this.addIdToDropdownOptions(products);

      const finalData = {
        fields: [],
      };

      loanApplicationSchema.fields.forEach((field) => {
        if (field.label === 'Select product') {
          const { values, ...rest } = field;
          const newField = { ...rest, values: () => productOptionsWithId };
          finalData.fields.push(newField);
          return;
        }

        finalData.fields.push(field);
      });

      return finalData;
    },
  },
  async mounted() {
    await this.fetchBorrowers();
    await this.selectBorrower();
    await this.fetchProducts();
    this.formModel = loanApplicationModel;
    this.formSchema = this.schemaWithProducts;
  },
  methods: {
    ...mapActions('borrowers', ['fetchBorrowers']),
    ...mapActions('products', ['fetchProducts']),

    async selectBorrower() {
      const data = [];
      this.borrowers?.results.forEach((option) => {
        const id = option.ref;
        const { name, partner } = option;
        data.push({ id, name, partner });
      });
      this.options = data;
    },

    validateSelection(selection) {
      this.selected = selection;
    },

    async getDropdownValues(searchTerm) {
      const status = 'active';
      await this.fetchBorrowers({ status, searchTerm });
      await this.selectBorrower();
    },

    addIdToDropdownOptions(options) {
      const data = [];
      if (options && options.results) {
        options.results.forEach((option) => {
          const id = option.ref;
          const entry = { id, ...option };
          data.push(entry);
        });
      }
      return data;
    },
    submitApplication() {
      this.formErrors = [];
      const finalObject = this.formModel;
      finalObject.borrower = this.selected;
      this.isLoading = true;
      this.$store
        .dispatch(`applications/createApplication`, {
          data: finalObject,
        })
        .then(() => {
          this.isLoading = false;
          this.$emit('close-modal');
        })
        .catch((err) => {
          this.isLoading = false;
          this.formErrors = err;
        });
    },
  },
};
</script>

<style lang="scss">
.capitalise {
  text-transform: capitalize;
}
.span {
  margin-left: 5px;
}

.dropdown {
  display: block;
  margin-bottom: 12px !important;
  .dropdown-input {
    min-width: 100% !important;
    max-width: 100% !important;
    background-image: none !important;
    border-radius: 24px !important;
    border: 1px solid rgb(14, 151, 71) !important;
  }
}

#__lpform_dropdown_icon {
  display: none !important;
}
</style>
