<template>
  <Modal
    :title="'SMS ' + currentBorrower.name"
    :openModal="!!display"
    :width="600"
    v-on:close-modal="$emit('close-modal')"
  >
    <template v-slot:content>
      <div v-if="!formModel"><h2>No Action Available</h2></div>
      <div v-if="formModel">
        <form action="" @submit.prevent="send" id="smsForm">
          <ErrorAlert
            v-if="formErrors.length > 0"
            :errors="formErrors"
            sticky
          />
          <vue-form-generator
            :schema="formSchema"
            :model="formModel"
          ></vue-form-generator>
        </form>
      </div>
    </template>
    <template v-slot:footer>
      <Button
        text="Submit"
        type="submit"
        form="smsForm"
        styling="primary"
        :disabled="disabled"
        validateBeforeSubmit
      ></Button>
    </template>
  </Modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Modal from '../../../components/Modal/Modal.vue';
import Button from '../../../components/Button/Button.vue';
import ErrorAlert from '../../../components/Alert/ErrorAlert.vue';
import { schema, model } from '../../../formSchemas/sms/smsEditor';

export default {
  name: 'SmsTemplateSelectorModal',
  props: ['display'],
  emits: ['close-modal'],
  components: {
    Modal,
    Button,
    ErrorAlert,
  },
  data() {
    return {
      disabled: true,
      formSchema: schema,
      formModel: { ...model },
      formErrors: [],
    };
  },
  computed: {
    ...mapGetters('borrowers', ['currentBorrower']),
    ...mapGetters('smstemplates', ['smsTemplates']),
    ...mapGetters('borrowers', ['currentBorrower']),
  },
  methods: {
    ...mapActions('smstemplates', ['fetchSmsTemplates']),
    setTemplates() {
      const { fields } = this.formSchema;
      const modifiedFields = fields.map((field) => {
        const modifiedField = { ...field };
        if (modifiedField.label === 'Template') {
          const options = (this.smsTemplates?.results || []).map((t) => ({
            name: t.name,
            id: {
              message: t.content,
              id: t.id,
            },
          }));
          modifiedField.values = () => options;
        }
        return modifiedField;
      });
      this.formSchema.fields = modifiedFields;
    },
    send() {
      const formCopy = { ...this.formModel };
      this.$store.dispatch(`sms/sendSingleSms`, {
        data: {
          templateId: formCopy.templateId,
          borrowerRef: this.currentBorrower.ref,
        },
      });
      this.$emit('close-modal');
    },
  },

  watch: {
    // when modal opens, query to fetch templates is executed
    display: {
      async handler() {
        if (this.display) {
          await this.fetchSmsTemplates();
          this.setTemplates();
        } else {
          this.formModel = { ...model };
        }
      },
    },
    // when form is updated, form for message is filled
    formModel: {
      handler() {
        const selected = this.display ? this.formModel.template : {};
        this.formModel.message = selected.message || '';
        this.formModel.templateId = selected.id || '';

        this.disabled = false;
      },
      deep: true,
    },
  },
};
</script>
