<template>
  <div class="width100">
    <PageHeader headingText="Loan Terms"></PageHeader>
    <v-layout
      justify-center
      flex-column
      align-stretch
      class="ma-4"
      :key="componentKey"
    >
      <v-row align="center" justify="space-between" class="ma-auto col-10 pl-7">
        <v-col class="shrink">
          <v-btn class="ma-2" @click="backButton" light>
            <v-icon dark left> mdi-arrow-left </v-icon>Back
          </v-btn>
        </v-col>
        <v-col class="shrink">
          <v-btn color="primary" dark @click="downloadLoanTerms">
            Download Loan Terms
          </v-btn>
        </v-col>
      </v-row>
      <v-card
        v-show="true"
        id="loan-terms"
        elevation="2"
        class="ma-auto col-10 pl-7"
      >
        <v-card-title id="cardTitle" class="pt-0 pb-0">
          <v-img
            :src="require('../../assets/crediation_logo.gif')"
            max-height="75"
            :max-width="160"
            alt="crediation logo"
          ></v-img>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-list>
          <v-list-item>
            <v-list-item-title class="subTitle"
              ><u>Sale and Purchase Terms</u></v-list-item-title
            >
          </v-list-item>
          <v-list-item>
            <p>
              These Loan Sale and Purchase Terms together with Terms and
              Conditions and any other agreements, annexures and/or appendices
              referenced here to set out complete terms and conditions (“these
              Terms and Conditions”, “this Agreement”) agreed to by you and
              Crediation Limited (“Crediation Limited”, “Us”, “Our”) whose
              principal office is at Prime Office Suites, Along Kindaruma Road,
              Nairobi, Kenya, P.O Box 3085-00100 and {Insert Name of Retailer}
              (the “Retailer”, “Borrower”, “You”, “Your”) whose place of
              business is at {Insert physical location}
            </p>
          </v-list-item>
          <v-list-item>
            <h5>BECAUSE</h5>
          </v-list-item>
          <v-list-item>
            <p>
              This Agreement sets out the terms on which we will provide you a
              stock loan “CrediStock” in return for full payment of the Loan
              Repayment Amount and;
            </p>
          </v-list-item>
          <v-list-item>
            <h5>ACKNOWLEDGING</h5>
          </v-list-item>
          <v-list-item>
            <p>
              That the terms and definitions expressed herein shall have the
              same meanings as those expressed in the Terms and Conditions
              agreed by you;
            </p>
          </v-list-item>
          <v-list-item>
            <h5>IT IS THUS AGREED THAT</h5>
          </v-list-item>
          <v-list-item>
            <p>
              We will offer You a CrediStock loan, in exchange, You will pay us
              the full Repayment Amount subject to the Terms and Conditions and
              the terms specified below
            </p>
          </v-list-item>
          <ul v-for="loanTerm in loanTermsFields" :key="loanTerm.id">
            <li>
              {{ loanTerm.id }} {{ loanTerm.field }}
              {{ loanTerm.filedValue }}
            </li>
          </ul>
          <br />
          <v-list-item>
            <p>
              You warrant that you have read, understood and will adhere to
              these Terms and Conditions. Further, you warrant that you have
              accepted our standard terms and conditions. You certify that You,
              the person(s) listed below are the Authorized user(s) and that
              this Agreement is legally binding.
            </p>
          </v-list-item>
          <ul
            v-for="authorizedPerson in authorizedPersonDetails"
            :key="authorizedPerson.id"
          >
            <li>
              {{ authorizedPerson.field }}
              {{ authorizedPerson.filedValue }}
            </li>
          </ul>
        </v-list>
      </v-card>
    </v-layout>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import html2canvas from 'html2canvas';
import * as JsPDF from 'jspdf';

import PageHeader from '../../components/PageHeader/PageHeader.vue';
import { formatCurrency, unformatCurrency } from '../../utils/currency';
// import LoanTerms from './LoanTerms.vue';

export default {
  name: 'SingleLoan',
  components: {
    PageHeader,
  },
  props: {
    loanTerms: { type: Object },
    forLoanApproval: { type: Boolean, default: false },
    suppliedloanRef: { type: String, default: 'none' },
  },
  data() {
    return {
      item: null,
      now: new Date().toString().slice(0, 24),
      componentKey: 0,
      loanRef: this.$route.params.ref,
    };
  },
  computed: {
    ...mapGetters('auth', { isAdmin: 'isAdmin' }),
    ...mapGetters('loans', ['current_loan']),
    ...mapGetters('auth', { isSuperUser: 'isSuperUser' }),

    loanUrl() {
      return this.current_loan.url;
    },
    authorizedPersonDetails() {
      const borrowerName = this.current_loan.borrower.owner_name;
      const phoneNumber = this.current_loan.borrower.phone;
      return [
        {
          id: 9,
          field: 'Name :',
          filedValue:
            borrowerName === '' ? '________________________' : borrowerName,
        },
        {
          id: 10,
          field: 'ID Number :',
          filedValue:
            this.current_loan.borrower.national_id_number === ''
              ? '_____________________'
              : this.current_loan.borrower.national_id_number,
        },
        {
          id: 11,
          field: 'Phone Number :',
          filedValue: phoneNumber,
        },
        {
          id: 12,
          field: 'Time Stamp :',
          filedValue: this.current_loan.disbursement_date,
        },
      ];
    },
    loanTermsFields() {
      const borrowerName = this.current_loan.borrower.name;
      const phoneNumber = this.current_loan.borrower.phone;
      const interestRate = this.current_loan.interest_rate;
      const totalRepayment = formatCurrency(
        unformatCurrency(this.current_loan.outstanding_interest) +
          unformatCurrency(this.current_loan.principal),
      );
      return [
        {
          id: 1,
          field: 'Retailer/Borrower :',
          filedValue: borrowerName,
        },
        {
          id: 2,
          field: 'Contact Person :',
          filedValue: borrowerName,
        },
        {
          id: 3,
          field: 'Contact Number :',
          filedValue: phoneNumber,
        },
        {
          id: 4,
          field: 'Loan Principal :',
          filedValue: this.current_loan.principal,
        },
        {
          id: 5,
          field: 'Loan Duration(Days) :',
          filedValue: this.current_loan.loan_period,
        },
        {
          id: 6,
          field: 'Cost (Interest) :',
          filedValue: `${interestRate}%`,
        },
        {
          id: 7,
          field: 'Total Repayment Amount :',
          filedValue: totalRepayment,
        },
      ];
    },
  },
  beforeMount() {
    this.fetchLoan(this.loanRef);
  },
  methods: {
    ...mapActions('loans', ['fetchLoan']),

    backButton() {
      window.history.back();
    },
    forceRerender() {
      this.componentKey += 1;
    },
    downloadLoanTerms() {
      this.expand = !this.expand;
      html2canvas(document.querySelector('#loan-terms'), {
        imageTimeout: 5000,
        useCORS: false,
      }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const imgWidth = 210;
        const pageHeight = 295;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        // eslint-disable-next-line new-cap
        const doc = new JsPDF.default();
        let position = 0;
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
        while (heightLeft >= 0) {
          position += heightLeft - imgHeight; // top padding for other pages
          doc.addPage();
          doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }
        doc.save(`${this.loanRef}_loan_terms.pdf`);
      });
    },
  },
};
</script>

<style>
#borrowerDetailsTable {
  border: 0px;
}

#cardTitle {
  border-bottom: 0px;
}

.subTitle {
  text-align: center;
  font-weight: bold;
}
p {
  font-size: 12px;
}
ul {
  list-style: none;
  font-size: 14px;
  font-weight: 500;
  padding-block: 2px;
  margin-left: 5px;
}
h5,
li {
  font-size: 13px;
}
</style>
