const genericVettingSchema = {
  fields: [
    {
      type: 'select',
      label: 'Would you like to approve this application?',
      model: 'isLoanApproved',
      required: true,
      values: [
        {
          id: 'approved',
          name: 'Approve',
        },
        {
          id: 'rejected',
          name: 'Reject',
        },
      ],
    },
    {
      type: 'input',
      inputType: 'text',
      label: 'Reason For Rejection (Optional)',
      model: 'rejectDesc',
      placeholder: 'Some information is not valid',

      visible(model) {
        if (!model) return true;
        return model.isLoanApproved === 'rejected';
      },
    },
  ],
};

const genericVettingModel = {
  isLoanApproved: '',
  rejectDesc: '',
};

const withdrawalSchema = {
  fields: [
    {
      type: 'input',
      inputType: 'text',
      label: 'Reason For Withdrawal',
      model: 'rejectDesc',
      placeholder: 'Some information on withdrawal reasons',
      required: true,
    },
  ],
};

export { genericVettingSchema, genericVettingModel, withdrawalSchema };
