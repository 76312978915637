<template>
  <Modal
    :title="title"
    :openModal="display"
    v-on:close-modal="$emit('close-modal')"
  >
    <template v-slot:content>
      <div v-if="!formModel"><h2>No Action Available</h2></div>
      <div v-if="formModel">
        <form action="" @submit.prevent="submitComment" id="commentForm">
          <ErrorAlert
            v-if="formErrors.length > 0"
            :errors="formErrors"
            sticky
          />
          <vue-form-generator
            :schema="formSchema"
            :model="formModel"
          ></vue-form-generator>
        </form>
      </div>
    </template>
    <template v-slot:footer>
      <Button
        text="Submit"
        type="submit"
        form="commentForm"
        styling="primary"
        :disabled="disabled"
        validateBeforeSubmit
      ></Button>
    </template>
  </Modal>
</template>

<script>
/* eslint-disable camelcase */
import Modal from '../../components/Modal/Modal.vue';
import Button from '../../components/Button/Button.vue';
import ErrorAlert from '../../components/Alert/ErrorAlert.vue';
import { unformatCurrency } from '../../utils/currency';
import {
  commentModel,
  commentSchema,
} from '../../formSchemas/borrower-profile/commentForm';
import {
  feedbackModel,
  feedbackSchema,
} from '../../formSchemas/borrower-profile/feedbackForm';

export default {
  name: 'CommentFormModal',
  emits: ['close-modal'],
  props: ['comment', 'commentType', 'resourceRef', 'display'],
  components: {
    Modal,
    Button,
    ErrorAlert,
  },
  data() {
    return {
      disabled: false,
      formSchema: {},
      formModel: {},
      formErrors: [],
    };
  },
  computed: {
    changeData() {
      const { comment, commentType } = this;
      return {
        commentType,
        comment,
      };
    },
    title() {
      const action = this.comment !== null ? 'Edit' : 'Add';
      const comment_type = this.commentType;
      const resource =
        this.commentType === 'admin comment' ? 'borrower' : 'loan';

      return `${action} ${comment_type} under this ${resource}`;
    },
  },
  watch: {
    changeData: {
      handler() {
        this.updateFormState();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    updateFormState() {
      this.formSchema =
        this.commentType === 'feedback' ? feedbackSchema : commentSchema;
      this.formModel =
        this.commentType === 'feedback' ? feedbackModel : commentModel;
      if (this.comment !== null) {
        this.formModel = { ...this.comment };
      }
    },
    submitComment() {
      this.disabled = true;
      this.formErrors = [];
      const formCopy = { ...this.formModel };
      const { comment, link, id } = formCopy;
      const resourceType =
        this.commentType === 'admin comment' ? 'borrowers' : 'loans';
      const resource = `${process.env.VUE_APP_BASE_URL}/api/v2/${resourceType}/${this.resourceRef}`;
      const formData = new FormData();
      formData.set('comment', comment);
      formData.set('link', link);
      formData.set('resource', resource);

      if (this.commentType === 'feedback') {
        const feedbackFields = [
          'contact_method',
          'contact_reason',
          'ptp_booked',
          'next_contact_date',
          'ptp_due',
          'summary',
          'ptp_amount',
        ];
        const setFormIfValue = (key) => {
          if (formCopy[key] !== null) {
            if (key === 'ptp_amount') {
              formCopy[key] = unformatCurrency(formCopy[key]);
            }
            formData.set(key, formCopy[key]);
          }
        };
        feedbackFields.forEach(setFormIfValue);
        formData.set('type', 'Feedback');
      }

      const method = id ? 'PATCH' : 'POST';

      const action =
        this.commentType === 'admin comment'
          ? 'borrowers/borrowerCommentCreateOrEdit'
          : 'borrowers/loanCommentCreateOrEdit';
      this.$store
        .dispatch(action, {
          data: formData,
          method,
          id,
        })
        .then(() => {
          this.$emit('close-modal');
          this.disabled = false;
        })
        .catch((err) => {
          this.formErrors = err;
          this.disabled = false;
        });
    },
  },
};
</script>
