const outcomeOptions = [
  'Third party picked up',
  'Debtor Switched off during conversation',
  'Debtor not responding to calls/smses',
  'Debtor disputed',
  'Debtor deceased',
  'Promised to pay without a specific date',
  'Promised to pay with a specific date',
  'Phone switched off, did not go through',
  'Confirmed earlier commitment made / Agreed on',
  'Paid',
];

const reasonsForCalling = [
  'Follow up on overdue Balance',
  'Follow up on missed/booked ptp',
  'Debtor Reached out',
  'Customer Relations Engagement',
  'Reminder for upcoming loan due',
];

const feedbackSchema = {
  fields: [
    {
      type: 'select',
      label: 'Contact Method',
      model: 'contact_method',
      placeholder: 'Select contact method',
      values: ['Call', 'Text', 'Field Visit'],
      default: 'Call',
      required: true,
      validator: ['required'],
    },
    {
      type: 'select',
      label: 'Contact Reason',
      model: 'contact_reason',
      placeholder: 'Enter contact reason',
      values: reasonsForCalling,
      default: reasonsForCalling[0],
      required: true,
      validator: ['required'],
    },
    {
      type: 'select',
      label: 'Outcome',
      model: 'comment',
      placeholder: 'Enter outcome of engagement',
      values: outcomeOptions,
      default: outcomeOptions[0],
      required: true,
      validator: ['required'],
    },
    {
      type: 'textArea',
      inputType: 'text',
      label: 'Summary',
      model: 'summary',
      placeholder: 'Add a summary',
      required: false,
      max: 1000,
      min: 5,
    },
    {
      type: 'input',
      inputType: 'text',
      label: 'Link',
      model: 'link',
      placeholder: 'Enter a link to any documents',
      required: false,
      max: 150,
      min: 5,
    },
    {
      type: 'input',
      inputType: 'date',
      label: 'Next Contact Date',
      model: 'next_contact_date',
      required: false,
      format: 'YYYY-MM-DD',
    },
    {
      type: 'checkbox',
      label: 'PTP Booked',
      model: 'ptp_booked',
      required: false,
    },
    {
      type: 'input',
      inputType: 'text',
      label: 'PTP Amount',
      model: 'ptp_amount',
      required: false,
      placeholder: 'E.g. 5000',
    },
    {
      type: 'input',
      inputType: 'date',
      label: 'PTP Due Date',
      model: 'ptp_due',
      validator: ['required'],
      format: 'YYYY-MM-DD',
    },
  ],
};

const feedbackModel = {
  id: '',
  comment: '',
  link: '',
  resource: '',
  created_at: '',
  contact_method: '',
  contact_reason: '',
  next_contact_date: '',
  ptp_amount: '',
  ptp_due: '',
  ptp_booked: '',
};

export { feedbackModel, feedbackSchema };
