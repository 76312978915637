<template>
  <v-app>
    <v-container>
      <NavBar v-if="isLoggedIn"></NavBar>
      <SnackBar v-bind="snackbarDisplay"></SnackBar>
      <vue-confirm-dialog></vue-confirm-dialog>
      <v-main>
        <router-view></router-view>
      </v-main>
    </v-container>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import SnackBar from './components/SnackBar/SnackBar.vue';
import NavBar from './components/NavBar/NavBar.vue';

export default {
  name: 'app',
  components: {
    SnackBar,
    NavBar,
  },
  computed: {
    ...mapGetters('common', { snackbarDisplay: 'snackbarDisplay' }),
    ...mapGetters('auth', { isLoggedIn: 'isLoggedIn' }),
    ...mapGetters('auth', { isSuperUser: 'isSuperUser' }),
    ...mapGetters('auth', { isAdmin: 'isAdmin' }),
  },
};
</script>

<style></style>
