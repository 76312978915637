<template>
  <div class="width100">
    <PageHeader headingText="Leads Management"></PageHeader>
    <v-layout justify-center flex-column align-stretch class="ma-4">
      <v-tabs v-model="tab" class="col-12">
        <v-tab
          v-for="item in tabItems"
          :key="item.tab"
          @click="changeTab(item.tab, item.firstClick)"
          >{{ item.tab }}</v-tab
        >
      </v-tabs>
      <v-tabs-items v-model="tab" class="ma-4">
        <v-tab-item v-for="item in tabItems" :key="item.tab">
          <v-card flat>
            <Table
              :tableData="leads.results"
              :options.sync="options"
              :serverItemsLength="leads.count"
              :itemsPerPage="leads.per_page"
              :headers="headers"
              v-on:updated-options="fetchLeadsWithPage"
              class="ma-auto"
            >
              <template v-slot:actions="slotProps">
                <v-icon
                  small
                  class="mr-1"
                  @click="openLeadDetails(slotProps.item)"
                  style="font-size: 20px"
                  color="green"
                >
                  mdi-account-details-outline
                </v-icon>
              </template>
            </Table>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-layout>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import PageHeader from '../../components/PageHeader/PageHeader.vue';
import Table from '../../components/Table/Table.vue';

export default {
  name: 'Leads',
  components: {
    PageHeader,
    Table,
  },
  data() {
    return {
      tab: null,
      options: {},
      headers: [
        {
          value: 'name',
          text: 'Full Name',
          sortable: true,
          searchable: true,
        },
        {
          value: 'phone',
          text: 'Phone Number',
          sortable: true,
          searchable: true,
        },
        {
          value: 'business_name',
          text: 'Business Name',
          sortable: true,
          searchable: true,
        },
        {
          value: 'business_location',
          text: 'Location',
          sortable: true,
          searchable: true,
        },
        {
          value: 'distributor',
          text: 'Distributor',
          sortable: true,
          searchable: true,
        },
        {
          value: 'status',
          text: 'Status',
          sortable: true,
          searchable: true,
        },
        {
          value: 'action',
          text: 'Details',
          sortable: true,
          searchable: true,
        },
      ],
      tabItems: [
        {
          tab: 'pending',
          firstClick: true,
        },
        {
          tab: 'approved',
          firstClick: true,
        },
        {
          tab: 'completed',
          firstClick: true,
        },
        {
          tab: 'rejected',
          firstClick: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters('leads', ['leads']),
  },
  methods: {
    ...mapActions('leads', ['fetchLeads']),

    async fetchLeadsWithPage(options) {
      this.tabItems[this.tab].firstClick = false;
      const status = this.tabItems[this.tab].tab;
      const { page } = options;
      await this.fetchLeads({ status, page, searchTerm: this.searchTerm });
    },

    async changeTab(status, firstClick) {
      if (firstClick) {
        return;
      }
      const page = 1;
      await this.fetchLeads({ status, page, searchTerm: this.searchTerm });
    },

    openLeadDetails(item) {
      const dataToDisplay = item;
      this.$router.push({
        name: 'leadDetails',
        params: { dataToDisplay },
      });
    },
  },
};
</script>
<style scoped>
.v-tabs {
  padding: 0 16px 0;
}
</style>
