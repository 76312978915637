import Vue from 'vue';

const getLeads = (status = '', page = 1, searchTerm = '') => {
  let url = `/api/v2/leads?status=${status}&page=${page}`;
  if (searchTerm) {
    url = `${url}&search=${searchTerm}`;
  }
  return Vue.http.get(url);
};

const approveLead = (id, comment) => {
  const url = `/api/v2/leads/${id}/approve`;
  const method = 'PATCH';
  return Vue.http({
    url,
    method,
    data: { comment },
  });
};

const completeLead = (id, comment) => {
  const url = `/api/v2/leads/${id}/complete`;
  const method = 'PATCH';
  return Vue.http({
    url,
    method,
    data: { comment },
  });
};

const rejectLead = (id, comment) => {
  const url = `/api/v2/leads/${id}/reject`;
  const method = 'PATCH';
  return Vue.http({
    url,
    method,
    data: { comment },
  });
};

const sendLinkToLead = (id) => {
  const url = `/api/v2/leads/${id}/send_onboarding_link`;
  const method = 'PATCH';
  return Vue.http({
    url,
    method,
  });
};

export { getLeads, approveLead, rejectLead, completeLead, sendLinkToLead };
