import {
  getMpesaRepayments,
  reconMpesaPayment,
} from '../api/mpesapayments.api';
import { formatCurrency } from '../utils/currency';

const state = () => ({
  mpesaPayments: {
    count: 0,
    links: {
      next: null,
      previous: null,
    },
    pages: 1,
    per_page: 30,
    results: [],
  },
});

const getters = {
  mpesapayments: (state) => state.mpesaPayments,
};

const actions = {
  async fetchMpesaPayments({ commit }, payload = {}) {
    commit('initial_state');
    const { page, searchTerm, searchParams } = payload;
    try {
      commit('common/data_requested', null, { root: true });
      const response = await getMpesaRepayments(page, searchTerm, searchParams);
      const data = [];
      response.data.results.forEach((repayment) => {
        const item = repayment;
        item.timestamp = repayment.timestamp
          ? new Date(repayment.timestamp).toDateString()
          : '-';
        item.completed_date = repayment.completed_date
          ? new Date(repayment.completed_date).toDateString()
          : '-';
        data.push({ ...repayment });
      });
      commit('set_mpesapayments', response.data);
    } catch (error) {
      commit(
        'common/error_snackbar',
        'Failed to fetch Mpesa Repayments. Please try again later.',
        {
          root: true,
        },
      );
    } finally {
      commit('common/response_received', null, { root: true });
    }
  },
  async reconPayment({ commit }, { data, ref }) {
    try {
      const payload = {
        data,
        ref,
      };
      const resp = await reconMpesaPayment(payload);
      commit('completed_mpesa_payment', resp.data);
      commit(
        'common/success_snackbar',
        'Mpesa payment reconcilled successfully',
        {
          root: true,
        },
      );
    } catch (errors) {
      const error = errors.response.data;
      throw Array.isArray(error) ? error : [error];
    }
  },
};

const mutations = {
  initial_state(state) {
    state.mpesaPayments = {
      count: 0,
      links: {
        next: null,
        previous: null,
      },
      pages: 1,
      per_page: 30,
      results: [],
    };
  },
  set_mpesapayments(state, data) {
    // eslint-disable-next-line no-param-reassign
    data.results = data.results.map((value) => {
      const payment = { ...value };
      payment.amount = formatCurrency(value.amount);
      return payment;
    });
    state.mpesaPayments = data;
  },
  completed_mpesa_payment(state, data) {
    state.mpesaPayments.results = state.mpesaPayments.results.map((value) => {
      let paymentItem = value;
      if (paymentItem.merchant_request_id === data.merchant_request_id) {
        paymentItem = { ...paymentItem, ...data };
      }
      return paymentItem;
    });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
