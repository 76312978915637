import Vue from 'vue';

const getDSRs = (page = 1, searchTerm = '') => {
  let url = `/api/v2/users?page=${page}`;
  if (searchTerm) {
    url = `/api/v2/users/${searchTerm}`;
  }
  return Vue.http.get(url);
};

const createOrEditDSR = (data, method, ref) =>
  Vue.http({
    url: `/api/v2/users${method === 'PATCH' ? `/${ref}` : ''}`,
    method,
    data,
  });

const getSingleDSR = (ref) => {
  return Vue.http.get(`/api/v2/users/${ref}`);
};

const getDSRBorrowers = (phone, searchTerm, status) => {
  let url = `/api/v2/borrowers?sales_rep_phone=${phone}`;
  if (searchTerm) {
    url = `${url}&searchTerm=${searchTerm}`;
  }
  if (status) {
    url += `&status=${status}`;
  }
  return Vue.http.get(url);
};

const deactivateOrActivateDSR = (id, action) => {
  let actionType;
  if (action === 'activate') {
    actionType = 'activate';
  } else {
    actionType = 'deactivate';
  }
  const url = `/api/v2/users/${actionType}?id=${id}`;
  const method = 'PATCH';
  return Vue.http({
    url,
    method,
  });
};

export {
  getDSRs,
  getSingleDSR,
  createOrEditDSR,
  getDSRBorrowers,
  deactivateOrActivateDSR,
};
