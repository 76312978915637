<template>
  <div class="width100">
    <PageHeader
      headingText="SMS Templates"
      :breadcrumbs="getBreadCrumbs"
    ></PageHeader>
    <v-layout justify-center flex-column align-stretch class="ma-4">
      <v-card class="pa-5 pl-3">
        <v-card-title class="pt-0">
          {{ title }}
          <v-spacer></v-spacer>
          <v-btn color="primary" dark text @click="viewTemplate"> View </v-btn>
        </v-card-title>
        <template>
          <div v-if="!formModel"><h2>No Action Available</h2></div>
          <form
            v-if="formModel"
            class="pa-4"
            action=""
            @submit.prevent="submitTemplate"
            id="SmsTemplateForm"
          >
            <ErrorAlert
              v-if="formErrors.length > 0"
              :errors="formErrors"
              sticky
            />
            <vue-form-generator
              :schema="formSchema"
              :model="formModel"
            ></vue-form-generator>
            <div>
              Character Count: {{ wordCount }} (Fits:
              {{ smsCount }}
              SMS)
            </div>

            <Button
              text="Submit"
              type="submit"
              form="SmsTemplateForm"
              styling="primary"
              validateBeforeSubmit
            ></Button>
          </form>
        </template>

        <v-layout column class="pa-5 pt-2 border">
          <p class="key-title">"Click To Copy" Template Keys</p>
          <v-alert dense text type="warning" class="alert"
            >These are keys that will be automatically replaced based on the
            borrower. <br />
            - Borrower Keys are used for all template categories.
            <br />
            - Loan Keys are used for templates in the Debt Collection & PTP
            category. <br />
            - PTP Keys are used specifically for templates in the PTP category.
          </v-alert>
          <div
            class="pb-4"
            v-for="(keyType, index) in Object.keys(templateKeys)"
            :key="index"
          >
            <small>{{ keyType }}</small>
            <br />
            <v-chip
              v-for="(item, index) in templateKeys[keyType]"
              @click="copyText(item)"
              :key="index"
              color="#ddd"
              class="pa-4 mr-1 mb-1"
            >
              {{ item }}
            </v-chip>
          </div>
        </v-layout>
      </v-card>
    </v-layout>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Button from '../../components/Button/Button.vue';
import ErrorAlert from '../../components/Alert/ErrorAlert.vue';
import PageHeader from '../../components/PageHeader/PageHeader.vue';
import {
  smsTemplateModel,
  smsTemplateSchema,
  templateKeys,
} from '../../formSchemas/sms/smsTemplateForm';

const initialData = () => ({
  templateKeys,
  formErrors: [],
  options: [],
  formModel: smsTemplateModel,
  formSchema: smsTemplateSchema,
  maxCharactersPerSMS: 160,
  title: 'Create New SMS template',
});

export default {
  name: 'TemplateEditor',
  components: {
    Button,
    ErrorAlert,
    PageHeader,
  },
  data() {
    const { templateId } = this.$route.params;
    return {
      ...initialData(),
      templateId,
    };
  },
  async beforeMount() {
    if (this.templateId) {
      await this.fetchTemplate(this.templateId);
      this.title = `Edit "${this.currentTemplate.name}" Template`;
      this.formModel = { ...this.currentTemplate };
    } else {
      await this.$store.dispatch(`smstemplates/resetCurrentTemplate`);
    }
  },
  methods: {
    ...mapActions('smstemplates', ['fetchTemplate']),
    async copyText(text) {
      await navigator.clipboard.writeText(text);
    },
    viewTemplate() {
      this.$router.push({
        path: `/smstemplates/view/${this.currentTemplate.id}`,
        params: { ref: this.currentTemplate.id },
      });
    },
    submitTemplate() {
      this.formErrors = [];
      const formCopy = { ...this.formModel };

      const method = formCopy.url ? 'PATCH' : 'POST';
      const id = formCopy.url ? formCopy.url.split('/').pop() : null;
      this.$store
        .dispatch(`smstemplates/createOrEditTemplate`, {
          data: formCopy,
          method,
          id,
        })
        .then(() => {
          if (!this.templateId) {
            this.$router.push('/smstemplates');
          }
        })
        .catch((error) => {
          this.formErrors = error.response.data;
        });
    },
  },

  computed: {
    ...mapGetters('auth', { isSuperUser: 'isSuperUser' }),
    ...mapGetters('smstemplates', ['currentTemplate']),
    getBreadCrumbs() {
      return [
        { text: 'SMS Template', disabled: false, href: '#/smstemplates' },
        {
          text: this.templateId ? `${this.currentTemplate.name}` : 'Create',
          disabled: true,
          href: '',
        },
      ];
    },
    smsCount() {
      if (this.formModel.content.isEmpty) {
        return 0;
      }
      const smsCount = Math.ceil(
        this.formModel.content.length / this.maxCharactersPerSMS,
      );
      return smsCount > 0 ? smsCount : 1;
    },
    wordCount() {
      if (this.formModel.content) {
        return this.formModel.content.length;
      }
      return 0;
    },
  },

  watch: {
    template: {
      handler() {
        this.checkTemplate();
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.content {
  margin-right: 30px;
  padding-bottom: 30px;
}
.light {
  color: #8c98a4;
}
.dark {
  font-weight: 500;
}
.details {
  font-size: 15px;
  padding-right: 50px;
}
.border {
  margin: 40px 10px 0;
  border-radius: 4px;
  background: #f7f7f7;
}
.key-title {
  margin: 20px 0;
  font-size: 16px;
  font-weight: bolder;
  color: #555;
}
.alert {
  font-size: 14px;
}
</style>
