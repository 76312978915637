import {
  getLeads,
  approveLead,
  rejectLead,
  sendLinkToLead,
  completeLead,
} from '../api/leads.api';

const state = () => ({
  leads: {
    count: 0,
    link: {
      next: null,
      previous: null,
    },
    pages: 1,
    per_page: 30,
    results: [],
  },
});

const getters = {
  leads: (state) => state.leads,
};

const actions = {
  async fetchLeads({ commit }, payload = {}) {
    commit('initial_state');
    const { status, page, searchTerm } = payload;
    try {
      commit('common/data_requested', null, { root: true });
      const response = await getLeads(status, page, searchTerm);
      commit('set_leads', response.data);
    } catch (error) {
      commit(
        'common/error_snackbar',
        'Failed to fetch Leads. Please try again later.',
        {
          root: true,
        },
      );
    } finally {
      commit('common/response_received', null, { root: true });
    }
  },
  async approveLead({ commit }, { id, comment }) {
    try {
      const response = await approveLead(id, comment);
      commit('update_lead', response.data);
      commit(
        'common/success_snackbar',
        `${response.data.name} has been approved successfully`,
        {
          root: true,
        },
      );
    } catch (errors) {
      const error = errors.response.data;
      throw Array.isArray(error) ? error : [error];
    }
  },
  async rejectLead({ commit }, { id, comment }) {
    try {
      const response = await rejectLead(id, comment);
      commit('update_lead', response.data);
      commit(
        'common/success_snackbar',
        `${response.data.name} has been rejected successfully`,
        {
          root: true,
        },
      );
    } catch (errors) {
      const error = errors.response.data;
      throw Array.isArray(error) ? error : [error];
    }
  },
  async onboardLead({ commit }, { id }) {
    try {
      const response = await sendLinkToLead(id);
      commit('update_lead', response.data);
      commit(
        'common/success_snackbar',
        `Onboarding link has been sent successfully to ${response.data.name}`,
        {
          root: true,
        },
      );
    } catch (errors) {
      const error = errors.response.data;
      throw Array.isArray(error) ? error : [error];
    }
  },
  async completeLead({ commit }, { id }) {
    try {
      const response = await completeLead(id);
      commit('update_lead', response.data);
      commit(
        'common/success_snackbar',
        `Lead account ${response.data.name} completed successfully.`,
        {
          root: true,
        },
      );
    } catch (errors) {
      const error = errors.response.data;
      throw Array.isArray(error) ? error : [error];
    }
  },
};

const mutations = {
  initial_state(state) {
    state.leads = {
      count: 0,
      links: {
        next: null,
        previous: null,
      },
      pages: 1,
      per_page: 30,
      results: [],
    };
  },
  set_leads(state, data) {
    state.leads = data;
  },
  update_lead(state, data) {
    state.leads.results = state.leads.results.map((value) => {
      let account = value;
      if (account.id === data.id) {
        account = { ...account, ...data };
      }
      return account;
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
