export default {
  fields: [
    {
      type: 'input',
      inputType: 'text',
      label: 'Username',
      model: 'username',
      required: true,
      validator: ['required'],
    },
    {
      type: 'input',
      inputType: 'password',
      label: 'Password',
      model: 'password',
      placeholder: '***********',
      required: true,
      validator: ['required'],
      min: 6,
      max: 128,
    },
  ],
};
