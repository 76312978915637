export default {
  fields: [
    {
      type: 'select',
      label: 'Status',
      model: 'status',
      values: () => {
        return [
          { id: 'pending', name: 'Pending' },
          { id: 'disbursed', name: 'Disbursed' },
          { id: 'need_more_info', name: 'Need More Info' },
          { id: 'rejected', name: 'Rejected' },
          { id: 'approved', name: 'Approved' },
          { id: '', name: 'All Statuses' },
        ];
      },
      default: '',
    },
  ],
};
