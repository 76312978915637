<template>
  <div class="width100">
    <PageHeader
      :headingText="'SMS Management'"
      :btnText="btnText"
      v-on:action-button-clicked="openTemplateForm()"
    ></PageHeader>
    <v-layout justify-center flex-column align-stretch class="ma-4">
      <!-- SEARCH FIELDS -->
      <v-text-field
        v-model="searchTerm"
        placeholder="Enter a search term"
        append-outer-icon="search"
        clearable
      >
      </v-text-field>
      <v-tabs v-model="tab" class="col-12">
        <template>
          <v-tab @click="setSelectedComponent('SMS Templates')"
            >SMS Tempates</v-tab
          >
          <v-tab @click="setSelectedComponent('Scheduled SMSs')"
            >Scheduled</v-tab
          >
          <v-tab-item>
            <Templates v-if="selectedComponent === 'SMS Templates'">
            </Templates>
          </v-tab-item>

          <v-tab-item>
            <ScheduledSmss
              v-if="selectedComponent === 'Scheduled SMSs'"
            ></ScheduledSmss>
          </v-tab-item>
        </template>
      </v-tabs>
    </v-layout>
  </div>
</template>

<script>
import PageHeader from '../../components/PageHeader/PageHeader.vue';
import Templates from './SmsTemplates.vue';
import ScheduledSmss from './ScheduledSmss.vue';

export default {
  name: 'SMSManagement',
  components: {
    PageHeader,
    Templates,
    ScheduledSmss,
  },
  data() {
    return {
      searchTerm: '',
      tab: null,
      selectedComponent: 'SMS Templates',
      borrowerRef: this.$route.params.ref,
      breadcrumbs: [
        { text: 'Borrower', disabled: false, href: '#/accounts' },
        {
          text: this.$route.params.ref,
          disabled: true,
          href: '',
        },
      ],
    };
  },

  methods: {
    setSelectedComponent(component) {
      this.selectedComponent = component;
    },
    openTemplateForm() {
      this.$router.push(`/smstemplates/create`);
    },
  },
  computed: {
    btnText() {
      return this.selectedComponent === 'SMS Templates'
        ? 'Add SMS Template'
        : '';
    },
  },
};
</script>

<style scoped>
.v-text-field {
  width: 50%;
}
.v-input {
  padding-left: 16px;
}
.v-tabs {
  padding: 0 16px 0;
}
</style>
