<template>
  <div class="width100">
    <v-card-title class="pl-5 d-flex justify-space-between">
      {{ currentBorrower.name }} Documents
      <Button
        text="Upload Kyc File"
        color="primary"
        v-on:button-clicked="openKycFileForm"
      ></Button>
    </v-card-title>

    <Table
      :tableData="getBusinessFiles.results"
      :options.sync="options"
      :serverItemsLength="getBusinessFiles.count"
      :itemsPerPage="getBusinessFiles.per_page"
      :headers="headers"
      v-on:updated-options="fetchBusinessFilesWithPage(borrowerRef)"
      class="ma-auto"
    >
      <template v-slot:actions="slotProps">
        <v-btn
          class="no-uppercase"
          @click="openKycFileForm(slotProps.item)"
          text
        >
          Edit
        </v-btn>
        <v-btn
          class="no-uppercase"
          @click="downloadAFile(slotProps.item)"
          text
          v-bind:id="slotProps.item.id"
          v-bind:fileName="slotProps.item.fileNameWithExtension"
        >
          View Details
        </v-btn>
      </template>
    </Table>
    <KycFileForm
      :fileItem="fileProp"
      :display="showFormModal"
      :borrowerDetails="borrowerDetails"
      v-on:close-modal="showFormModal = false"
    ></KycFileForm>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Table from '../../components/Table/Table.vue';
import Button from '../../components/Button/Button.vue';
import KycFileForm from './KycFileForm.vue';

export default {
  name: 'Accounts',
  components: {
    Table,
    Button,
    KycFileForm,
  },
  props: ['borrowerRef'],
  data() {
    return {
      options: {},
      searchTerm: '',
      ref: '',
      createButtonText: null,
      borrowerDetails: {},
      fileProp: null,
      showFormModal: false,
      headers: [
        {
          value: 'fileName',
          text: 'Document Name',
        },
        {
          value: 'file_type',
          text: 'Document Type',
        },
        {
          value: 'status',
          text: 'Validity Status',
        },
        {
          value: 'createdAt',
          text: 'Date Created',
        },
        {
          value: 'validity_date',
          text: 'Expiry date',
        },
        {
          value: 'action',
          text: 'Actions',
        },
      ],
    };
  },

  computed: {
    ...mapGetters('borrowers', ['getBusinessFiles']),
    ...mapGetters('borrowers', ['currentBorrower']),
  },
  methods: {
    ...mapActions('borrowers', ['fetchBusinessFiles']),
    async fetchBusinessFilesWithPage(borrowerRef) {
      this.ref = borrowerRef;
      await this.fetchBusinessFiles(this.ref);
    },
    async downloadAFile({ id, fileNameWithExtension }) {
      const link = document.createElement('a');
      link.href = `${process.env.VUE_APP_BASE_URL}/api/v2/businesses_files/${id}/download_file`;
      link.setAttribute('download', fileNameWithExtension);
      document.body.appendChild(link);
      link.click();
    },
    openKycFileForm(fileItem = null) {
      this.borrowerDetails = {
        borrower_ref: this.currentBorrower.ref,
        business_name: this.currentBorrower.businesses[0].name,
      };
      this.fileProp = fileItem;
      this.showFormModal = true;
    },
  },
};
</script>

<style scoped lang="scss">
.no-uppercase {
  text-transform: unset !important;
}
.v-btn {
  color: #699a4c;
}
</style>
