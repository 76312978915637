<template>
  <Modal
    :title="title"
    :openModal="display"
    v-on:close-modal="$emit('close-modal')"
  >
    <template v-slot:content>
      <div v-if="!formModel"><h2>No Action Available</h2></div>
      <div v-if="formModel">
        <form
          action=""
          @submit.prevent="handleClick"
          id="smsTemplateReviewForm"
        >
          <ErrorAlert
            v-if="formErrors.length > 0"
            :errors="formErrors"
            sticky
          />
          <vue-form-generator
            v-if="action === 'Review'"
            :schema="formSchema"
            :model="formModel"
          ></vue-form-generator>
        </form>
      </div>
    </template>
    <template v-slot:footer>
      <Button
        :text="buttonText"
        type="submit"
        form="smsTemplateReviewForm"
        styling="primary"
        validateBeforeSubmit
        :disabled="!isEnabled"
      ></Button>
    </template>
  </Modal>
</template>

<script>
import { mapGetters } from 'vuex';
import Modal from '../../components/Modal/Modal.vue';
import Button from '../../components/Button/Button.vue';
import ErrorAlert from '../../components/Alert/ErrorAlert.vue';

import {
  templateReviewSchema,
  templateReviewModel,
} from '../../formSchemas/sms/smsTemplateReviewForm';

const initialData = () => ({
  formErrors: [],
  formModel: templateReviewModel,
  formSchema: templateReviewSchema,
  title: 'Admin Review',
  buttonText: '',
});

export default {
  name: 'smsTemplateReviewForm',
  emits: ['close-modal'],
  props: ['templateItem', 'action', 'display'],
  components: {
    Modal,
    Button,
    ErrorAlert,
  },
  data() {
    return initialData();
  },
  methods: {
    handleClick() {
      const txt =
        this.action === 'Review'
          ? `Are you sure you want to submit this review?`
          : `Are you sure you want to publish this template?`;
      this.$confirm({
        message: txt,
        button: {
          no: 'Cancel',
          yes: 'Yes',
        },
        callback: (confirm) => {
          if (confirm) {
            this.submitReview();
          }
        },
      });
    },
    submitReview() {
      if (this.action === 'Review') {
        this.formErrors = [];
        const templateId = this.templateItem.id;
        const formCopy = { ...this.formModel };
        const { comment } = formCopy;

        const formData = new FormData();
        formData.set('id', templateId);
        formData.set('comment', comment);

        this.$store
          .dispatch(`smstemplates/reviewTemplate`, {
            data: formData,
            id: templateId,
          })
          .then(() => {
            this.$emit('close-modal');
            this.$router.push('/smstemplates');
          })
          .catch((err) => {
            this.formErrors = err;
          });
      } else {
        this.$store
          .dispatch(`smstemplates/publishTemplate`, {
            id: this.templateItem.id,
          })
          .then(() => {
            this.$emit('close-modal');
            this.$router.push('/smstemplates');
          })
          .catch((err) => {
            this.formErrors = err;
          });
      }
    },
    checkTemplateItem() {
      if (this.templateItem !== null) {
        this.title =
          this.action === 'Review'
            ? `Submit Admin review for ${this.templateItem.name} template`
            : `Publish ${this.templateItem.name} template`;
        this.buttonText = this.action === 'Review' ? 'Submit' : 'Publish';
      }
    },
  },
  watch: {
    templateItem: {
      handler() {
        this.checkTemplateItem();
      },
      deep: true,
    },
  },
  mounted() {
    if (!this.templateItem) this.$emit('close-modal');
    else this.checkTemplateItem();
  },
  computed: {
    ...mapGetters('auth', { isLoggedIn: 'isLoggedIn' }),
    isEnabled() {
      const { comment } = this.formModel;
      return this.action === 'Review' ? comment.length > 10 : true;
    },
  },
};
</script>
