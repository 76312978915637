<template>
  <div>
    <v-card v-if="formModel" class="pa-5">
      <div v-if="!formModel"><h2>No Action Available</h2></div>
      <form action="" @submit.prevent="submitBorrower" id="borrowerForm">
        <ErrorAlert v-if="formErrors.length > 0" :errors="formErrors" sticky />
        <v-card-title class="pl-0 d-flex justify-space-between">
          Borrower Information
          <v-btn
            v-if="isAdmin"
            color="primary"
            dark
            text
            @click="viewBorrowerDetails"
          >
            View
          </v-btn>
        </v-card-title>
        <vue-form-generator
          class="pt-4"
          :schema="schema.borrower"
          :model="formModel"
        ></vue-form-generator>
        <v-card-title class="pl-0">
          Primary Businesses Information</v-card-title
        >
        <vue-form-generator
          class="pt-4"
          :schema="schema.primaryBusiness"
          :model="primaryBusinessModel"
        ></vue-form-generator>
        <div v-if="isAdmin">
          <v-card-title class="pl-0"> Loan Information </v-card-title>
          <vue-form-generator
            class="pt-4"
            :schema="schema.loan"
            :model="formModel"
          ></vue-form-generator>
        </div>
        <v-card-title class="pl-0"> Referee Information </v-card-title>
        <vue-form-generator
          class="pt-4"
          :schema="schema.referee"
          :model="formModel"
        ></vue-form-generator>
      </form>

      <template>
        <Button
          class="mt-4 mb-4"
          :text="submitButtonText"
          type="submit"
          form="borrowerForm"
          styling="primary"
          validateBeforeSubmit
        ></Button>
      </template>
    </v-card>

    <div v-if="!otherBusinesses.length && !addNewBusiness" class="pa-2">
      <a @click="addNewBusiness = true"
        >Add other businesses to this borrower.</a
      >
    </div>

    <v-card v-if="otherBusinesses.length || addNewBusiness" class="mt-5 pa-5">
      <v-card-title class="pl-0"> Other Businesses </v-card-title>
      <ErrorAlert
        v-if="businessFormErrors.length > 0"
        :errors="businessFormErrors"
        sticky
      />
      <form v-for="model in otherBusinesses" :key="model.id" :id="model.id">
        <vue-form-generator
          class="pt-4"
          :schema="schema.business"
          :model="model"
        ></vue-form-generator>
        <v-btn
          class="mb-4"
          color="primary"
          rounded
          @click="submitBusiness(model)"
        >
          Update Business
        </v-btn>
        <v-divider class="mt-5 mb-5"></v-divider>
      </form>
      <form id="newBusiness" v-if="addNewBusiness">
        <vue-form-generator
          class="pt-4"
          :schema="schema.business"
          :model="businessModel"
        ></vue-form-generator>
        <v-btn
          color="primary"
          class="mb-4"
          rounded
          @click="submitBusiness(businessModel)"
        >
          Add Business
        </v-btn>
      </form>

      <div class="pa-2" v-if="!addNewBusiness">
        <a @click="addNewBusiness = true"
          >Add other businesses to this borrower.</a
        >
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ErrorAlert from '../../components/Alert/ErrorAlert.vue';
import Button from '../../components/Button/Button.vue';
import { unformatCurrency } from '../../utils/currency';
import {
  schema,
  defaultModel,
  businessModel,
} from '../../formSchemas/borrowers/defaultBorrower';

export default {
  name: 'BorrowerForm',
  emits: ['close-modal'],
  props: ['display'],
  components: {
    ErrorAlert,
    Button,
  },
  data() {
    const isAdmin = this.$store.getters['auth/isAdmin'];
    return {
      isAdmin,
      borrowerRef: this.$route.params.ref,
      mode: this.$route.params.mode,
      addNewBusiness: false,
      schema,
      formModel: defaultModel,
      businessModel,
      primaryBusinessModel: businessModel,
      otherBusinesses: [],
      formErrors: [],
      businessFormErrors: [],
      isLoading: false,
    };
  },

  methods: {
    ...mapActions('borrowers', ['fetchBorrower']),
    ...mapActions('partners', ['fetchPartners']),
    setFormData(result, data, key) {
      if (data[key] !== undefined) {
        result.set(key, data[key]);
      }
      return result;
    },
    submitBorrower() {
      this.formErrors = [];
      const formCopy = { ...this.formModel };
      let formData = new FormData();
      const keys = Object.keys(defaultModel);
      const isAdmin = this.$store.getters['auth/isAdmin'];
      const adminFields = [
        'has_max_loan_duration',
        'loan_limit',
        'loan_limit_lock',
      ];
      keys.forEach((key) => {
        if (adminFields.includes(key) && !isAdmin) {
          return;
        }
        if (key === 'loan_limit') {
          formCopy[key] = unformatCurrency(formCopy[key]);
        }
        if (key === 'primary_business') {
          formCopy[key] = JSON.stringify(this.primaryBusinessModel);
        }
        if (key === 'partner_defined_id') {
          formCopy[key] = this.primaryBusinessModel.ke_number;
        }
        formData = this.setFormData(formData, formCopy, key);
      });

      const method = this.mode === 'edit' ? 'PATCH' : 'POST';
      const ref = this.currentBorrower
        ? this.currentBorrower.ref
        : formCopy.ref;
      this.isLoading = true;

      this.$store
        .dispatch(`borrowers/borrowerCreateOrEdit`, {
          data: formData,
          method,
          ref,
        })
        .then(() => {
          this.isLoading = false;
          if (
            method === 'POST' ||
            ref.slice(0, 3) !== this.formModel.partner.slice(-3)
          ) {
            this.$router.push({ path: `/borrowers` });
          }
        })
        .catch((err) => {
          this.isLoading = false;
          this.formErrors = err;
        });
    },
    submitBusiness(businessFormData) {
      this.businessFormErrors = [];
      const formCopy = { ...businessFormData };
      let formData = new FormData();

      const keys = Object.keys(businessModel);

      keys.forEach((key) => {
        if (
          key === 'name' &&
          formData.ke_number === this.primaryBusinessModel.ke_number
        ) {
          formCopy[key] = this.formModel.name;
        }
        if (key === 'owner') {
          formCopy[key] = this.currentBorrower.url;
        }
        formData = this.setFormData(formData, formCopy, key);
      });

      const method = formCopy.id ? 'PATCH' : 'POST';
      const ref = formCopy.ke_number;
      this.$store
        .dispatch(`borrowers/businessCreateOrEdit`, {
          data: formData,
          method,
          ref,
        })
        .then()
        .catch((err) => {
          this.businessFormErrors = err;
        });
    },
    setPageData() {
      this.formErrors = [];

      if (this.currentBorrower.name) {
        this.title = `Edit Borrower ${this.currentBorrower.name}`;
        this.formModel = { ...this.currentBorrower };

        const primaryBusiness = (this.currentBorrower.businesses || []).find(
          (biz) => biz.ke_number === this.currentBorrower.partner_defined_id,
        );
        this.primaryBusinessModel = {
          ...(primaryBusiness || this.currentBorrower.businesses[0]),
          id: this.currentBorrower.businesses[0].id,
        };
        this.otherBusinesses = (this.currentBorrower.businesses || [])
          .filter(
            (biz) => biz.ke_number !== this.primaryBusinessModel.ke_number,
          )
          .map((business) => ({ ...business }));
      } else {
        this.title = `Add Borrower`;
        this.formModel = { ...defaultModel };
        this.primaryBusinessModel = { ...businessModel };
        this.businessModels = [{ ...businessModel }];
      }
    },
    setPartners() {
      const configurePartnerOptions = (options) => {
        return options.map((option) => {
          const { ref, name } = option;
          return {
            name,
            id: `${process.env.VUE_APP_BASE_URL}/api/v2/partners/${ref}`,
          };
        });
      };
      const { fields } = this.schema.borrower;
      const modifiedFields = fields.map((field) => {
        const modifiedField = { ...field };
        if (modifiedField.label === 'Distributor') {
          const partnerOptions = configurePartnerOptions(this.allPartners);
          modifiedField.values = () => partnerOptions;
        }
        return modifiedField;
      });

      this.schema.borrower.fields = modifiedFields;
    },
    viewBorrowerDetails() {
      this.$router.push({
        path: `/borrowers/view/${this.currentBorrower.ref}`,
        params: { ref: this.currentBorrower.ref },
      });
    },
  },
  computed: {
    ...mapGetters('partners', ['allPartners']),
    ...mapGetters('borrowers', ['currentBorrower']),
    submitButtonText() {
      return this.mode === 'edit' ? 'Update Borrower' : 'Add Borrower';
    },
    isEnabled() {
      const { name, partner } = this.formModel;

      const partnerIsSelected =
        typeof partner === 'string' && partner.length > 1;

      return (
        name &&
        partnerIsSelected &&
        this.formModel.borrower_type &&
        !this.isLoading
      );
    },
  },
  async beforeMount() {
    const queries = [this.fetchPartners()];
    if (this.borrowerRef) {
      queries.push(this.fetchBorrower(this.borrowerRef));
    } else {
      this.$store.dispatch(`borrowers/resetCurrentBorrower`);
    }
    await Promise.all(queries);
    this.setPartners();
    this.setPageData();
  },
};
</script>
