<template>
  <Modal
    :title="title"
    :openModal="display"
    v-on:close-modal="$emit('close-modal')"
  >
    <template v-slot:content>
      <form action="" @submit.prevent="handleClick" id="reverseRepayment">
        <ErrorAlert v-if="formErrors.length > 0" :errors="formErrors" sticky />
        <vue-form-generator
          :schema="formSchema"
          :model="formModel"
        ></vue-form-generator>
      </form>
    </template>
    <template v-slot:footer>
      <Button
        type="submit"
        form="reverseRepayment"
        styling="primary"
        text="Reverse"
      ></Button>
    </template>
  </Modal>
</template>

<script>
import Modal from '../../components/Modal/Modal.vue';
import Button from '../../components/Button/Button.vue';
import ErrorAlert from '../../components/Alert/ErrorAlert.vue';

export default {
  name: 'ReverseRepayment',
  emits: ['close-modal'],
  props: ['repaymentItem', 'display', 'repaymentRef'],
  components: {
    Modal,
    Button,
    ErrorAlert,
  },
  data() {
    return {
      formModel: null,
      formSchema: null,
      formErrors: [],
    };
  },
  computed: {
    title() {
      return `Reverse repayment ${this.repaymentRef}`;
    },
  },
  methods: {
    handleClick() {
      this.$confirm({
        message: `Are you sure you want to reverse this repayment?`,
        button: {
          no: 'Cancel',
          yes: 'Yes',
        },
        callback: (confirm) => {
          if (confirm) {
            this.reverseRepayment();
          }
        },
      });
    },
    reverseRepayment() {
      const { ref } = this.repaymentItem;

      this.$store
        .dispatch(`repayments/reverseRepayment`, {
          ref,
        })
        .then(() => {
          this.$emit('close-modal');
          // TODO To be removed after making statement a standalone page
          this.$router.push('/loans');
        })
        .catch((err) => {
          this.formErrors = err;
        });
    },
  },
};
</script>
