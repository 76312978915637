<template>
  <Modal
    :title="title"
    :openModal="display"
    v-on:close-modal="$emit('close-modal')"
  >
    <template v-slot:content>
      <div v-if="borrower">
        <div class="card-content">
          <div class="content">
            <h2 class="ma-3 mt-8">Vet Borrower {{ borrower.ref }}</h2>
            <v-simple-table>
              <tbody>
                <tr>
                  <td><b>Loan Limit: </b></td>
                  <td>{{ borrower.loan_limit }}</td>
                </tr>
                <tr>
                  <td><b>Type: </b></td>
                  <td>{{ borrower.borrower_type }}</td>
                </tr>
                <tr>
                  <td><b>Phone: </b></td>
                  <td>{{ borrower.phone }}</td>
                </tr>
                <tr>
                  <td><b>Partner: </b></td>
                  <td>{{ borrower.partner }}</td>
                </tr>
                <tr>
                  <td><b>Partner Defined ID: </b></td>
                  <td>{{ borrower.partner_defined_id }}</td>
                </tr>
                <tr>
                  <td><b>Organization info: </b></td>
                  <td v-if="borrower.org_info">
                    {{ borrower.org_info }}
                  </td>
                  <td v-else>-</td>
                </tr>
                <tr>
                  <td><b>Borrower users: </b></td>
                  <td v-if="borrower.borrower_users">
                    {{ borrower.borrower_users }}
                  </td>
                  <td v-else>-</td>
                </tr>
                <tr>
                  <td><b>Extra Information: </b></td>
                  <td>{{ borrower.metadata }}</td>
                </tr>
              </tbody>
            </v-simple-table>
            <input />
          </div>
        </div>
        <div class="card">
          <v-text-field
            placeholder="Enter your comment"
            label="Comment"
            rounded
            dense
            outlined
            v-model="comment"
          ></v-text-field>
        </div>
        <div class="card-footer">
          <Button
            type="submit"
            v-on:button-clicked="handleClick"
            styling="primary"
            text="Approve"
            :disabled="isDisabled"
          ></Button>
          <Button
            type="submit"
            v-on:button-clicked="rejectBorrower"
            styling="secondary"
            text="Reject"
          ></Button>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from '../../components/Modal/Modal.vue';
import Button from '../../components/Button/Button.vue';

export default {
  name: 'VetBorrower',
  emits: ['close-modal'],
  props: ['borrower', 'display'],
  components: {
    Modal,
    Button,
  },
  data() {
    return {
      borrowerInfo: null,
      title: '',
      comment: '',
      isLoading: false,
    };
  },
  computed: {
    isDisabled() {
      return this.isLoading;
    },
  },
  methods: {
    handleClick() {
      this.$confirm({
        message: `Are you sure you want to approve this borrower?`,
        button: {
          no: 'Cancel',
          yes: 'Yes',
        },
        callback: (confirm) => {
          if (confirm) {
            this.approveBorrower();
          }
        },
      });
    },
    approveBorrower() {
      const { ref } = this.borrower;
      const { comment } = this;
      this.isLoading = true;
      this.$store
        .dispatch(`borrowers/activateBorrower`, {
          ref,
          comment,
        })
        .then(() => {
          this.isLoading = false;
          this.$emit('close-modal');
        })
        .catch((err) => {
          this.isLoading = false;
          this.formErrors = err;
        });
    },
    rejectBorrower() {
      const { ref } = this.borrower;
      const { comment } = this;
      this.$store
        .dispatch(`borrowers/deactivateBorrower`, {
          ref,
          comment,
        })
        .then(() => {
          this.$emit('close-modal');
        })
        .catch((err) => {
          this.formErrors = err;
        });
    },
  },
  updated() {
    if (!this.borrower) this.$emit('close-modal');
    else {
      this.title = `Details for Borrower ${this.borrower.name}`;
    }
  },
};
</script>
