<template>
  <v-container v-if="formInitialized">
    <template>
      <div v-if="!formModel"><h2>No Action Available</h2></div>
      <div v-if="formModel">
        <form action="" @submit.prevent="submitDmsData" id="dmsDataRefreshForm">
          <ErrorAlert
            v-if="formErrors.length > 0"
            :errors="formErrors"
            sticky
          />
          <vue-form-generator
            :schema="formSchema"
            :model="formModel"
          ></vue-form-generator>
          <div class="form-group col-md-6 pt-3 pb-0 mb-0 field-input">
            <span style="font-weight: 500">DMS Data Excel File</span>
            <v-file-input
              background-color="white"
              outlined
              rounded
              dense
              @change="onFileChanged($event)"
            ></v-file-input>
          </div>
        </form>
        <v-btn
          rounded
          class="mb-3"
          type="submit"
          form="dmsDataRefreshForm"
          color="primary"
          :loading="loading"
        >
          Upload
        </v-btn>
      </div>
    </template>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import ErrorAlert from '../../components/Alert/ErrorAlert.vue';
import {
  dmsRefreshFileSchema,
  dmsRefreshFileModel,
} from '../../formSchemas/dmsDataForm';

export default {
  name: 'DmsDataForm',
  emits: ['close-modal'],
  props: ['dmsRefreshStep', 'isLoading'],
  components: {
    ErrorAlert,
  },
  data() {
    return {
      formInitialized: false,
      loading: false,
      formSchema: { ...dmsRefreshFileSchema },
      formModel: { ...dmsRefreshFileModel },
      formErrors: [],
      fileUrl: {},
    };
  },

  methods: {
    ...mapActions('partners', ['fetchPartners']),
    addIdToDropdownOptions(options) {
      const data = [];
      options.forEach((option) => {
        const id = option.ref;
        const entry = { id, ...option };
        data.push(entry);
      });
      return data;
    },
    submitDmsData() {
      this.formErrors = [];
      const partner = `${process.env.VUE_APP_BASE_URL}/api/v2/partners/${this.formModel.partner}`;

      const { year, month, weekDates } = this.getDateInfo();

      const formData = new FormData();
      formData.append('partner', partner);
      formData.append('year', year);
      formData.append('month', month);
      formData.append('weekDates', weekDates);
      formData.append('fileUrl', this.fileUrl, this.fileUrl.name);

      this.$emit('submitted', formData);
    },
    onFileChanged(newFile) {
      this.fileUrl = newFile;
    },
    getDateInfo() {
      const today = new Date();

      const dayOfWeek = today.getDay();
      const daysToSubtract = 7 + (dayOfWeek - 1);
      const lastMonday = new Date(
        today.getTime() - daysToSubtract * 24 * 60 * 60 * 1000,
      );

      const year = lastMonday.getFullYear();
      const monthList = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ];
      const month = monthList[lastMonday.getMonth()];
      const endOfWeek = new Date(
        lastMonday.getTime() + 6 * 24 * 60 * 60 * 1000,
      );
      const weekDates = `${lastMonday.getDate()}-${endOfWeek.getDate()}`;

      return {
        year,
        month,
        weekDates,
      };
    },
  },

  async mounted() {
    await this.fetchPartners();
    this.formSchema = this.schemaWithPartners;
    this.formInitialized = true;
  },

  watch: {
    isLoading(newVal) {
      this.loading = newVal;
    },
  },

  computed: {
    schemaWithPartners() {
      const partners = this.$store.getters['partners/allPartners'];
      const partnerOptionsWithId = this.addIdToDropdownOptions(partners);

      const finalData = { fields: [] };
      this.formSchema.fields.forEach((field) => {
        if (field.label === 'Partner') {
          const { values, ...rest } = field;
          const newField = { ...rest, values: () => partnerOptionsWithId };
          finalData.fields.push(newField);
          return;
        }

        finalData.fields.push(field);
      });

      return finalData;
    },
  },
};
</script>
