const repaymentSchema = {
  fields: [
    {
      type: 'input',
      inputType: 'date',
      label: 'Date',
      model: 'date',
      required: true,
      format: 'YYYY-MM-DD',
      validator: ['required'],
    },
    {
      type: 'input',
      inputType: 'text',
      label: 'Amount',
      model: 'amount',
      required: true,
      max: 150,
      min: 5,
      validator: ['required'],
    },
    {
      type: 'input',
      inputType: 'text',
      label: 'Repayment Code',
      model: 'repayment_code',
      required: true,
      max: 15,
      min: 5,
      validator: ['required'],
    },
    {
      type: 'select',
      label: 'Repayment Channel',
      model: 'repaymentChannel',
      values: ['mpesa', 'bank'],
      required: true,
      validator: ['required'],
    },
    {
      type: 'input',
      inputType: 'text',
      label: 'Comment',
      model: 'comment',
      required: false,
    },
  ],
};

const repaymentModel = {
  amount: '',
  date: '',
  comment: '',
  repayment_code: '',
  repaymentChannel: 'mpesa',
};

export { repaymentSchema, repaymentModel };
