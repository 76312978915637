<template>
  <div class="width100">
    <PageHeader headingText="Borrowers" :breadcrumbs="breadcrumbs"></PageHeader>
    <v-layout justify-center flex-column align-stretch class="ma-5">
      <BorrowerForm
        v-if="getMode === 'edit' || getMode === 'create'"
      ></BorrowerForm>
      <v-card v-if="getMode === 'view'" class="pa-5">
        <AccountDetails
          :currentBorrower="currentBorrower"
          :canEdit="true"
        ></AccountDetails>
      </v-card>
    </v-layout>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PageHeader from '../../components/PageHeader/PageHeader.vue';
import BorrowerForm from './BorrowerForm.vue';
import AccountDetails from '../BorrowerProfile/AccountDetails.vue';

export default {
  name: 'BorrowerDetailPage',
  components: {
    PageHeader,
    BorrowerForm,
    AccountDetails,
  },
  data() {
    const { mode } = this.$route.params;
    return {
      tab: null,
      selectedComponent: 'account-details',
      borrowerRef: this.$route.params.ref,
      mode,
      breadcrumbs: [
        {
          text: 'Borrowers',
          disabled: false,
          href: '#/borrowers',
        },
        {
          text: `${this.$route.params.ref || ''}`,
          disabled: true,
          href: '',
        },
      ],
    };
  },
  methods: {
    ...mapActions('borrowers', ['fetchBorrower']),
  },
  computed: {
    ...mapGetters('borrowers', ['currentBorrower']),
    getMode() {
      const { mode } = this.$route.params;
      return mode;
    },
  },
  async beforeMount() {
    if (this.mode !== 'create') {
      await this.fetchBorrower(this.borrowerRef);
    }
  },
};
</script>
