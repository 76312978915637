const templateReviewSchema = {
  fields: [
    {
      type: 'textArea',
      inputType: 'text',
      label: 'Comment/Instructions',
      model: 'comment',
      placeholder: 'Enter comment',
      required: true,
      max: 1000,
      min: 5,
      validator: ['required'],
    },
  ],
};

const templateReviewModel = {
  comment: '',
};

export { templateReviewSchema, templateReviewModel };
