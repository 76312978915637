<template>
  <Modal
    :title="title"
    :openModal="display"
    v-on:close-modal="$emit('close-modal')"
  >
    <template v-slot:content>
      <div v-if="!formModel"><h2>No Action Available</h2></div>
      <div v-if="formModel">
        <form action="" @submit.prevent="submitKycItem" id="kycForm">
          <ErrorAlert
            v-if="formErrors.length > 0"
            :errors="formErrors"
            sticky
          />
          <vue-form-generator
            :schema="formSchema"
            :model="formModel"
          ></vue-form-generator>
        </form>
      </div>
    </template>
    <template v-slot:footer>
      <Button
        text="Submit"
        type="submit"
        form="kycForm"
        styling="primary"
        validateBeforeSubmit
      ></Button>
    </template>
  </Modal>
</template>

<script>
import { mapGetters } from 'vuex';
import Modal from '../../components/Modal/Modal.vue';
import Button from '../../components/Button/Button.vue';
import ErrorAlert from '../../components/Alert/ErrorAlert.vue';

import { kycModel, kycSchema } from '../../formSchemas/kycForm';

const initialData = () => ({
  formErrors: [],
  formModel: kycModel,
  formSchema: kycSchema,
  title: 'Create new kyc item',
});

export default {
  name: 'KycForm',
  emits: ['close-modal'],
  props: ['kycItem', 'display'],
  components: {
    Modal,
    Button,
    ErrorAlert,
  },
  data() {
    return initialData();
  },
  methods: {
    submitKycItem() {
      this.formErrors = [];
      const formCopy = { ...this.formModel };
      const { url, code, value } = formCopy;

      const formData = new FormData();
      formData.set('code', code);
      formData.set('value', value);
      const method = url ? 'PATCH' : 'POST';
      const kycCode = url ? url.split('/').pop() : null;

      this.$store
        .dispatch(`kycs/createOrEditKyc`, {
          data: formData,
          method,
          kycCode,
        })
        .then(() => {
          this.$emit('close-modal');
        })
        .catch((err) => {
          this.formErrors = err;
        });
    },
    checkKycItem() {
      if (this.kycItem !== null) {
        this.title = `Edit ${this.kycItem.value}`;
        this.formModel = { ...this.kycItem };
      }
    },
  },
  watch: {
    kycItem: {
      handler() {
        this.checkKycItem();
      },
      deep: true,
    },
    display: {
      handler() {
        if (!this.display) {
          Object.assign(this.$data, initialData());
        }
      },
    },
  },
  mounted() {
    if (!this.kycItem) this.$emit('close-modal');
    else this.checkKycItem();
  },
  computed: {
    ...mapGetters('auth', { isLoggedIn: 'isLoggedIn' }),
  },
};
</script>
