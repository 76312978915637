<template>
  <div class="width100">
    <PageHeader headingText="Loan Details" :breadcrumbs="items"></PageHeader>
    <v-layout justify-center flex-column align-stretch class="ma-4">
      <v-row align="center" justify="end" class="ma-auto col-10 pl-7">
        <v-col class="shrink">
          <v-btn color="primary" dark @click="expand = !expand">
            Actions
            <v-icon right dark>mdi-menu-down</v-icon>
          </v-btn>
          <v-expand-x-transition>
            <v-card v-show="expand" max-width="344" class="mx-auto">
              <v-card-text>
                <v-btn
                  text
                  v-if="isAdmin && current_loan.status !== 'repaid'"
                  @click="openExtraChargeModal"
                >
                  Add Extra Fee
                </v-btn>
                <v-btn
                  text
                  v-if="isSuperUser && current_loan.status === 'overdue'"
                  @click="openPostWaiverModal"
                >
                  Post Waiver
                </v-btn>
                <v-btn text @click="exportPDF"> Download Statement </v-btn>
                <v-btn text @click="openLoanTermsPage"> View Loan Terms </v-btn>
                <v-btn
                  text
                  v-if="current_loan.status !== 'repaid'"
                  @click="openOutstandingBalanceCalculator"
                >
                  Loan calculator
                </v-btn>
                <v-btn
                  text
                  v-if="isAdmin && current_loan.status !== 'repaid'"
                  @click="openMakeRepaymentModal"
                >
                  Make a repayment
                </v-btn>
                <v-btn
                  text
                  v-if="isAdmin && canFreeze.includes(current_loan.status)"
                  @click="openFreezeLoanModal"
                >
                  Freeze loan
                </v-btn>
                <v-btn
                  text
                  v-if="isAdmin && current_loan.status === 'frozen'"
                  @click="openUnfreezeLoanModal"
                >
                  Unfreeze loan
                </v-btn>
                <v-btn
                  text
                  v-if="current_loan.status === 'overdue'"
                  @click="openWriteOffLoanModal"
                >
                  Write off
                </v-btn>
                <v-btn
                  text
                  v-if="current_loan.status === 'active' && isSuperUser"
                  @click="openReverseDisbursement"
                >
                  Reverse disbursement
                </v-btn>
              </v-card-text>
            </v-card>
          </v-expand-x-transition>
        </v-col>
      </v-row>
      <v-card id="statement" elevation="2" class="ma-auto col-10 pl-7">
        <v-card-title id="cardTitle" class="pt-0 pb-0">
          <div class="d-flex flex-column">
            <v-card-subtitle class="pb-0 pl-0">
              <p class="mb-0">{{ now }}</p>
            </v-card-subtitle>
            <p>Loan {{ loanRef.toUpperCase() }}</p>
            <p>
              Total repaid: <b>{{ amountRepaidSoFar }}</b>
            </p>
          </div>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-list>
          <v-list-item v-if="current_loan.borrower">
            <v-simple-table id="borrowerDetailsTable">
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td>Name</td>
                    <td>{{ current_loan.borrower.name }}</td>
                  </tr>
                  <tr>
                    <td>Phone</td>
                    <td>{{ current_loan.borrower.phone }}</td>
                  </tr>
                  <tr>
                    <td>KE Number</td>
                    <td>{{ current_loan.borrower.partner_defined_id }}</td>
                  </tr>
                  <tr>
                    <td>Repayment Model</td>
                    <td>{{ repaymentModel }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-list-item>
        </v-list>
        <v-list>
          <v-list-item>
            <v-list-item-title class="subTitle">LOAN DETAILS</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-simple-table class="pr-10">
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td>Principal</td>
                    <td>{{ current_loan.principal }}</td>
                  </tr>
                  <tr>
                    <td>Interest rate</td>
                    <td>{{ current_loan.interest_rate }}</td>
                  </tr>
                  <tr>
                    <td>Loan period</td>
                    <td>{{ current_loan.loan_period }}</td>
                  </tr>
                  <tr>
                    <td>Status</td>
                    <td>{{ current_loan.status }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-simple-table class="pr-10">
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td>Disbursement date</td>
                    <td>{{ current_loan.disbursement_date }}</td>
                  </tr>
                  <tr>
                    <td>Due date</td>
                    <td>{{ current_loan.due_date }}</td>
                  </tr>
                  <tr v-if="current_loan.status === 'repaid'">
                    <td>Completed date</td>
                    <td>{{ current_loan.completed_date }}</td>
                  </tr>
                  <tr v-else>
                    <td>Days in Arrears</td>
                    <td>{{ current_loan.days_in_arrears }}</td>
                  </tr>
                  <tr>
                    <td>Expected repayment amount</td>
                    <td>{{ current_loan.total_expected_repayment }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td>Total Outstanding Amount</td>
                    <td>{{ current_loan.outstanding_balance }}</td>
                  </tr>
                  <tr>
                    <td>Outstanding Principal</td>
                    <td>{{ current_loan.outstanding_principal }}</td>
                  </tr>
                  <tr>
                    <td>Outstanding Interest</td>
                    <td>{{ current_loan.outstanding_interest }}</td>
                  </tr>
                  <tr>
                    <td>Outstanding Penalty</td>
                    <td>
                      {{ amountPenalty }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-list-item>
        </v-list>
        <v-list>
          <v-list-item>
            <v-list-item-title class="subTitle">REPAYMENTS</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-data-table
              :headers="repaymentTableHeaders"
              :items="current_loan.repayments"
              :items-per-page="tableLength"
              :hide-default-footer="true"
              class="elevation-1"
            >
              <template v-if="isAdmin" v-slot:[`item.action`]="{ item }">
                <slot name="actions" :item="item"
                  ><v-icon
                    small
                    style="font-size: 30px"
                    color="green"
                    @click="openReverseRepaymentModal(item)"
                  >
                    mdi-arrow-u-left-top
                  </v-icon></slot
                >
              </template>
            </v-data-table>
            <ReverseRepayment
              v-bind:loan="this.current_loan"
              v-bind:repaymentRef="this.repaymentRef"
              v-bind:repaymentItem="this.item"
              v-bind:display="this.showReverseRepaymentModal"
              v-on:close-modal="showReverseRepaymentModal = false"
            ></ReverseRepayment>
          </v-list-item>
          <v-list-item v-if="current_loan.extra_charges.length > 0">
            <v-list-item-title class="subTitle"
              >EXTRA CHARGES :
              {{ current_loan.total_extra_charges }}</v-list-item-title
            >
          </v-list-item>
          <v-list-item v-if="current_loan.extra_charges.length > 0">
            <v-data-table
              :headers="extraChargesTableHeaders"
              :items="current_loan.extra_charges"
              :items-per-page="extraChargeTableLength"
              :hide-default-footer="true"
              class="elevation-1"
              style="width: 100%"
            >
            </v-data-table>
          </v-list-item>
          <v-list-item>
            <v-list-item-title class="subTitle">WAIVERS</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-data-table
              :headers="postWaiverTableHeaders"
              :items="current_loan.waivers"
              :items-per-page="postWaiverTableLength"
              :hide-default-footer="true"
              class="elevation-1"
              style="width: 100%"
            >
              <template #item.attachment="{ value }">
                <a v-show="value != null" target="_blank" :href="value">
                  View Link Here
                </a>
              </template>
            </v-data-table>
          </v-list-item>
        </v-list>
      </v-card>
      <MakeRepaymentModal
        :loanRef="loanRef"
        :display="makeRepaymentModalShow"
        @close-modal="makeRepaymentModalShow = false"
      />
      <AddExtraChargeModal
        :loanRef="loanRef"
        :loanUrl="loanUrl"
        :display="extraChargeModalShow"
        @close-modal="extraChargeModalShow = false"
      />
      <PostWaiverModel
        :loanRef="loanRef"
        :loanUrl="loanUrl"
        :display="postWaiverModalShow"
        @close-modal="postWaiverModalShow = false"
      />
      <OutstandingBalanceCalculator
        v-bind:loanRef="this.outstandingBalanceLoanRef"
        v-bind:borrowerName="this.outstandingBalanceBorrowerName"
        v-bind:display="this.outstandingBalanceShow"
        v-on:close-loan-table-modal="outstandingBalanceShow = false"
      ></OutstandingBalanceCalculator>
      <LoanAction
        v-bind:methodToDispatch="currentMethod"
        v-bind:loanAction="currentAction"
        v-bind:loanRef="currentLoanRef"
        v-bind:actionMessage="actionMessage"
        :visible="loanActionModalShow"
        @close="loanActionModalShow = false"
      />
    </v-layout>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import html2canvas from 'html2canvas';
import * as JsPDF from 'jspdf';

import PageHeader from '../../components/PageHeader/PageHeader.vue';
import ReverseRepayment from './ReverseRepayment.vue';
import MakeRepaymentModal from './MakeRepayment.vue';
import AddExtraChargeModal from './AddExtraCharge.vue';
import PostWaiverModel from './PostWaiver.vue';
import OutstandingBalanceCalculator from './OutstandingBalanceCalculator.vue';
import LoanAction from './LoanAction.vue';
import { formatCurrency, unformatCurrency } from '../../utils/currency';

export default {
  name: 'SingleLoan',
  components: {
    PageHeader,
    LoanAction,
    MakeRepaymentModal,
    AddExtraChargeModal,
    OutstandingBalanceCalculator,
    ReverseRepayment,
    PostWaiverModel,
  },
  data() {
    const isAdmin = this.$store.getters['auth/isAdmin'];
    const repaymentTableHeaders = [
      { text: 'Repayment ref', align: 'start', value: 'ref' },
      { text: 'Repayment date', value: 'repayment_date' },
      { text: 'Amount repaid', value: 'amount' },
      {
        text: 'Outstanding balance after repayment',
        value: 'outstanding_balance_after_repayment',
      },
    ];
    const postWaiverTableHeaders = [
      { text: 'Waiver Ref', align: 'start', value: 'ref' },
      { text: 'Amount', value: 'amount' },
      { text: 'Reason', align: 'start', value: 'reason' },
      { text: 'Document Link', align: 'start', value: 'attachment' },
    ];
    const extraChargesTableHeaders = [
      { text: 'Charge Type', align: 'start', value: 'charge_type' },
      { text: 'Amount', value: 'amount' },
    ];
    if (isAdmin) {
      repaymentTableHeaders.push({ text: 'Actions', value: 'action' });
    }
    return {
      item: null,
      showReverseRepaymentModal: false,
      repaymentRef: '',
      items: [
        { text: 'Loans', disabled: false, href: '#/loans' },
        {
          text: this.$route.params.ref,
          disabled: false,
          href: `#/loan/${this.$route.params.ref}`,
        },
      ],
      makeRepaymentModalShow: false,
      extraChargeModalShow: false,
      postWaiverModalShow: false,
      expand: false,
      outstandingBalanceShow: false,
      loanActionModalShow: false,
      outstandingBalanceLoanRef: null,
      outstandingBalanceBorrowerName: '',
      canFreeze: ['active', 'due', 'overdue'],
      currentAction: '',
      currentLoanRef: '',
      currentMethod: 'loans/actOnLoan',
      actionMessage: '',
      now: new Date().toString().slice(0, 24),
      loanRef: this.$route.params.ref,
      repaymentTableHeaders,
      extraChargesTableHeaders,
      postWaiverTableHeaders,
      flashLoanTerms: false,
    };
  },

  computed: {
    ...mapGetters('auth', { isAdmin: 'isAdmin' }),
    ...mapGetters('loans', ['current_loan']),
    ...mapGetters('auth', { isSuperUser: 'isSuperUser' }),

    amountPenalty() {
      return formatCurrency(
        unformatCurrency(this.current_loan.outstanding_penalty) +
          unformatCurrency(this.current_loan.outstanding_rollover_fee),
      );
    },

    repaymentModel() {
      if (
        this.current_loan.product.repayment_model ===
        'reducingBalanceSingleInstallment'
      ) {
        return 'Reducing Balance';
      }
      return 'Fixed Rate';
    },

    amountRepaidSoFar() {
      if (this.current_loan && this.current_loan.repayments) {
        return this.sumTotalRepayments(this.current_loan.repayments);
      }
      return 0;
    },
    tableLength() {
      if (this.current_loan && this.current_loan.repayments) {
        return this.current_loan.repayments.length;
      }
      return 20;
    },
    extraChargeTableLength() {
      if (this.current_loan && this.current_loan.extra_charges) {
        return this.current_loan.extra_charges.length;
      }
      return 20;
    },
    postWaiverTableLength() {
      if (this.current_loan && this.current_loan.waivers) {
        return this.current_loan.waivers.length;
      }
      return 20;
    },
    loanUrl() {
      return this.current_loan.url;
    },
  },
  beforeMount() {
    this.fetchLoan(this.loanRef); // TODO place this method correctly.
  },

  methods: {
    ...mapActions('loans', ['fetchLoan']),

    openReverseRepaymentModal(repaymentItem = null) {
      this.item = repaymentItem;
      this.repaymentRef = repaymentItem.ref;
      this.showReverseRepaymentModal = true;
    },
    sumTotalRepayments(repayments) {
      let total = 0;

      total = repayments.reduce(
        (x, y) => x + unformatCurrency(y.amount),
        total,
      );
      return formatCurrency(total);
    },
    openPostWaiverModal() {
      this.expand = !this.expand;
      // if (this.current_loan.status === 'repaid') {
      //   this.$store.commit(
      //     'common/success_snackbar',
      //     'This loan is fully repaid',
      //   );
      //   return;
      // }
      this.currentLoanRef = this.loanRef;
      this.postWaiverModalShow = true;
    },
    openExtraChargeModal() {
      this.expand = !this.expand;
      if (this.current_loan.status === 'repaid') {
        this.$store.commit(
          'common/success_snackbar',
          'This loan is fully repaid',
        );
        return;
      }
      this.currentLoanRef = this.loanRef;
      this.extraChargeModalShow = true;
    },
    openMakeRepaymentModal() {
      this.expand = !this.expand;
      if (this.current_loan.status === 'repaid') {
        this.$store.commit(
          'common/success_snackbar',
          'This loan is fully repaid',
        );
        return;
      }
      this.currentLoanRef = this.loanRef;
      this.makeRepaymentModalShow = true;
    },
    openOutstandingBalanceCalculator() {
      this.expand = !this.expand;
      if (this.current_loan.status === 'repaid') {
        this.$store.commit(
          'common/success_snackbar',
          'This loan is fully repaid',
        );
        return;
      }
      this.outstandingBalanceShow = true;
      this.outstandingBalanceLoanRef = this.current_loan.ref;
      this.outstandingBalanceBorrowerName = this.current_loan.borrower?.name;
    },
    openFreezeLoanModal() {
      this.expand = !this.expand;
      this.currentAction = 'freeze';
      this.currentLoanRef = this.current_loan.ref;
      this.loanActionModalShow = true;
      this.actionMessage = 'Pause interest accrual for';
    },
    openUnfreezeLoanModal() {
      this.expand = !this.expand;
      this.currentAction = 'unfreeze';
      this.currentLoanRef = this.current_loan.ref;
      this.loanActionModalShow = true;
      this.actionMessage = 'Resume interest accrual for';
    },
    openWriteOffLoanModal() {
      this.expand = !this.expand;
      this.currentAction = 'write_off';
      this.currentLoanRef = this.current_loan.ref;
      this.loanActionModalShow = true;
      this.actionMessage = 'Write off';
    },
    openReverseDisbursement() {
      this.expand = !this.expand;
      this.currentAction = 'withdraw';
      this.currentLoanRef = this.current_loan.ref;
      this.loanActionModalShow = true;
      this.actionMessage = 'Reverse the disbursal of';
    },
    openLoanTermsPage() {
      this.$router.push({
        path: `/loanterms/${this.current_loan.ref}`,
        params: { ref: this.current_loan.ref },
      });
    },
    exportPDF() {
      this.expand = !this.expand;
      html2canvas(document.querySelector('#statement'), {
        imageTimeout: 5000,
        useCORS: false,
      }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const imgWidth = 210;
        const pageHeight = 295;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        // eslint-disable-next-line new-cap
        const doc = new JsPDF.default();
        let position = 0;
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
        while (heightLeft >= 0) {
          position += heightLeft - imgHeight; // top padding for other pages
          doc.addPage();
          doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }
        doc.save(`${this.loanRef}_loan_statement.pdf`);
      });
    },
  },
};
</script>

<style>
#borrowerDetailsTable {
  border: 0px;
}

#cardTitle {
  border-bottom: 0px;
}

.subTitle {
  text-align: center;
  font-weight: bold;
}
</style>
