const kycSchema = {
  fields: [
    {
      type: 'input',
      inputType: 'text',
      label: 'Code',
      model: 'code',
      placeholder: 'EXAMPLE_KYC',
      required: true,
      max: 150,
      min: 5,
      validator: ['required'],
    },
    {
      type: 'input',
      inputType: 'text',
      label: 'Value',
      model: 'value',
      placeholder: 'Example Kyc',
      required: true,
      max: 150,
      min: 5,
      validator: ['required'],
    },
  ],
};

const kycModel = {
  code: '',
  value: '',
};

export { kycModel, kycSchema };
