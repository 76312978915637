import Vue from 'vue';

const getAllRepayments = (isAdmin, partnerId) => {
  switch (isAdmin) {
    case true:
      return Vue.http.get('/api/v2/repayments');
    default:
      return Vue.http.get(`/api/v2/partners/${partnerId}/repayments`);
  }
};

const makeRepayment = (data) => {
  return Vue.http({
    url: 'api/v2/repayments',
    method: 'POST',
    data,
  });
};

const reverseRepayment = (ref) => {
  const url = `/api/v2/repayments/${ref}/reverse_repayment`;
  const method = 'PATCH';
  return Vue.http({
    url,
    method,
  });
};

export { getAllRepayments, makeRepayment, reverseRepayment };
