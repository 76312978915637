/* eslint-disable no-param-reassign */
import Vue from 'vue';
import {
  getBorrowers,
  createOrEditBorrower,
  deleteBorrower,
  activateBorrower,
  deactivateBorrower,
  getBorrower,
  getBorrowerLoans,
  createOrEditBorrowerComment,
  getLoanFeedback,
  getBusinessFiles,
  getAccountBusinesses,
  createOrEditAccountBusiness,
  createKycFile,
  editKycFile,
} from '../api/borrowers.api';
import { getLoan, createOrEditLoanComment } from '../api/loans.api';
import { formatCurrency } from '../utils/currency';
import { formatDate } from '../utils/date';

const currencyKeys = [
  'applied_amount',
  'outstanding_balance',
  'outstanding_charges',
  'outstanding_interest',
  'outstanding_penalty',
  'outstanding_principal',
  'outstanding_rollover_fee',
  'principal',
  'total_expected_repayment',
];

const state = () => ({
  allBorrowers: [],
  businessFiles: [],
  accountBusinesses: [],
  sendSmsBorrowersList: [],
  borrowers: {
    count: 0,
    links: {
      next: null,
      previous: null,
    },
    pages: 1,
    per_page: 30,
    results: [],
  },
  currentBorrower: {
    comments: [],
    businesses: [],
  },
  borrowerLoans: [],
  /** Detail view for borrower-profile-loan. */
  currentBorrowerLoan: {
    repayments: [],
    comments: [],
    feedback: [],
  },
});

const getters = {
  getBusinessFiles: (state) => state.businessFiles,
  accountBusinesses: (state) => state.accountBusinesses,
  allBorrowers: (state) => state.allBorrowers,
  borrowers: (state) => state.borrowers,
  borrowerUsingRef: (state) => (ref) =>
    state.allBorrowers.find(
      ({ borrowerRef }) => borrowerRef.toUpperCase() === ref.toUpperCase(),
    ),
  borrowerUsingName: (state) => (borrower) =>
    state.allBorrowers.find(
      ({ name }) => name.toUpperCase() === borrower.toUpperCase(),
    ),
  currentBorrower: (state) => state.currentBorrower,
  currentBorrowerLoan: (state) => state.currentBorrowerLoan,
  borrowerLoans: (state) => state.borrowerLoans,
  sendSmsBorrowersList: (state) => state.sendSmsBorrowersList,
};

const actions = {
  async fetchBorrowers({ commit }, payload = {}) {
    commit('initial_state');
    const { status, page, searchTerm, searchParams } = payload;
    try {
      commit('common/data_requested', null, { root: true });
      const response = await getBorrowers(
        status,
        page,
        searchTerm,
        searchParams,
      );
      const updatedBorrowers = response.data.results.map((oldBorrower) => {
        const newBorrower = { ...oldBorrower };
        if (newBorrower.partner) {
          const splitPt = newBorrower.partner.split('/');
          newBorrower.partner = splitPt[splitPt.length - 1];
        } else {
          newBorrower.partner = '';
        }
        if (newBorrower.borrower_users && newBorrower.borrower_users.admin) {
          const { admin } = newBorrower.borrower_users;
          const adminName = admin.admin_name ? admin.admin_name : '';
          newBorrower.adminName = adminName;
        }
        if (newBorrower.borrower_users && newBorrower.borrower_users.owner) {
          const { owner } = newBorrower.borrower_users;
          const ownerName = owner.owner_name ? owner.owner_name : '';
          const phoneNumber = owner.phone_number ? owner.phone_number : '';
          const ownerId = owner.owner_id ? owner.owner_id : '';
          newBorrower.ownerName = ownerName;
          newBorrower.phoneNumber = phoneNumber;
          newBorrower.ownerId = ownerId;
        }
        if (newBorrower.metadata) {
          const borrowerMetadata = newBorrower.metadata;
          const liquorLicense = borrowerMetadata
            ? borrowerMetadata.liquor_license
            : '';
          const businessPermit = borrowerMetadata
            ? borrowerMetadata.business_permit
            : '';
          newBorrower.liquorLicense = liquorLicense;
          newBorrower.businessPermit = businessPermit;
        }
        if (newBorrower.org_info) {
          const orgInfo = newBorrower.org_info;
          const tradingName = orgInfo ? orgInfo.trading_name : '';
          const registrationType = orgInfo ? orgInfo.registration_type : '';
          newBorrower.tradingName = tradingName;
          newBorrower.registrationType = registrationType;
        }
        return newBorrower;
      });
      response.data.results = updatedBorrowers; // TODO fix this. Should not mutate response object

      commit('set_borrowers', response.data);
    } catch (error) {
      commit(
        'common/error_snackbar',
        `Failed to fetch borrowers. Please try again later.\n ${error}`,
        {
          root: true,
        },
      );
    } finally {
      commit('common/response_received', null, { root: true });
    }
  },
  async fetchBorrower({ commit }, borrowerRef) {
    try {
      const { data } = await getBorrower(borrowerRef);
      commit('set_current_borrower', data);
    } catch (error) {
      commit(
        'common/error_snackbar',
        `Failed to fetch active borrower. An error happened ${error}`,
        {
          root: true,
        },
      );
    }
  },
  resetCurrentBorrower({ commit }) {
    commit('reset_current_borrower');
  },
  async borrowerCreateOrEdit({ commit }, { data, method, ref }) {
    try {
      const resp = await createOrEditBorrower(data, method, ref);
      commit(
        method === 'POST' ? 'add_borrower' : 'updated_borrower',
        resp.data,
      );
      commit(
        'common/success_snackbar',
        `Borrower ${method === 'POST' ? 'created' : 'edited'} successfully`,
        {
          root: true,
        },
      );
    } catch (errors) {
      const error = errors.response.data;
      throw Array.isArray(error) ? error : [error];
    }
  },
  async deleteBorrower({ commit }, { ref }) {
    try {
      await deleteBorrower(ref);
      commit('deleted_borrowers', ref);
      commit('common/success_snackbar', 'Borrower deleted successfully', {
        root: true,
      });
    } catch (errors) {
      const error = errors.response.data;
      throw Array.isArray(error) ? error : [error];
    }
  },
  async populateSendSmsBorrowersList({ commit }, data) {
    try {
      commit('set_sendSmsBorrowersList', data);
    } catch (errors) {
      commit(
        'common/error_snackbar',
        `Failed to update list of borrowers to send sms. An error happened ${errors}`,
        {
          root: true,
        },
      );
    }
  },
  async activateBorrower({ commit }, { ref, comment }) {
    try {
      const resp = await activateBorrower(ref, comment);
      commit('updated_borrower', resp.data);
      commit('common/success_snackbar', 'Borrower ACTIVATED successfully', {
        root: true,
      });
    } catch (errors) {
      const error = errors.response.data;
      throw Array.isArray(error) ? error : [error];
    }
  },
  async deactivateBorrower({ commit }, { ref, comment }) {
    try {
      const resp = await deactivateBorrower(ref, comment);
      commit('updated_borrower', resp.data);
      commit('common/success_snackbar', 'Borrower DEACTIVATED successfully', {
        root: true,
      });
    } catch (errors) {
      const error = errors.response.data;
      throw Array.isArray(error) ? error : [error];
    }
  },
  // Borrower Profile State Side-Effects
  async fetchBorrowerLoans({ commit }, ref) {
    commit('initial_state');
    try {
      commit('common/data_requested', null, { root: true });
      const response = await getBorrowerLoans(ref);
      const { data } = response;
      commit('set_borrower_loans', data);
    } catch (error) {
      commit(
        'common/error_snackbar',
        `Failed to fetch borrower loans. An error happened ${error}`,
        {
          root: true,
        },
      );
    } finally {
      commit('common/response_received', null, { root: true });
    }
  },
  async fetchBorrowerLoan({ commit }, loanRef) {
    commit('initial_state');
    try {
      commit('common/data_requested', null, { root: true });
      const response = await getLoan(loanRef);
      const { data } = response;

      commit('set_current_borrower_loan', data);
    } catch (error) {
      commit(
        'common/error_snackbar',
        `Failed to fetch active borrower's loan. An error happened ${error}`,
        {
          root: true,
        },
      );
    } finally {
      commit('common/response_received', null, { root: true });
    }
  },
  async borrowerCommentCreateOrEdit({ commit }, { data, method, id }) {
    try {
      const resp = await createOrEditBorrowerComment(data, method, id);
      commit(
        method === 'POST' ? 'add_borrower_comment' : 'updated_borrower_comment',
        resp.data,
      );
      commit(
        'common/success_snackbar',
        `Borrower comment ${
          method === 'POST' ? 'created' : 'edited'
        } successfully`,
        {
          root: true,
        },
      );
    } catch (errors) {
      const error = errors.response.data;
      throw Array.isArray(error) ? error : [error];
    }
  },
  async loanCommentCreateOrEdit({ commit }, { data, method, id }) {
    try {
      const resp = await createOrEditLoanComment(data, method, id);
      commit(
        method === 'POST' ? 'add_loan_comment' : 'updated_loan_comment',
        resp.data,
      );
      commit(
        'common/success_snackbar',
        `Loan comment ${method === 'POST' ? 'created' : 'edited'} successfully`,
        {
          root: true,
        },
      );
    } catch (errors) {
      const error = errors.response.data;
      throw Array.isArray(error) ? error : [error];
    }
  },
  async fetchLoanFeedback({ commit }, { loanId }) {
    commit('initial_state');
    try {
      commit('common/data_requested', null, { root: true });
      const response = await getLoanFeedback(loanId);
      const { data } = response;

      commit('set_current_loan_feedback', data);
    } catch (error) {
      commit(
        'common/error_snackbar',
        `Failed to fetch active loan feedback. An error happened ${error}`,
        {
          root: true,
        },
      );
    }
  },
  async fetchBusinessFiles({ commit }, ref) {
    commit('initial_state');
    try {
      commit('common/data_requested', null, { root: true });
      const response = await getBusinessFiles(ref);
      const { data } = response;
      const results = await data.results.map((file) => {
        const createdAt = file.created_at.slice(0, 10);
        const status = file.validity_status;
        const fileNameWithExtension = file.fileName;
        const fileName = file.fileName.split('.')[0].split('_').join(' ');
        return { ...file, fileName, createdAt, status, fileNameWithExtension };
      });
      commit('set_business_files', { ...data, results });
    } catch (error) {
      commit(
        'common/error_snackbar',
        `Failed to fetch business files. An error happened ${error}`,
        {
          root: true,
        },
      );
    } finally {
      commit('common/response_received', null, { root: true });
    }
  },
  resetCurrentBorrowerLoan({ commit }) {
    commit('unset_current_borrower_loan');
  },
  async fetchAccountBusinesses({ commit }, ref) {
    commit('initial_state');
    try {
      const response = await createOrEditAccountBusiness(ref);
      commit('set_account_businesses', response.data.results);
    } catch (error) {
      commit(
        'common/error_snackbar',
        'Failed to fetch account businesses. Please try again later.',
        {
          root: true,
        },
      );
    }
  },
  async businessCreateOrEdit({ commit }, { data, method, ref }) {
    try {
      const resp = await createOrEditAccountBusiness(data, method, ref);
      commit(
        method === 'POST' ? 'add_business' : 'updated_business',
        resp.data,
      );
      commit(
        'common/success_snackbar',
        `Business ${method === 'POST' ? 'created' : 'edited'} successfully`,
        {
          root: true,
        },
      );
    } catch (errors) {
      const error = errors.response.data;
      throw Array.isArray(error) ? error : [error];
    }
  },
  async uploadKycFile({ commit }, { data }) {
    try {
      const resp = await createKycFile(data);
      commit('upload_kyc_file', {
        data: resp.data,
      });
      commit('common/success_snackbar', 'Kyc file uploaded successfully', {
        root: true,
      });
    } catch (errors) {
      const error = errors.response.data;
      throw Array.isArray(error) ? error : [error];
    }
  },
  async kycFileEdit({ commit }, { data, id }) {
    try {
      const resp = await editKycFile(data, id);
      commit('update_kyc_file', {
        data: resp.data,
      });

      commit('common/success_snackbar', 'Kyc file edited successfully', {
        root: true,
      });
    } catch (errors) {
      const error = errors.response.data;
      throw Array.isArray(error) ? error : [error];
    }
  },
};

const mutations = {
  initial_state(state) {
    state.allBorrowers = [];
    state.accountBusinesses = [];
    state.borrowers = {
      count: 0,
      links: {
        next: null,
        previous: null,
      },
      pages: 1,
      per_page: 30,
      results: [],
    };
  },
  set_borrowers(state, data) {
    data.results = data.results.map((value) => {
      const borrower = { ...value };
      borrower.loan_limit = formatCurrency(borrower.loan_limit);
      borrower.created_at = formatDate(borrower.created_at);
      return borrower;
    });

    state.allBorrowers = [...new Set([...state.allBorrowers, ...data.results])]; // remove all duplicates
    state.borrowers = data;
  },
  set_sendSmsBorrowersList(state, data) {
    state.sendSmsBorrowersList = data;
  },
  updated_borrower(state, data) {
    state.allBorrowers = state.allBorrowers.map((value) => {
      let borrower = value;
      if (borrower.ref === data.ref) {
        data.loan_limit = formatCurrency(data.loan_limit);
        borrower = { ...borrower, ...data };
      }
      return borrower;
    });
  },
  deleted_borrowers(state, ref) {
    const { allBorrowers } = state;
    const item = allBorrowers.find((borrower) => borrower.ref === ref);
    allBorrowers.splice(allBorrowers.indexOf(item), 1);
    state.allBorrowers = allBorrowers;
  },
  add_borrower(state, data) {
    state.currentBorrower = data;
    state.allBorrowers.unshift(data);
  },
  // Borrower Profile State
  set_current_borrower(state, data) {
    data.loan_limit = formatCurrency(data.loan_limit);
    if (data.comments) {
      data.comments.forEach((comment) => {
        comment.created_at = formatDate(comment.created_at);
        comment.updated_at = formatDate(comment.created_at);
      });
    }

    state.currentBorrower = data;
  },
  reset_current_borrower(state) {
    state.currentBorrower = {};
  },
  set_current_borrower_loan(state, data) {
    Object.keys(data).forEach((key) => {
      if (currencyKeys.includes(key)) {
        data[key] = formatCurrency(data[key]);
      }
    });
    const repaymentCurrencyFields = [
      'amount',
      'interest_paid',
      'outstanding_balance_after_repayment',
      'principal_paid',
      'rollover_fee_paid',
    ];
    data.repayments.forEach((payment) => {
      Object.keys(payment).forEach((key) => {
        if (repaymentCurrencyFields.includes(key)) {
          payment[key] = formatCurrency(payment[key]);
        }
      });
    });

    if (data.comments) {
      data.comments.forEach((comment) => {
        comment.created_at = formatDate(comment.created_at);
        comment.updated_at = formatDate(comment.created_at);
      });
    }
    if (data.ptps) {
      data.ptps.forEach((ptp) => {
        ptp.created_at = formatDate(ptp.created_at);
      });
    }
    state.currentBorrowerLoan = data;
  },
  set_borrower_loans(state, data) {
    data.results = data.results.map((value) => {
      const loan = { ...value };
      Object.keys(loan).forEach((key) => {
        if (currencyKeys.includes(key)) {
          loan[key] = formatCurrency(loan[key]);
        }
      });
      return loan;
    });

    state.borrowerLoans = data;
  },
  set_business_files(state, data) {
    state.businessFiles = data;
  },
  updated_borrower_comment(state, data) {
    const comment = { ...data };
    comment.created_at = formatDate(comment.created_at);
    comment.updated_at = formatDate(comment.updated_at);
    const { comments } = state.currentBorrower;
    // eslint-disable-next-line no-restricted-syntax
    for (const i in comments) {
      if (comments[i].id === comment.id) {
        comments[i] = { ...comments[i], ...comment };
        break;
      }
    }
    state.currentBorrower.comments = [...comments];
  },
  add_borrower_comment(state, data) {
    const comment = { ...data };
    comment.created_at = formatDate(comment.created_at);
    comment.updated_at = formatDate(comment.updated_at);
    state.currentBorrower.comments.unshift(comment);
  },
  updated_loan_comment(state, data) {
    const comment = { ...data };
    comment.created_at = formatDate(comment.created_at);
    comment.updated_at = formatDate(comment.updated_at);
    const type = data.type === 'Comment' ? 'comments' : 'feedback';
    const index = state.currentBorrowerLoan[type].findIndex(
      (x) => x.id === data.id,
    );
    Vue.set(state.currentBorrowerLoan[type], index, comment);
  },
  add_loan_comment(state, data) {
    const activeLoan = { ...state.currentBorrowerLoan };
    const comment = { ...data };
    comment.created_at = formatDate(comment.created_at);
    comment.updated_at = formatDate(comment.updated_at);
    const type = data.type === 'Comment' ? 'comments' : 'feedback';
    activeLoan[type].unshift(comment);

    state.currentBorrowerLoan = activeLoan;
  },
  set_current_loan_feedback(state, data) {
    const activeLoan = { ...state.currentBorrowerLoan };
    activeLoan.feedback = data.results.map((feedback) => {
      const fb = { ...feedback };
      fb.created_at = formatDate(fb.created_at);
      fb.updated_at = formatDate(fb.updated_at);
      return fb;
    });

    state.currentBorrowerLoan = activeLoan;
  },
  unset_current_borrower_loan() {
    state.currentBorrowerLoan = [];
  },
  update_kyc_file(state, { ...data }) {
    state.getBusinessFiles.results = state.getBusinessFiles.results.map(
      (value) => {
        let kycFile = value;
        if (kycFile.id === data.id) {
          kycFile = { ...kycFile, ...data.data };
        }
        return kycFile;
      },
    );
  },
  upload_kyc_file(state, { ...data }) {
    state.businessFiles.results.unshift(data.data);
  },
  set_account_businesses(state, data) {
    if (!data) return;
    state.currentBorrower.businesses = [
      ...state.currentBorrower.businesses,
      data,
    ];
  },
  add_business(state, data) {
    state.currentBorrower.businesses = [
      ...state.currentBorrower.businesses,
      data,
    ];
  },
  updated_business(state, data) {
    const business = { ...data };
    business.created_at = formatDate(business.created_at);
    business.updated_at = formatDate(business.updated_at);
    const { businesses } = state.currentBorrower;
    // eslint-disable-next-line no-restricted-syntax
    for (const i in businesses) {
      if (businesses[i].id === businesses.id) {
        businesses[i] = { ...businesses[i], ...businesses };
        break;
      }
    }
    state.currentBorrower.accountBusinesses = [...businesses];
  },
  add_borrower_business(state, data) {
    const business = { ...data };
    business.created_at = formatDate(business.created_at);
    business.updated_at = formatDate(business.updated_at);
    state.currentBorrower.accountBusinesses.unshift(business);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  getAccountBusinesses,
  createKycFile,
  editKycFile,
};
