/* eslint no-underscore-dangle: 0 */
import Vue from 'vue';
import VueFormGenerator from 'vue-form-generator';
import VueConfirmDialog from 'vue-confirm-dialog';
import axios from 'axios';
import jwt from 'jsonwebtoken';
import vuetify from './plugins/vuetify';
import App from './App.vue';
import store from './store';
import router from './router';
import 'vue-form-generator/dist/vfg.css';

Vue.use(VueFormGenerator);
Vue.use(VueConfirmDialog);

Vue.http = axios.create({ baseURL: process.env.VUE_APP_BASE_URL });
const requestService = axios.create({ baseURL: process.env.VUE_APP_BASE_URL });

// function to intercept API request and append Bearer token
Vue.http.interceptors.request.use((config) => {
  const reqObject = config;
  const token = localStorage.getItem('token');
  reqObject.headers.Authorization = `Bearer ${token}`;
  return reqObject;
});

Vue.http.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    const decoded = jwt.decode(localStorage.getItem('refreshToken'));
    if (decoded.exp < Date.now() / 1000) {
      store.commit('auth/logout');
      store.commit(
        'common/error_snackbar',
        'Session expired, please log back in.',
      );
      router.push('/');
    }
    if (decoded.exp > Date.now() / 1000) {
      if (err.response.status === 401) {
        originalConfig._retry = true;
        try {
          const rs = await requestService.post('/api/v2/token/refresh/', {
            refresh: localStorage.getItem('refreshToken'),
          });
          store.commit('auth/update_token', rs.data);
          err.config.headers.Authorization = `Bearer ${rs.data.access}`; // eslint-disable-line no-param-reassign
          return new Promise((resolve, reject) => {
            requestService
              .request(originalConfig)
              .then((response) => {
                resolve(response);
              })
              .catch((e) => {
                reject(e);
              });
          });
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }
    return Promise.reject(err);
  },
);

new Vue({
  vuetify,
  render: (h) => h(App),
  store,
  router,
}).$mount('#app');
