<template>
  <div v-if="formInitialized">
    <div class="wrapper elevation-1">
      <div v-if="!formModel"><h2>No Action Available</h2></div>
      <div v-if="formModel" style="dispay: flex">
        <form action="" id="searchForm" @submit.prevent="submit">
          <ErrorAlert
            v-if="formErrors.length > 0"
            :errors="formErrors"
            sticky
          />
          <vue-form-generator
            :schema="formSchema"
            :model="formModel"
          ></vue-form-generator>
        </form>
        <div class="filter-actions">
          <h5 @click="submit">Filter</h5>
          <h5 v-if="filtered" @click="clear">Clear Filter</h5>
        </div>
      </div>
    </div>
    <div v-if="formModel" class="triangle"></div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import ErrorAlert from '../../components/Alert/ErrorAlert.vue';
import {
  defaultModel,
  defaultSchema,
} from '../../formSchemas/borrower-profile/searchBorrower';

export default {
  name: 'AdvancedAccountSearch',
  components: {
    ErrorAlert,
  },
  data() {
    return {
      formInitialized: false,
      filtered: false,
      formSchema: { ...defaultSchema },
      formModel: { ...defaultModel },
      formErrors: [],
    };
  },
  methods: {
    ...mapActions('partners', ['fetchPartners']),
    addIdToDropdownOptions(options) {
      const data = [];
      options.forEach((option) => {
        const id = option.ref;
        const entry = { id, ...option };
        data.push(entry);
      });
      return data;
    },
    clear() {
      this.formModel = { ...defaultModel };
      this.filtered = false;
      this.$store.dispatch(`sms/setSendSmsFilterFields`, {});
      this.$emit('clear');
    },
    submit() {
      this.$store.dispatch(`sms/setSendSmsFilterFields`, {});
      this.formErrors = [];
      this.filtered = true;
      const partners = this.$store.getters['partners/allPartners'];
      const partnerRefs = partners
        .filter((item) => this.formModel.partner.includes(item.name))
        .map((item) => item.ref);
      const searchParams = {
        ...this.formModel,
        partner: partnerRefs,
      };

      const searchFilterFields = {
        status: searchParams.status,
        partner: searchParams.partner,
        ptpStatus: searchParams.ptpStatus,
        loanStatus: searchParams.loanStatus,
        loanComment: searchParams.loanComment,
        upperLoanLimit: searchParams.upperLoanLimit,
        lowerLoanLimit: searchParams.lowerLoanLimit,
        business: searchParams.business,
      };
      this.$store.dispatch(`sms/setSendSmsFilterFields`, searchFilterFields);
      this.$emit('search', searchParams);
    },
  },
  async mounted() {
    await this.fetchPartners();
    this.formSchema = this.schemaWithPartners;
    this.formInitialized = true;
  },
  computed: {
    schemaWithPartners() {
      const partners = this.$store.getters['partners/allPartners'];
      const partnerOptionsWithId = this.addIdToDropdownOptions(partners);

      const finalData = {
        fields: [],
      };
      const borrowerNames = [];
      function appendNamesToList() {
        // Iterate through the partners array and extract names
        partnerOptionsWithId.forEach((borrower) => {
          borrowerNames.push(borrower.name);
        });
      }
      appendNamesToList();
      this.formSchema.fields.forEach((field) => {
        if (field.label === 'Distributor') {
          const { values, ...rest } = field;
          const newField = { ...rest, values: borrowerNames };
          finalData.fields.push(newField);
          return;
        }

        finalData.fields.push(field);
      });

      return finalData;
    },
  },
};
</script>

<style scoped>
.wrapper {
  border-radius: 5px;
  background-color: white;
  box-shadow: 0px 1px 10px -1px rgba(190, 190, 190, 0.9);
  padding: 20px 30px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.filter-actions {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #699a4c;
}

h5 {
  padding-left: 20px;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
}

.triangle {
  width: 120px;
  height: 25px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 16px 10px -17px rgba(0, 0, 0, 0.9);
}

.triangle:after {
  content: '';
  position: absolute;
  width: 35px;
  height: 35px;
  background: #fff;
  transform: rotate(45deg); /* Prefixes... */
  top: -20px;
  left: 50px;
  box-shadow: -2px -2px 10px -2px rgba(0, 0, 0, 0.9);
}
</style>
