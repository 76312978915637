<template>
  <Modal
    :openModal="display"
    v-model="show"
    v-on:close-modal="$emit('close-modal')"
    :title="title"
    class="col-6"
  >
    <template v-slot:content>
      <form action="" @submit.prevent="handleClick" id="repaymentForm">
        <ErrorAlert
          v-if="formErrors ? formErrors.length > 0 : false"
          :errors="formErrors"
          sticky
        />
        <vue-form-generator
          :schema="formSchema"
          :model="formModel"
        ></vue-form-generator>
        <div class="form-group col-md-6 pt-0 field-input">
          <v-file-input
            counter
            small-chips
            truncate-length="15"
            label="Upload File"
            @change="onFileChanged($event)"
          ></v-file-input>
        </div>
        <br />
        <Button
          text="Submit"
          type="submit"
          styling="primary"
          validateBeforeSubmit
          :disabled="isDisabled"
        ></Button>
      </form>
    </template>
  </Modal>
</template>

<script>
import Modal from '../../components/Modal/Modal.vue';
import Button from '../../components/Button/Button.vue';
import {
  postWaiverSchema,
  postWaiverModel,
} from '../../formSchemas/postWaiver';
import ErrorAlert from '../../components/Alert/ErrorAlert.vue';

export default {
  name: 'PostWaiverModal',
  emits: ['close-modal'],
  props: ['loanRef', 'loanUrl', 'display', 'formErrors'],
  components: { Modal, ErrorAlert, Button },
  data() {
    return {
      formModel: {},
      formSchema: {},
      loanLimit: null,
      isLoading: false,
    };
  },
  computed: {
    show: {
      get() {
        return this.display;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
    title() {
      return `Post a waiver for loan ${this.loanRef}`;
    },
    isDisabled() {
      return this.isLoading;
    },
  },
  mounted() {
    this.formModel = postWaiverModel;
    this.formSchema = postWaiverSchema;
  },
  methods: {
    onFileChanged(newFile) {
      this.fileUrl = newFile;
    },
    handleClick() {
      this.$confirm({
        message: `Are you sure you want to post a waiver for this loan?`,
        button: {
          no: 'Cancel',
          yes: 'Yes',
        },
        callback: (confirm) => {
          if (confirm) {
            this.submitPostWaiver();
          }
        },
      });
    },
    submitPostWaiver() {
      const formData = new FormData();
      const { reason, amount } = this.formModel;
      formData.append(
        'attachment',
        this.fileUrl === undefined ? '' : this.fileUrl,
      );
      formData.append('reason', reason);
      formData.append('amount', amount);
      formData.append('loan', this.loanUrl);
      const { loanRef } = this;
      this.isLoading = true;
      this.$store
        .dispatch(`loans/postWaiver`, {
          data: formData,
          loanRef,
        })
        .then(() => {
          this.isLoading = false;
          this.formModel = {};
          this.$emit('close-modal');
        })
        .catch((err) => {
          this.isLoading = false;
          this.formErrors = err;
        });
    },
  },
};
</script>

<style scoped>
.capitalise {
  text-transform: capitalize;
}
</style>
