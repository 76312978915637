import CryptoJS from 'crypto-js';

const encrypt = (data) => {
  let key = 'Pe69m*A%tADq@D7J';
  key = CryptoJS.enc.Utf8.parse(key);
  const iv = CryptoJS.enc.Utf8.parse('a$gyaS9uYgy6%y^a');
  let encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), key, {
    iv,
    mode: CryptoJS.mode.CBC,
  });
  encrypted = encrypted.toString();
  return encrypted;
};

const decrypt = (Base64CBC) => {
  let key = 'Pe69m*A%tADq@D7J'; // key used in Python
  key = CryptoJS.enc.Utf8.parse(key);
  const iv = CryptoJS.enc.Utf8.parse('a$gyaS9uYgy6%y^a');
  let decrypted = CryptoJS.AES.decrypt(Base64CBC.data, key, {
    iv,
    mode: CryptoJS.mode.CBC,
  });
  decrypted = decrypted.toString(CryptoJS.enc.Utf8);

  return decrypted;
};

// eslint-disable-next-line import/prefer-default-export
export { encrypt, decrypt };
