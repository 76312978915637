<template>
  <div v-if="currentBorrower">
    <!-- ACCOUNT SUMMARY -->
    <v-card-title class="pl-0 d-flex justify-space-between">
      {{ currentBorrower.name }}

      <v-btn
        v-if="isAdmin"
        color="primary"
        dark
        text
        @click="editBorrowerDetails"
      >
        Edit
      </v-btn>
    </v-card-title>
    <div class="content">
      <v-row>
        <v-col lg="4" md="6" sm="12" class="details">
          <v-row>
            <v-col class="light"> Borrower Ref. </v-col>
            <v-col class="dark">
              {{ currentBorrower.ref }}
            </v-col>
          </v-row>

          <v-row>
            <v-col class="light"> Nominated Phone No. </v-col>
            <v-col class="dark">
              {{ currentBorrower.phone }}
            </v-col>
          </v-row>

          <v-row>
            <v-col class="light"> Owner Name </v-col>
            <v-col class="dark">
              {{ formatter(currentBorrower.owner_name) }}
            </v-col>
          </v-row>

          <v-row>
            <v-col class="light"> Owner Phone No. </v-col>
            <v-col class="dark">
              {{ formatter(currentBorrower.owner_phone) }}
            </v-col>
          </v-row>

          <v-row>
            <v-col class="light"> National ID No. </v-col>
            <v-col class="dark">
              {{ formatter(currentBorrower.national_id_number) }}
            </v-col>
          </v-row>

          <v-row>
            <v-col class="light"> Happiness Index </v-col>
            <v-col class="dark"> - </v-col>
          </v-row>
        </v-col>

        <v-col lg="4" md="6" sm="12" class="details">
          <v-row>
            <v-col class="light"> Status </v-col>
            <v-col class="dark">
              {{ formatter(currentBorrower.status) }}
            </v-col>
          </v-row>

          <v-row>
            <v-col class="light"> Health Status </v-col>
            <v-col class="dark">
              {{ formatter(currentBorrower.health_status) }}
            </v-col>
          </v-row>

          <v-row>
            <v-col class="light"> Loan Limit </v-col>
            <v-col class="dark"> {{ currentBorrower.loan_limit }} </v-col>
          </v-row>

          <v-row>
            <v-col class="light"> Max. Loan Duration </v-col>
            <v-col class="dark">
              {{ currentBorrower.has_max_loan_duration ? 30 : 15 }} Days
            </v-col>
          </v-row>

          <v-row>
            <v-col class="light"> Loan Limit Lock </v-col>
            <v-col class="dark">
              {{ formatter(currentBorrower.loan_limit_lock) }}
            </v-col>
          </v-row>

          <v-row>
            <v-col class="light"> CRB score </v-col>
            <v-col class="dark">
              {{ formatter(currentBorrower.metro_score) }}
            </v-col>
          </v-row>
        </v-col>

        <v-col lg="4" md="6" sm="12" class="details">
          <v-row>
            <v-col class="light"> Distributor </v-col>
            <v-col class="dark">
              {{ (currentBorrower.partner || '').split('/').pop() }}
            </v-col>
          </v-row>

          <v-row>
            <v-col class="light"> Referee </v-col>
            <v-col class="dark">
              {{ formatter(currentBorrower.referee_name) }}
            </v-col>
          </v-row>

          <v-row>
            <v-col class="light"> Referee Phone </v-col>
            <v-col class="dark">
              {{ formatter(currentBorrower.referee_phone) }}
            </v-col>
          </v-row>

          <v-row>
            <v-col class="light"> Referee Relationship </v-col>
            <v-col class="dark">
              {{ formatter(currentBorrower.referee_relationship) }}
            </v-col>
          </v-row>

          <v-row>
            <v-col class="light"> Sales Rep No. </v-col>
            <v-col class="dark">
              {{ formatter(currentBorrower.sales_rep_phone) }}
            </v-col>
          </v-row>

          <v-row>
            <v-col class="light"> Date Onboarded </v-col>
            <v-col class="dark">
              {{ formatDate(currentBorrower.created_at) }}
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>

    <!-- BUSINESSES SUMMARY -->
    <v-card-title class="title_2"> Outlets </v-card-title>
    <template>
      <div class="businesses" v-if="(currentBorrower.businesses || []).length">
        <v-expansion-panels
          v-for="business of currentBorrower.businesses"
          :key="business.name"
          flat
          class="accordion"
        >
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h4>
                {{ business.name }}
              </h4>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col>
                  <v-row class="dark">
                    <v-col class="light">Business Name </v-col>
                    <v-col class="data">
                      {{ business.name }}
                    </v-col>
                  </v-row>

                  <v-row class="dark">
                    <v-col class="light">Trading Name </v-col>
                    <v-col class="data">
                      {{ formatter(business.trading_name) }}
                    </v-col>
                  </v-row>

                  <v-row class="dark">
                    <v-col class="light">Registration Type </v-col>
                    <v-col>
                      {{
                        business.registration_type
                          ? formatter(business.registration_type)
                          : '-'
                      }}
                    </v-col>
                  </v-row>

                  <v-row class="dark">
                    <v-col class="light">Distributor Till Number</v-col>
                    <v-col class="data">
                      {{ formatter(business.distributor_till_number) }}
                    </v-col>
                  </v-row>
                </v-col>

                <v-col>
                  <v-row class="dark">
                    <v-col class="light">KE Number </v-col>
                    <v-col class="data">
                      {{ formatter(business.ke_number) }}
                    </v-col>
                  </v-row>

                  <v-row class="dark">
                    <v-col class="light">Location </v-col>
                    <v-col class="data">
                      {{ formatter(business.location) }}
                    </v-col>
                  </v-row>

                  <v-row class="dark">
                    <v-col class="light">Route Segment </v-col>
                    <v-col class="data">
                      {{ formatter(business.route_segment) }}
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
      <template v-else>
        <v-expansion-panels :disabled="true" flat class="accordion businesses">
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h4>No Businesses registered under this Account</h4>
            </v-expansion-panel-header>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>
    </template>

    <template v-if="isAdmin">
      <div class="width100">
        <v-layout justify-center flex-column align-stretch>
          <v-tabs v-model="tab">
            <v-tab
              v-for="item in tabItems"
              :key="item.tab"
              @click="changeTab(item.tab, item.firstClick)"
              >{{ item.tab }}</v-tab
            >
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item v-for="item in tabItems" :key="item.tab">
              <v-list-item-content v-if="selectedComponent === 'comments'">
                <div class="d-flex justify-space-between align-center">
                  <h4 class="pl-4">Admin Comments</h4>
                  <Button
                    v-on:button-clicked="openCommentForm"
                    text="Add Comment"
                  ></Button>
                </div>
                <CommentsTable
                  v-bind:commentType="commentType"
                  v-bind:comments="currentBorrower.comments"
                  v-on:open-comment-detail="openCommentForm"
                ></CommentsTable>
              </v-list-item-content>
              <v-list-item-content v-if="selectedComponent === 'messages'">
                <SMSTable />
              </v-list-item-content>
            </v-tab-item>
          </v-tabs-items>
        </v-layout>
      </div>
    </template>

    <CommentFormModal
      v-bind:commentType="commentType"
      v-bind:comment="commentProp"
      v-bind:resourceRef="currentBorrower.ref"
      v-bind:display="showCommentFormModal"
      v-on:close-modal="showCommentFormModal = false"
    ></CommentFormModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CommentsTable from './CommentsTable.vue';
import CommentFormModal from './CommentFormModal.vue';
import Button from '../../components/Button/Button.vue';
import SMSTable from './Sms/Sms.vue';
import { formatDate } from '../../utils/date';

export default {
  name: 'AccountDetails',
  props: ['currentBorrower', 'canEdit'],
  components: {
    CommentsTable,
    SMSTable,
    CommentFormModal,
    Button,
  },
  data() {
    const tabItems = [
      {
        tab: 'comments',
        firstClick: true,
      },
    ];
    tabItems.push({
      tab: 'messages',
      firstClick: false,
    });
    return {
      formatDate,
      borrowerRef: this.$route.params.ref,
      commentType: 'admin comment',
      commentProp: null,
      showCommentFormModal: false,
      selectedComponent: 'comments',
      tab: null,
      tabItems,
    };
  },
  computed: {
    ...mapGetters('auth', ['isAdmin']),
  },
  methods: {
    formatter(text) {
      if (!text) return '-';
      // eslint-disable-next-line radix
      if (parseInt(text) === 0) return '-';
      return text.slice(0, 1).toUpperCase() + text.slice(1);
    },
    openCommentForm(comment = null) {
      this.commentProp = comment;
      this.showCommentFormModal = true;
    },
    editBorrowerDetails() {
      this.$router.push({
        path: `/borrowers/edit/${this.currentBorrower.ref}`,
        params: { ref: this.currentBorrower.ref },
      });
    },
    changeTab(status) {
      switch (status) {
        case 'comments':
          this.selectedComponent = status;
          break;

        case 'messages':
          this.selectedComponent = status;
          this.$store.dispatch(`sms/fetchSmses`, {
            searchParams: {
              borrower: this.currentBorrower.id,
            },
          });
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style scoped>
.wrapper {
  margin: 0 35px 60px;
  padding: 20px 30px 40px;
}
.content {
  margin-right: 30px;
  padding-bottom: 30px;
}
.businesses {
  padding-bottom: 30px;
}
.light {
  color: #8c98a4;
}
.dark {
  font-weight: 500;
}
.details {
  font-size: 15px;
  padding-right: 50px;
}
h4 {
  font-weight: 500;
}
.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: #f1f1f1;
}
</style>
