<template>
  <div class="width100">
    <PageHeader
      headingText="Products"
      btnText="Create New Product"
      v-on:action-button-clicked="openProductForm()"
    ></PageHeader>
    <v-layout justify-center flex-column align-stretch class="ma-4">
      <Table
        :tableData="this.products.results"
        :options.sync="options"
        :serverItemsLength="this.products.count"
        :itemsPerPage="this.products.per_page"
        :headers="this.headers"
        v-on:updated-options="fetchProductsWithPage"
        class="ma-auto"
      >
        <template v-slot:actions="slotProps">
          <Action
            :item="slotProps.item"
            :icon="detailsIcon"
            :action="openItemDetails"
            :color="greyIcon"
          />
          <Action
            :item="slotProps.item"
            :icon="editIcon"
            :action="openProductForm"
            :color="greenIcon"
          />
          <Action
            :item="slotProps.item"
            :icon="deleteIcon"
            :action="openDeleteProduct"
            :color="redIcon"
          />
        </template>
      </Table>
      <ProductForm
        v-bind:product="this.productProp"
        v-bind:display="this.showProductModal"
        v-on:close-modal="(showProductModal = false), (showDeleteModal = false)"
      ></ProductForm>
      <DeleteProduct
        v-bind:product="this.productProp"
        v-bind:display="this.showDeleteModal"
        v-on:close-modal="(showDeleteModal = false), (showProductModal = false)"
      ></DeleteProduct>
    </v-layout>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DeleteProduct from './DeleteProduct.vue';
import Table from '../../components/Table/Table.vue';
import PageHeader from '../../components/PageHeader/PageHeader.vue';
import Action from '../../components/Action/Action.vue';
import ProductForm from './ProductForm.vue';

export default {
  name: 'Products',
  components: {
    PageHeader,
    Table,
    Action,
    DeleteProduct,
    ProductForm,
  },
  data() {
    return {
      deleteIcon: 'mdi-delete',
      editIcon: 'mdi-lead-pencil',
      detailsIcon: 'mdi-eye',
      greenIcon: 'green',
      redIcon: 'red',
      greyIcon: 'grey',
      productProp: null,
      showProductModal: false,
      showDeleteModal: false,
      options: {},
      headers: [
        {
          value: 'name',
          text: 'Product Name',
          sortable: true,
          searchable: true,
        },
        {
          value: 'ref',
          text: 'Product Ref',
          sortable: true,
          searchable: true,
        },
        {
          value: 'partner',
          text: 'Partner',
          sortable: true,
          searchable: true,
        },
        {
          value: 'loan_limit',
          text: 'Loan Limit (KShs)',
          sortable: true,
          searchable: true,
        },
        {
          value: 'default_interest_rate',
          text: 'Default interest rate',
          sortable: true,
          searchable: true,
        },
        {
          value: 'transaction_charge',
          text: 'Transaction Charge (KShs)',
          sortable: true,
          searchable: true,
        },
        {
          value: 'action',
          text: 'Actions',
          sortable: true,
          searchable: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters('products', ['products']),
  },
  methods: {
    ...mapActions('products', ['fetchProducts']),
    async fetchProductsWithPage(options) {
      const { page } = options;
      await this.fetchProducts(page);
    },
    openProductForm(product = null) {
      this.productProp = product;
      this.showProductModal = true;
    },
    openDeleteProduct(product) {
      this.productProp = product;
      this.showDeleteModal = true;
    },
    openItemDetails(data) {
      const { url, ...dataToDisplay } = data;
      this.$router.push({ name: 'details', params: { dataToDisplay } });
    },
  },
};
</script>
