<template>
  <Modal
    :title="title"
    :openModal="display"
    v-on:close-modal="$emit('close-loan-table-modal')"
  >
    <template v-slot:content>
      <v-layout
        justify-center
        justify-space
        flex-row
        align-stretch
        class="ma-4"
      >
        <v-date-picker v-model="datePicker" class="mr-6"></v-date-picker>

        <v-simple-table>
          <template v-slot:default>
            <tbody>
              <tr v-for="item in tableValues" :key="item">
                <td class="capitalise">{{ item.split('_').join(' ') }}</td>
                <td>{{ $data[item] }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-layout>
    </template>
  </Modal>
</template>

<script>
import Modal from '../../components/Modal/Modal.vue';

export default {
  name: 'OutstandingBalanceCalculator',
  emits: ['close-loan-table-modal'],
  props: ['loanRef', 'borrowerName', 'display'],
  components: { Modal },
  data() {
    return {
      datePicker: new Date().toISOString().substr(0, 10),
      principal: 0,
      charges_due: 0,
      total_outstanding_balance: 0,
      tableValues: ['principal', 'charges_due', 'total_outstanding_balance'],
    };
  },
  computed: {
    title() {
      return `Loan ${this.loanRef} by ${this.borrowerName}`;
    },
  },
  watch: {
    async datePicker(newVal) {
      // TODO: Map request balance to proper values that are returned
      const data = await this.$store.dispatch(
        'loans/requestOutstandingBalance',
        { date: newVal, loanRef: this.loanRef },
      );

      this.principal = data.outstanding_principal || 0;
      this.charges_due = data.outstanding_charges || 0;
      this.total_outstanding_balance = data.total_outstanding_balance || 0;
    },
  },
};
</script>

<style scoped>
.capitalise {
  text-transform: capitalize;
}
</style>
