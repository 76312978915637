const loanApplicationSchema = {
  fields: [
    {
      type: 'select',
      label: 'Select product',
      model: 'product',
      required: true,
      values: () => {
        return {};
      },
    },
    {
      type: 'input',
      inputType: 'number',
      label: 'Principal',
      model: 'principal',
      required: true,
      placeholder: 10000,
      min: 1,
    },
    {
      type: 'input',
      inputType: 'text',
      label: 'Loan period',
      model: 'loan_period',
      required: true,
      validator: ['number'],
    },
    {
      type: 'input',
      inputType: 'text',
      label: 'Interest Rate',
      model: 'interest_rate',
      required: true,
      validator: ['double'],
    },
    {
      type: 'input',
      inputType: 'text',
      label: 'Applicant',
      placeholder: 'Enter applicant',
      model: 'applicant',
      required: true,
    },
    {
      type: 'input',
      inputType: 'text',
      label: 'Till Number',
      model: 'disbursement_account',
      required: true,
    },
  ],
};

const loanApplicationModel = {
  principal: 0,
  interest_rate: 0,
  applicant: '',
  loan_period: '',
  disbursement_account: '',
  product: {},
};

export { loanApplicationSchema, loanApplicationModel };
