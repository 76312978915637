const kycFileSchema = {
  fields: [
    {
      type: 'select',
      label: 'Business',
      model: 'business',
      values: () => {
        return {};
      },
    },
    {
      type: 'select',
      label: 'File Type',
      model: 'file_type',
      default: '',
      values: ['LIQUOR_LICENCE', 'BUSINESS_PERMIT', 'NATIONAL_ID'],
    },
    {
      type: 'select',
      label: 'Validity Status',
      model: 'validity_status',
      default: '',
      values: ['valid', 'expired'],
    },
    {
      type: 'input',
      inputType: 'date',
      label: 'Validity Date',
      model: 'validity_date',
      format: 'YYYY-MM-DD',
      validator: ['required'],
    },
  ],
};

const kycFileModel = {
  business: {},
  validity_status: '',
  validity_date: '',
  file_type: '',
  fileUrl: '',
};

export { kycFileModel, kycFileSchema };
