<template>
  <v-btn
    :class="styling"
    style="margin-right: 10px"
    @click="buttonClicked"
    :disabled="disabled"
    rounded
    elevation="0"
  >
    <slot></slot>
    <v-progress-circular
      indeterminate
      color="#95bf7c"
      v-if="this.isLoading"
    ></v-progress-circular>
    <span v-if="!this.isLoading">{{ text }}</span>
  </v-btn>
</template>

<script>
export default {
  name: 'Button',
  emits: ['button-clicked'],
  props: {
    text: { type: String, required: true },
    disabled: { type: Boolean, required: false, default: false },
    isLoading: { type: Boolean, required: false, default: false },
    styling: {
      type: String,
      required: false,
      default: () => 'primary',
      validator: (value) => {
        return ['primary', 'secondary'].includes(value);
      },
    },
  },

  methods: {
    buttonClicked() {
      if (this.isLoading) return;
      this.$emit('button-clicked');
    },
  },
};
</script>

<style lang="scss">
.v-btn--rounded {
  box-shadow: none;
  color: unset;
  text-transform: none !important;
  &:active,
  &:hover {
    transform: scale(1.01, 1.01);
    box-shadow: none;
  }

  &.btn--primary {
    background: $primary-green !important;
    border: 1px solid $primary-green;
    color: #ffffff;
  }

  &.v-btn.v-btn--disabled {
    background: $gray;
    border: $gray;
  }
}
</style>
