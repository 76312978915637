const repaymentSchema = {
  fields: [
    {
      type: 'input',
      inputType: 'text',
      label: 'Loan Ref',
      model: 'loanRef',
      placeholder: 'E.g. VEWNXX1',
      required: true,
      max: 150,
      min: 5,
      validator: ['required'],
    },
    {
      type: 'input',
      inputType: 'text',
      label: 'Borrower KE number',
      model: 'loanAccountNumber',
      placeholder: 'E.g. KE0146129',
      required: true,
      max: 150,
      min: 5,
      validator: ['required'],
    },
  ],
};

const repaymentModel = {
  loanRef: '',
  loanAccountNumber: '',
};

export { repaymentModel, repaymentSchema };
