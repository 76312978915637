<template>
  <Modal
    :title="'Set Date and Time'"
    :openModal="display"
    v-on:close-modal="$emit('close-modal')"
  >
    <template v-slot:content>
      <template>
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-menu
              ref="menu"
              v-model="dateMenu"
              :close-on-content-click="false"
              :return-value.sync="date"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  label="Select Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="date" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.menu.save(date)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="11" sm="5">
            <v-menu
              ref="menu"
              v-model="timeMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="time"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="time"
                  label="Select Time"
                  prepend-icon="mdi-clock-time-four-outline"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="timeMenu"
                v-model="time"
                :allowed-minutes="allowedStep"
                full-width
                @click:minute="$refs.menu.save(time)"
              ></v-time-picker>
            </v-menu>
          </v-col>
        </v-row>
      </template>
    </template>
    <template v-slot:footer>
      <Button
        text="Schedule"
        type="submit"
        v-on:button-clicked="$emit('schedule', { date, time })"
        :disabled="!date || !time"
        styling="primary"
      ></Button>
    </template>
  </Modal>
</template>

<script>
/* eslint-disable camelcase */
import Modal from '../../../components/Modal/Modal.vue';
import Button from '../../../components/Button/Button.vue';

export default {
  name: 'TimeSchedulerModal',
  emits: ['close-modal'],
  props: ['display', 'selectedCount'],
  components: {
    Modal,
    Button,
  },
  data() {
    return {
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .slice(0, 10),
      time: null,
      dateMenu: false,
      timeMenu: false,
    };
  },
  methods: {
    allowedStep: (m) => m % 10 === 0,
  },
};
</script>
