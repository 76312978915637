<template>
  <Modal
    :openModal="display"
    v-model="show"
    v-on:close-modal="$emit('close-modal')"
    :title="'Create DSR'"
    class="col-6"
  >
    <template v-slot:content>
      <form autocomplete="off" action="" @submit.prevent="submitRequest">
        <input type="hidden" autocomplete="false" />
        <ErrorAlert
          v-if="formErrors ? formErrors.length > 0 : false"
          :errors="formErrors"
          sticky
        />

        <vue-form-generator
          :schema="formSchema"
          :model="formModel"
        ></vue-form-generator>
        <Button
          text="Submit"
          type="submit"
          styling="primary"
          validateBeforeSubmit
          :disabled="isDisabled"
        ></Button>
      </form>
    </template>
  </Modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Modal from '../../components/Modal/Modal.vue';
import Button from '../../components/Button/Button.vue';
import { addDSRSchema, addDSRModel } from '../../formSchemas/dsrs/addDSR';
import ErrorAlert from '../../components/Alert/ErrorAlert.vue';

export default {
  name: 'CreateDSR',
  emits: ['close-modal'],
  props: ['display'],
  components: { Modal, ErrorAlert, Button },
  data() {
    return {
      formModel: {},
      formSchema: {},
      formErrors: [],
      loanLimit: null,
      selected: '',
      options: [],
      isLoading: false,
    };
  },
  computed: {
    isDisabled() {
      return this.isLoading;
    },
    show: {
      get() {
        return this.display;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
    ...mapGetters('usergroups', ['usergroups']),
    schemaWithGroups() {
      const userGroups = this.$store.getters['usergroups/usergroups'];
      const userGroupsWithId = this.addIdToDropdownOptions(userGroups);
      const finalData = {
        fields: [],
      };
      addDSRSchema.fields.forEach((field) => {
        if (field.label === 'Select User Group') {
          const { values, ...rest } = field;
          const newField = { ...rest, values: () => userGroupsWithId };
          finalData.fields.push(newField);
          return;
        }
        finalData.fields.push(field);
      });
      return finalData;
    },
  },
  async mounted() {
    await this.fetchUserGroups();
    this.formModel = addDSRModel;
    this.formSchema = this.schemaWithGroups;
  },
  methods: {
    ...mapActions('usergroups', ['fetchUserGroups']),
    validateSelection(selection) {
      this.selected = selection;
    },
    addIdToDropdownOptions(options) {
      const data = [];
      if (options) {
        options.results.forEach((option) => {
          const entry = option.name;
          data.push(entry);
        });
      }
      return data;
    },
    submitRequest() {
      const groupsList = [];
      this.formErrors = [];
      const method = 'POST';
      const finalObject = this.formModel;
      groupsList.push(finalObject.groups);
      finalObject.groups = groupsList;
      this.isLoading = true;
      this.$store
        .dispatch(`dsrs/createOrEditDSR`, {
          data: finalObject,
          method,
        })
        .then(() => {
          this.isLoading = false;
          this.formModel = {};
          this.$emit('close-modal');
        })
        .catch((err) => {
          this.isLoading = false;
          this.formErrors = err;
        });
    },
  },
};
</script>

<style lang="scss">
.capitalise {
  text-transform: capitalize;
}
.span {
  margin-left: 5px;
}
.dropdown {
  display: block;
  margin-bottom: 12px !important;
  .dropdown-input {
    min-width: 100% !important;
    max-width: 100% !important;
    background-image: none !important;
    border-radius: 24px !important;
    border: 1px solid rgb(14, 151, 71) !important;
  }
}
#__lpform_dropdown_icon {
  display: none !important;
}
</style>
