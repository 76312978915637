import { makeSignupRequest } from '../api/users.api';

const state = () => ({
  allUsers: [],
});

const getters = {
  allPartners: (state) => state.allPartners,
  partnerGivenName: (state) => (partnerName) =>
    state.allPartners.find(({ name }) => name.toUpperCase() === partnerName),
  partnerUsingId: (state) => (partnerid) =>
    state.allPartners.find(({ id }) => id === partnerid),
  currentUserName: () => localStorage.getItem('user'),
};

const actions = {
  async signup({ commit }, { userData }) {
    try {
      // commit('auth_request');
      await makeSignupRequest(userData);
      // reveal password and allow copying to clipboard
      // optionally send email in backend to the user to reset password
      commit(
        'common/success_snackbar',
        `User ${userData.email} has been added!`,
        {
          root: true,
        },
      );
    } catch (error) {
      commit('auth_error');
      throw error;
    }
  },
};

const mutations = {
  intial_state(state) {
    state.allUsers = [];
  },
  set_users(state, data) {
    state.allUsers = data;
  },
  signup_success(state, data) {
    state.allUsers = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
