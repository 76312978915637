<template>
  <div class="width100">
    <PageHeader
      headingText="Borrowers"
      :btnText="createButtonText"
      v-on:action-button-clicked="openBorrowerForm()"
    ></PageHeader>
    <v-layout justify-center flex-column align-stretch class="ma-4">
      <v-text-field
        v-model="searchTerm"
        placeholder="Enter a search term"
        append-outer-icon="search"
        clearable
        @keydown.enter="searchBorrower"
        @click:append-outer="searchBorrower"
        @click:clear="onClearClicked"
      >
      </v-text-field>
      <v-tabs v-model="tab" class="col-12">
        <v-tab
          v-for="item in tabItems"
          :key="item.tab"
          @click="changeTab(item.tab, item.firstClick)"
          >{{ item.tab }}</v-tab
        >
      </v-tabs>

      <v-tabs-items v-model="tab" class="ma-4">
        <v-tab-item v-for="item in tabItems" :key="item.tab">
          <v-card flat>
            <Table
              :tableData="borrowers.results"
              :options.sync="options"
              :serverItemsLength="borrowers.count"
              :itemsPerPage="borrowers.per_page"
              :headers="headers"
              v-on:updated-options="fetchBorrowersWithPage"
              class="ma-auto"
            >
              <template v-slot:actions="slotProps">
                <v-icon
                  v-if="
                    isAdmin && slotProps.item.status == 'awaiting_activation'
                  "
                  small
                  class="mr-1"
                  @click="vetBorrower(slotProps.item)"
                  style="font-size: 20px"
                  color="green"
                >
                  mdi-shield-search
                </v-icon>
                <v-icon
                  small
                  class="mr-1"
                  @click="showBorrowerDetails(slotProps.item)"
                  style="font-size: 20px"
                  color="grey"
                >
                  mdi-eye
                </v-icon>
                <v-icon
                  small
                  class="mr-1"
                  @click="openBorrowerForm(slotProps.item)"
                  style="font-size: 20px"
                  color="green"
                >
                  mdi-account-edit
                </v-icon>
                <v-icon
                  small
                  class="mr-1"
                  @click="openBorrowerDeleteConfirm(slotProps.item)"
                  style="font-size: 20px"
                  color="red"
                >
                  mdi-delete
                </v-icon>
              </template>
            </Table>
          </v-card>
        </v-tab-item>
      </v-tabs-items>

      <BorrowerDetails
        v-bind:borrower="this.borrowerProp"
        v-bind:display="this.showDetailsModal"
        v-on:close-modal="showDetailsModal = false"
      ></BorrowerDetails>
      <DeleteBorrower
        v-bind:borrower="this.borrowerProp"
        v-bind:display="this.showDeleteModal"
        v-on:close-modal="showDeleteModal = false"
      ></DeleteBorrower>
      <VetBorrower
        v-bind:borrower="this.borrowerProp"
        v-bind:display="this.showVetBorrowerModal"
        v-on:close-modal="showVetBorrowerModal = false"
      ></VetBorrower>
    </v-layout>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Table from '../../components/Table/Table.vue';
import PageHeader from '../../components/PageHeader/PageHeader.vue';
import BorrowerDetails from './BorrowerDetails.vue';
import DeleteBorrower from './DeleteBorrower.vue';
import VetBorrower from './VetBorrower.vue';

export default {
  name: 'Borrowers',
  components: {
    Table,
    PageHeader,
    BorrowerDetails,
    DeleteBorrower,
    VetBorrower,
  },
  data() {
    return {
      tab: null,
      filterModel: { status: '' },
      borrowerRef: '',
      borrowerName: '',
      showFormModal: false,
      showDetailsModal: false,
      showDeleteModal: false,
      showVetBorrowerModal: false,
      borrowerProp: null,
      createButtonText: null,
      options: {},
      searchTerm: '',
      headers: [
        {
          value: 'ref',
          text: 'Borrower ref',
          searchable: true,
          sortable: true,
        },
        {
          value: 'name',
          text: 'Borrower Name',
          searchable: true,
          sortable: true,
        },
        {
          value: 'loan_limit',
          text: 'Loan Limit',
          sortable: true,
        },
        {
          value: 'status',
          text: 'Status',
          sortable: true,
        },
        {
          value: 'partner_defined_id',
          text: 'KE Number',
          sortable: true,
        },
        {
          value: 'action',
          text: 'Actions',
        },
      ],
      tabItems: [
        {
          tab: 'active',
          firstClick: true,
        },
        {
          tab: 'awaiting_activation',
          firstClick: true,
        },
        {
          tab: 'inactive',
          firstClick: true,
        },
        {
          tab: 'blacklisted',
          firstClick: true,
        },
      ],
    };
  },
  async mounted() {
    this.createButtonText = 'Add A Borrower';
  },
  computed: {
    ...mapGetters('auth', ['isAdmin']),
    ...mapGetters('borrowers', ['borrowers']),
  },
  methods: {
    ...mapActions('borrowers', ['fetchBorrowers']),

    async changeTab(status, firstClick) {
      if (firstClick) {
        return;
      }
      const page = 1;
      await this.fetchBorrowers({ status, page, searchTerm: this.searchTerm });
    },

    async fetchBorrowersWithPage(options) {
      this.tabItems[this.tab].firstClick = false;
      const status = this.tabItems[this.tab].tab;
      const { page } = options;
      await this.fetchBorrowers({ status, page, searchTerm: this.searchTerm });
    },
    async searchBorrower() {
      this.tabItems[this.tab].firstClick = false;
      const status = this.tabItems[this.tab].tab;
      await this.fetchBorrowers({
        status,
        searchTerm: this.searchTerm,
      });
    },
    async onClearClicked() {
      this.tabItems[this.tab].firstClick = false;
      const status = this.tabItems[this.tab].tab;
      await this.fetchBorrowers({ status, page: 1 });
    },
    openBorrowerForm(borrower = null) {
      this.$router.push({
        path: borrower
          ? `/borrowers/edit/${borrower.ref}`
          : '/borrowers/create',
        params: borrower ? { ref: borrower.ref } : {},
      });
      this.borrowerProp = borrower;
    },
    showBorrowerDetails(borrower) {
      this.$router.push({
        path: `/borrowers/view/${borrower.ref}`,
        params: { ref: borrower.ref },
      });
    },
    vetBorrower(borrower) {
      this.borrowerProp = borrower;
      this.showVetBorrowerModal = true;
    },
    openBorrowerDeleteConfirm(borrower) {
      this.borrowerProp = borrower;
      this.showDeleteModal = true;
    },
  },
};
</script>

<style scoped>
.v-input {
  padding-left: 16px;
}
.v-tabs {
  padding: 0 16px 0;
}
.v-text-field {
  width: 50%;
}
</style>
