<template>
  <div class="width100">
    <PageHeader
      v-bind:headingText="this.pageTitle"
      :breadcrumbs="crumbs"
    ></PageHeader>
    <v-layout justify-center flex-column align-stretch class="ma-4">
      <v-card elevation="2" class="ma-auto col-11">
        <v-simple-table class="pa-4">
          <template v-slot:default>
            <tbody>
              <tr v-for="(value, name) in item" :key="name">
                <td>{{ name }}</td>
                <td v-if="value instanceof Array">
                  <template v-for="item in value">
                    <v-chip class="ma-2" :key="item.code">
                      {{ item.value }}
                    </v-chip>
                  </template>
                </td>
                <td v-else>{{ value }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </v-layout>
  </div>
</template>

<script>
import PageHeader from '../../components/PageHeader/PageHeader.vue';

export default {
  name: 'Details',
  components: {
    PageHeader,
  },
  data() {
    return {
      pageTitle: this.$route.params.dataToDisplay ? 'Product Details' : '',
      item: this.$route.params.dataToDisplay,
      crumbs: this.$route.params.dataToDisplay
        ? [
            {
              text: 'Products',
              disabled: false,
              href: '#/products',
            },
            {
              text: this.$route.params.dataToDisplay.name,
              disabled: true,
              href: '',
            },
          ]
        : [],
    };
  },
  methods: {},
};
</script>
