const businessTypes = [
  {
    id: 'consumer',
    name: 'Consumer',
  },
  {
    id: 'sole proprietorship',
    name: 'Sole Proprietorship',
  },
  {
    id: 'individual',
    name: 'Individual',
  },
  {
    id: 'partnership',
    name: 'Partnership',
  },
  {
    id: 'llc',
    name: 'Limited Liability Company (LLC)',
  },
];

const borrowerFields = [
  {
    type: 'input',
    inputType: 'text',
    label: 'Borrower Name',
    placeholder: 'Borrower Name',
    model: 'name',
    required: true,
    max: 150,
    min: 5,
    styleClasses: 'col-md-6 pt-0 pl-0',
  },
  {
    type: 'select',
    label: 'Distributor',
    model: 'partner',
    required: true,
    values: () => [],
    styleClasses: 'col-md-6 pt-0 pl-0',
  },
  {
    type: 'input',
    inputType: 'text',
    label: 'Nominated Phone Number',
    placeholder: 'Contact Phone',
    model: 'phone',
    required: true,
    styleClasses: 'col-md-6 pt-0 pl-0',
  },
  {
    type: 'select',
    label: 'Status',
    model: 'status',
    required: true,
    default: 'awaiting_activation',
    values: [
      {
        id: 'awaiting_activation',
        name: 'Awaiting Activation',
      },
      {
        id: 'active',
        name: 'Active',
      },
      {
        id: 'inactive',
        name: 'Inactive',
      },
      {
        id: 'expired',
        name: 'Expired',
      },
      {
        id: 'blacklisted',
        name: 'Blacklisted',
      },
    ],
    styleClasses: 'col-md-6 pt-0 pl-0',
  },
  {
    type: 'input',
    inputType: 'text',
    label: 'Owner Name',
    placeholder: 'Owner Name',
    model: 'owner_name',
    required: true,
    max: 150,
    min: 5,
    styleClasses: 'col-md-6 pt-0 pl-0',
  },
  {
    type: 'input',
    inputType: 'text',
    label: 'Owner Phone Number',
    placeholder: 'Phone Number',
    model: 'owner_phone',
    styleClasses: 'col-md-6 pt-0 pl-0',
  },
  {
    type: 'input',
    inputType: 'text',
    label: 'Owner National ID Number',
    placeholder: 'National ID Number',
    model: 'national_id_number',
    max: 150,
    min: 5,
    styleClasses: 'col-md-6 pt-0 pl-0',
  },
  {
    type: 'input',
    inputType: 'text',
    label: 'Onboarding DSR Phone Number',
    model: 'sales_rep_phone',
    max: 150,
    min: 5,
    styleClasses: 'col-md-6 pt-0 pl-0',
  },
];
const refereeFields = [
  {
    type: 'input',
    inputType: 'text',
    label: 'Referee Name',
    model: 'referee_name',
    max: 150,
    min: 5,
    styleClasses: 'col-md-6 pt-0 pl-0',
  },
  {
    type: 'input',
    inputType: 'text',
    label: 'Referee Phone Number',
    model: 'referee_phone',
    max: 150,
    min: 5,
    styleClasses: 'col-md-6 pt-0 pl-0',
  },
  {
    type: 'input',
    inputType: 'text',
    label: 'Relationship',
    model: 'referee_relationship',
    max: 150,
    min: 5,
    styleClasses: 'col-md-6 pt-0 pl-0',
  },
];

const loanFields = [
  {
    type: 'input',
    inputType: 'text',
    label: 'Loan Limit',
    model: 'loan_limit',
    placeholder: "Borrower's loan limit",
    max: 64,
    min: 5,
    styleClasses: 'col-md-6 pt-0 pl-0',
  },
  {
    type: 'select',
    label: 'Loan Limit Lock Status',
    model: 'loan_limit_lock',
    default: 'inactive',
    values: [
      {
        id: 'active',
        name: 'Active',
      },
      {
        id: 'inactive',
        name: 'Inactive',
      },
    ],
    styleClasses: 'col-md-6 pt-0 pl-0',
  },
  {
    type: 'select',
    label: 'Max Loan Duration',
    model: 'has_max_loan_duration',
    default: false,
    values: [
      {
        id: false,
        name: '15 Days',
      },
      {
        id: true,
        name: '30 Days',
      },
    ],
    styleClasses: 'col-md-6 pt-0 pl-0',
  },
];

const primaryBusinessFields = [
  {
    type: 'input',
    inputType: 'text',
    label: 'Trading Name',
    placeholder: 'Trading Name',
    required: true,
    model: 'trading_name',
    max: 64,
    min: 5,
    styleClasses: 'col-md-6 pt-0 pl-0',
  },
  {
    type: 'input',
    inputType: 'text',
    label: 'KE Number',
    placeholder: 'KE Number',
    required: true,
    model: 'ke_number',
    styleClasses: 'col-md-6 pt-0 pl-0',
  },
  {
    type: 'select',
    inputType: 'text',
    label: 'Business Registration Type',
    placeholder: 'Registration Type',
    model: 'registration_type',
    required: true,
    values: businessTypes,
    max: 64,
    min: 5,
    styleClasses: 'col-md-6 pt-0 pl-0',
  },
  {
    type: 'input',
    inputType: 'text',
    label: 'Route Segment',
    placeholder: 'Segment',
    model: 'route_segment',
    required: true,
    max: 64,
    min: 5,
    styleClasses: 'col-md-6 pt-0 pl-0',
  },
  {
    type: 'input',
    inputType: 'text',
    label: 'Location',
    placeholder: 'Location',
    model: 'location',
    max: 64,
    min: 5,
    styleClasses: 'col-md-6 pt-0 pl-0',
  },
  {
    type: 'input',
    inputType: 'text',
    label: 'Distribution Till Number',
    placeholder: 'Till Number',
    model: 'distributor_till_number',
    max: 64,
    min: 5,
    styleClasses: 'col-md-6 pt-0 pl-0',
  },
];

const businessFields = [
  {
    type: 'input',
    inputType: 'text',
    label: 'Business Name',
    placeholder: 'Business Name',
    model: 'name',
    required: true,
    max: 150,
    min: 5,
    styleClasses: 'col-md-6 pt-0 pl-0',
  },
  ...primaryBusinessFields,
];

const getSchema = (fields) => ({ fields });
const loanSchema = getSchema(loanFields);
const borrowerSchema = getSchema(borrowerFields);
const refereeSchema = getSchema(refereeFields);
const primaryBusinessSchema = getSchema(primaryBusinessFields);
const businessSchema = getSchema(businessFields);
const schema = {
  loan: loanSchema,
  borrower: borrowerSchema,
  referee: refereeSchema,
  primaryBusiness: primaryBusinessSchema,
  business: businessSchema,
};

const defaultModel = {
  name: '', // is meant to always be same as primary business name
  phone: '',
  status: 'awaiting_activation',
  partner: {},
  partner_defined_id: '', // is meant to always be same as primary business KE Number
  owner_name: '',
  owner_phone: '',
  national_id_number: '',
  sales_rep_phone: '',

  primary_business: {},

  loan_limit: '',
  loan_limit_lock: 'inactive',
  has_max_loan_duration: false,

  referee_name: '',
  referee_phone: '',
  referee_relationship: '',
};

const businessModel = {
  id: undefined,
  name: '',
  trading_name: '',
  ke_number: '',
  registration_type: 'sole proprietorship',
  route_segment: '',
  location: '',
  distributor_till_number: '',
  owner: undefined, // id of current borrower
};

export { defaultModel, schema, businessModel };
