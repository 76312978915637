<template>
  <div class="width100">
    <PageHeader headingText="Loans"></PageHeader>
    <v-layout justify-center flex-column align-stretch class="ma-4">
      <v-text-field
        v-model="searchTerm"
        placeholder="Enter a search term"
        append-outer-icon="search"
        clearable
        @keydown.enter="searchLoan"
        @click:append-outer="searchLoan"
        @click:clear="onClearClicked"
      >
      </v-text-field>
      <v-tabs v-model="tab" class="col-12">
        <v-tab
          v-for="item in tabItems"
          :key="item.tab"
          @click="changeTab(item.tab, item.firstClick)"
          >{{ item.tab }}</v-tab
        >
      </v-tabs>
      <v-tabs-items v-model="tab" class="ma-4">
        <v-tab-item v-for="item in tabItems" :key="item.tab">
          <v-card flat>
            <Table
              :tableData="loans.results"
              :options.sync="options"
              :serverItemsLength="loans.count"
              :itemsPerPage="loans.per_page"
              :headers="headers"
              v-on:updated-options="fetchFilteredLoansWithPage"
              class="ma-auto"
              v-on:item-details="openLoanPage"
            >
            </Table>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-layout>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Table from '../../components/Table/Table.vue';
import PageHeader from '../../components/PageHeader/PageHeader.vue';
import filterSchema from './filterSchema';

export default {
  name: 'Loans',
  components: {
    Table,
    PageHeader,
  },
  data() {
    return {
      tab: null,
      filteredLoans: [],
      loanSchema: filterSchema,
      filterModel: { status: '' },
      showFilter: false,
      options: {},
      searchTerm: '',
      headers: [
        {
          value: 'ref',
          text: 'Loan Ref',
        },
        {
          value: 'status',
          text: 'Status',
        },
        {
          value: 'borrower.name',
          text: 'Borrower',
        },
        {
          value: 'borrower.partner_defined_id',
          text: 'KE Number',
        },
        {
          value: 'principal',
          text: 'Amount',
        },
        {
          value: 'disbursement_date',
          text: 'Disbursement Date',
        },
        {
          value: 'total_expected_repayment',
          text: 'Repayment Amount',
        },
        {
          value: 'due_date',
          text: 'Due Date',
          sortable: true,
        },
        {
          value: 'disbursement_account',
          text: 'VSM Till Number',
        },
      ],
      tabItems: [
        {
          tab: 'active',
          firstClick: true,
        },
        {
          tab: 'due',
          firstClick: true,
        },
        {
          tab: 'overdue',
          firstClick: true,
        },
        {
          tab: 'repaid',
          firstClick: true,
        },
        {
          tab: 'withdrawn',
          firstClick: true,
        },
        {
          tab: 'frozen',
          firstClick: true,
        },
        {
          tab: 'written off',
          firstClick: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters('loans', ['loans']),
  },
  methods: {
    ...mapActions('loans', ['fetchFilteredLoans']),

    async changeTab(status, firstClick) {
      if (firstClick) {
        return;
      }
      const page = 1;
      await this.fetchFilteredLoans({ status, page });
    },

    async fetchFilteredLoansWithPage(options) {
      this.tabItems[this.tab].firstClick = false;
      const status = this.tabItems[this.tab].tab;
      const { page } = options;
      await this.fetchFilteredLoans({
        status,
        page,
        searchTerm: this.searchTerm,
      });
    },

    async searchLoan() {
      this.tabItems[this.tab].firstClick = false;
      const status = this.tabItems[this.tab].tab;
      await this.fetchFilteredLoans({
        status,
        searchTerm: this.searchTerm,
      });
    },

    async onClearClicked() {
      this.tabItems[this.tab].firstClick = false;
      const status = this.tabItems[this.tab].tab;
      await this.fetchFilteredLoans({
        status,
      });
    },

    openLoanPage(item) {
      this.$router.push({
        path: `/loan/${item.ref}`,
        params: { ref: item.ref },
      });
    },
  },
};
</script>

<style scoped>
.v-input {
  padding-left: 16px;
}
.v-tabs {
  padding: 0 16px 0;
}
.v-text-field {
  width: 50%;
}
</style>
