import Vue from 'vue';

const getMpesaRepayments = (page = 1, searchTerm = '', searchParams = {}) => {
  let url = `/api/v2/mpesarepayments?page=${page}`;
  if (searchTerm) {
    url = `${url}&search=${searchTerm}`;
  }
  Object.keys(searchParams).forEach((key) => {
    if (searchParams[key] || searchParams[key] === false) {
      url += `&${key}=${searchParams[key]}`;
    }
  });
  return Vue.http.get(url);
};

const reconMpesaPayment = (payload) => {
  const { data, ref } = payload;
  return Vue.http({
    url: `/api/v2/mpesarepayments/${ref}/reconcile_payment`,
    method: 'PATCH',
    data,
  });
};

export { getMpesaRepayments, reconMpesaPayment };
