export default {
  fields: [
    {
      type: 'input',
      inputType: 'email',
      label: 'Email',
      model: 'email',
      placeholder: 'sample@mail.com',
      required: true,
      validator: ['email'],
    },
  ],
};
