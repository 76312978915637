const defaultSchema = {
  fields: [
    {
      type: 'checklist',
      label: 'Distributor',
      model: 'partner',
      multiSelect: true,
      values: [],
      styleClasses: 'col-md-3 pt-0 pl-0 mb-0',
    },
    {
      type: 'input',
      inputType: 'text',
      label: 'Business name',
      model: 'business',
      styleClasses: 'col-md-3 pt-0 pl-0 mb-0',
    },
    {
      type: 'checklist',
      label: 'Account Status',
      placeholder: 'Account status',
      model: 'status',
      multi: true,
      multiSelect: true,
      values: [
        'active',
        'inactive',
        'awaiting_activation',
        'blacklisted',
        'expired',
      ],
      styleClasses: 'col-md-3 pt-0 pl-0 mb-0',
    },
    {
      type: 'checklist',
      label: 'Loan Status',
      placeholder: 'Loan status',
      model: 'loanStatus',
      multi: true,
      multiSelect: true,
      values: [
        'active',
        'due',
        'repaid',
        'overdue',
        'pending vetting',
        'pending disbursal',
        'approved',
        'withdrawn',
        'written off',
        'frozen',
        'rejected',
      ],
      styleClasses: 'col-md-3 pt-0 pl-0 mb-0',
    },
    {
      type: 'checklist',
      multiSelect: true,
      label: 'PTP Status',
      placeholder: 'PTP status',
      values: ['Active', 'Honored', 'Broken', 'Stale'],
      model: 'ptpStatus',
      styleClasses: 'col-md-3 pt-0 pl-0 mb-0',
    },
    {
      type: 'input',
      inputType: 'number',
      label: 'Lower Loan Limit',
      model: 'lowerLoanLimit',
      styleClasses: 'col-md-3 pt-0',
    },
    {
      type: 'input',
      inputType: 'number',
      label: 'Upper Loan Limit',
      model: 'upperLoanLimit',
      styleClasses: 'col-md-3 pt-0 pl-0 mb-0',
    },
    {
      type: 'checklist',
      multiSelect: true,
      label: 'Loan Model',
      values: ['Fixed Rate', 'Reducing Balance'],
      model: 'loanModel',
      styleClasses: 'col-md-3 pt-0 pl-0 mb-0',
    },
    {
      type: 'checklist',
      multiSelect: true,
      label: 'Call Feedback',
      values: [
        'Third party picked up',
        'Debtor Switched off during conversation',
        'Debtor not responding to calls/smses',
        'Debtor disputed',
        'Debtor deceased',
        'Promised to pay without a specific date',
        'Promised to pay with a specific date',
        'Phone switched off, did not go through',
        'Confirmed earlier commitment made / Agreed on',
        'Paid',
      ],
      model: 'loanFeedback',
      styleClasses: 'col-md-3 pt-0 pl-0 mb-0',
    },
  ],
};

const defaultModel = {
  partner: [],
  lowerLoanLimit: '',
  upperLoanLimit: '',
  segment: '',
  business: '',
  loanStatus: [],
  loanComment: [],
  status: [],
  ptpStatus: '',
  created_at: '',
  loanModel: '',
  loanFeedback: [],
};

export { defaultModel, defaultSchema };
