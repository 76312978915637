<template>
  <div>
    <v-card-title v-if="loanData">
      {{ title }}
    </v-card-title>

    <LoanOverview v-bind:loanData="loanData"></LoanOverview>

    <template>
      <v-card-text v-if="loanData && loanData.hasOwnProperty('repayments')">
        <!-- COMMENTS AND FEEDBACK TABS -->
        <LoanTabsComponent v-bind:loan="loanData"> </LoanTabsComponent>
      </v-card-text>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LoanTabsComponent from './LoanTabsComponent.vue';
import LoanOverview from './LoanOverview.vue';

export default {
  name: 'Loan',
  props: ['loanData', 'title', 'loanId'],
  components: {
    LoanOverview,
    LoanTabsComponent,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters('auth', ['isAdmin']),
    amountRepaidSoFar() {
      if (this.loanData && this.loanData.repayments) {
        return this.loanData.repayments.reduce((x, y) => x + y.amount, 0);
      }
      return 0;
    },
  },
};
</script>

<style scoped>
.content {
  padding-bottom: 30px;
}
.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: #f1f1f1;
}
</style>
