<template>
  <div class="width100">
    <!-- ACCOUNTS LIST -->
    <Table
      :tableData="smsTemplates.results"
      :options.sync="options"
      :serverItemsLength="smsTemplates.count"
      :itemsPerPage="smsTemplates.per_page"
      :headers="headers"
      v-on:updated-options="fetchActiveTemplatesWithPage"
      class="ma-auto"
    >
      <template v-slot:actions="slotProps">
        <v-icon
          v-if="slotProps.item.status === 'Published'"
          small
          class="mr-1"
          @click="sendSmsUsingTemplate(slotProps.item)"
          style="font-size: 20px"
          color="green"
        >
          mdi-email-send-outline
        </v-icon>
        &nbsp;
        <v-icon
          v-if="slotProps.item.status !== 'Published'"
          small
          class="mr-1"
          @click="viewTemplate(slotProps.item)"
          style="font-size: 20px"
          color="orange"
        >
          mdi-file-find
        </v-icon>
        &nbsp;

        <Action
          :item="slotProps.item"
          :icon="editIcon"
          :action="openTemplateForm"
          color="gray"
        />
        &nbsp;
        <Action
          :item="slotProps.item"
          :icon="deleteIcon"
          :action="openDeleteTemplateForm"
          :color="redIcon"
        />
      </template>
    </Table>
    <SmsTemplateForm
      v-bind:template="this.templateProp"
      v-bind:display="this.showTemplateModal"
      v-on:close-modal="showTemplateModal = false"
    >
    </SmsTemplateForm>
    <DeleteTemplate
      v-bind:template="this.templateProp"
      v-bind:display="this.showDeleteModal"
      v-on:close-modal="showDeleteModal = false"
    >
    </DeleteTemplate>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Table from '../../components/Table/Table.vue';
import Action from '../../components/Action/Action.vue';
import SmsTemplateForm from './SmsTemplateForm.vue';
import DeleteTemplate from './DeleteSmsTemplate.vue';

export default {
  name: 'Templates',
  components: {
    SmsTemplateForm,
    DeleteTemplate,
    Table,
    Action,
  },
  data() {
    return {
      deleteIcon: 'mdi-delete',
      editIcon: 'mdi-lead-pencil',
      detailsIcon: 'mdi-eye',
      greenIcon: 'green',
      redIcon: 'red',
      greyIcon: 'grey',
      showAdvancedSearch: false,
      showTemplateModal: false,
      showDeleteModal: false,
      templateProp: null,
      options: {},
      searchTerm: '',
      headers: [
        {
          value: 'name',
          text: 'Template Name',
          width: '15%',
          sortable: true,
          searchable: true,
        },
        {
          value: 'category',
          text: 'SMS Category',
          sortable: true,
          searchable: true,
        },
        {
          value: 'content',
          text: 'Template',
          width: '45%',
          sortable: true,
          searchable: true,
        },
        {
          value: 'status',
          text: 'Status',
          width: '15%',
          align: 'center',
          sortable: true,
          searchable: true,
        },
        {
          value: 'action',
          text: 'Actions',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('auth', ['isAdmin']),
    ...mapGetters('smstemplates', ['smsTemplates']),
  },
  methods: {
    ...mapActions('smstemplates', ['fetchSmsTemplates']),

    toggleAdvancedSearch() {
      this.showAdvancedSearch = !this.showAdvancedSearch;
    },

    async fetchActiveTemplatesWithPage(options) {
      const status = 'active';
      const { page } = options;
      await this.fetchSmsTemplates({
        status,
        page,
        searchTerm: this.searchTerm,
      });
    },

    async searchTemplate(searchParams) {
      const status = searchParams.status || '';
      await this.fetchSmsTemplates({
        status,
        searchTerm: this.searchTerm,
        searchParams,
      });
    },

    async onClearClicked() {
      await this.fetchSmsTemplates();
    },

    sendSmsUsingTemplate(item) {
      const dataToDisplay = item;
      this.$router.push({
        path: `/smstemplates/send/${item.id}`,
        params: { dataToDisplay },
      });
    },

    viewTemplate(item) {
      this.$router.push({
        path: `/smstemplates/view/${item.id}`,
        params: { templateId: item.id },
      });
    },

    openTemplateForm(template = null) {
      this.templateProp = template;
      this.$router.push({
        path: template
          ? `/smstemplates/edit/${template.id}`
          : '/smstemplates/create',
        params: template ? { templateId: template.id } : {},
      });
    },

    openDeleteTemplateForm(template) {
      this.templateProp = template;
      this.showDeleteModal = true;
    },
  },
};
</script>

<style scoped lang="scss">
.v-input {
  padding-right: 40%;
  padding-left: 16px;
  margin: 0;
}
.v-application p {
  padding-left: 50px;
}
.no-uppercase {
  text-transform: unset !important;
}
.left {
  padding: 1em;
}
.v-btn {
  color: #699a4c;
}
.right {
  padding: 1em;
}
.parent {
  padding: 0 12px;
  background-color: #fff;
  border-radius: 5px;
}
.active {
  background-color: #699a4c25;
}
</style>
