<template>
  <div>
    <form @submit.prevent="handleClick" id="vettingForm">
      <ErrorAlert v-if="formErrors.length > 0" :errors="formErrors" sticky />
      <div v-if="loanLimit" class="ml-2 mb-4">
        <b>
          <i> Loan Limit: {{ loanLimit.toLocaleString() }} </i>
        </b>
      </div>
      <vue-form-generator
        :schema="formSchema"
        :model="formModel"
      ></vue-form-generator>
      <Button
        text="Submit"
        type="submit"
        styling="primary"
        validateBeforeSubmit
      ></Button>
    </form>
  </div>
</template>

<script>
import {
  genericVettingSchema,
  genericVettingModel,
  withdrawalSchema,
} from '../../../formSchemas/applications/genericApplicationVetting';
import ErrorAlert from '../../../components/Alert/ErrorAlert.vue';
import Button from '../../../components/Button/Button.vue';

export default {
  name: 'GenericVettingForm',
  emits: ['submit-vetting', 'save-loanterms'],
  props: ['application', 'formErrors'],
  components: { ErrorAlert, Button },
  data() {
    return {
      loanLimit: null,
      formModel: null,
      formSchema: null,
      fileUrl: [],
      componentKey: 0,
      isLoading: false,
    };
  },
  mounted() {
    this.configureForms(this.application);
  },
  watch: {
    application(newLoanApplication) {
      this.forceRerender();
      if (newLoanApplication === false) return;
      this.configureForms(newLoanApplication);
    },
  },
  methods: {
    onFileChanged(newFile) {
      this.fileUrl = newFile;
    },
    forceRerender() {
      this.componentKey += 1;
    },
    handleClick() {
      let action;
      if (this.application.status === 'approved') {
        action = 'withdraw';
      } else {
        action =
          this.formModel.isLoanApproved === 'approved' ? 'approve' : 'reject';
      }
      this.$confirm({
        message: `Are you sure you want to ${action} this loan?`,
        button: {
          no: 'Cancel',
          yes: 'Yes',
        },
        callback: (confirm) => {
          if (confirm) {
            this.submitVetting();
          }
        },
      });
    },
    submitVetting() {
      const { isLoanApproved, rejectDesc } = this.formModel;
      const isWithdrawal = this.application.status === 'approved';
      const payload = {
        status: isWithdrawal ? 'rejected' : isLoanApproved,
        comment: rejectDesc,
        fileUrl: this.fileUrl,
      };
      this.$emit('save-loanterms', this.application.status);
      this.$emit('submit-vetting', payload, 'vetApplication');
    },
    configureForms(application) {
      this.formModel = genericVettingModel;
      this.formSchema =
        application.status === 'approved'
          ? withdrawalSchema
          : genericVettingSchema;
    },
  },
};
</script>
