<template>
  <Modal
    :title="title"
    :openModal="display"
    v-on:close-modal="$emit('close-modal')"
    class="col-6"
  >
    <template v-slot:content>
      <div v-if="account">
        <ErrorAlert v-if="errors.length > 0" :errors="errors" sticky />
        <div class="card">
          <v-textarea
            placeholder="Enter your comment"
            label="Admin Comment"
            v-model="comment"
            rows="3"
            clearable
            auto-grow
            clear-icon="mdi-close-circle"
          ></v-textarea>
        </div>
        <div class="card-footer">
          <Button
            type="submit"
            v-on:button-clicked="handleClick"
            styling="primary"
            text="Approve"
            :disabled="isDisabled"
          ></Button>
          <Button
            type="submit"
            v-on:button-clicked="rejectLead"
            styling="secondary"
            text="Reject"
          ></Button>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from '../../components/Modal/Modal.vue';
import Button from '../../components/Button/Button.vue';
import ErrorAlert from '../../components/Alert/ErrorAlert.vue';

export default {
  name: 'VetAccount',
  emits: ['close-modal'],
  props: ['account', 'display'],
  components: { Modal, Button, ErrorAlert },
  data() {
    return {
      comment: '',
      isLoading: false,
      errors: [],
    };
  },
  computed: {
    isDisabled() {
      return this.isLoading;
    },
    title() {
      return `Vetting ${this.account.name}`;
    },
  },
  methods: {
    handleClick() {
      this.$confirm({
        message: `Are you sure you want to approve this account?`,
        button: {
          no: 'Cancel',
          yes: 'Yes',
        },
        callback: (confirm) => {
          if (confirm) {
            this.approveLead();
          }
        },
      });
    },
    approveLead() {
      const { id } = this.account;
      const { comment } = this;
      this.isLoading = true;
      this.$store
        .dispatch(`leads/approveLead`, {
          id,
          comment,
        })
        .then(() => {
          this.isLoading = false;
          this.$emit('close-modal');
        })
        .catch((err) => {
          this.isLoading = false;
          this.errors = err;
        });
    },
    rejectLead() {
      const { id } = this.account;
      const { comment } = this;
      this.$store
        .dispatch(`leads/rejectLead`, {
          id,
          comment,
        })
        .then(() => {
          this.$emit('close-modal');
        })
        .catch((err) => {
          this.errors = err;
        });
    },
  },
};
</script>

<style scoped>
.capitalise {
  text-transform: capitalize;
}
</style>
