const postWaiverSchema = {
  fields: [
    {
      type: 'input',
      inputType: 'text',
      label: 'Reason',
      model: 'reason',
      required: true,
      max: 150,
      min: 5,
      validator: ['required'],
    },
    {
      type: 'input',
      inputType: 'text',
      label: 'Amount',
      model: 'amount',
      required: true,
      max: 150,
      min: 5,
      validator: ['required'],
    },
  ],
};

const postWaiverModel = {
  reason: '',
  amount: '',
};

export { postWaiverModel, postWaiverSchema };
