<template>
  <div class="width100">
    <v-layout class="width100 d-flex flex-column">
      <v-alert dense text type="warning">
        You are about to send this message to the
        {{ smsSelectionObject.selectedBorrowersCount }} borrowers listed below.
      </v-alert>
      <v-divider :light="true"></v-divider>
      <TemplateDetailCard :smsTemplate="currentTemplate" />

      <div class="pl-4">
        <div class="pa-2">Receipients</div>
        <v-divider :light="true"></v-divider>

        <Table
          :tableData="
            smsSelectionObject.selectedAll ? borrowers.results : selected
          "
          :allowSelection="false"
          :serverItemsLength="smsSelectionObject.selectedBorrowersCount"
          :itemsPerPage="
            smsSelectionObject.selectedAll
              ? borrowers.per_page
              : selected.length
          "
          :headers="headers"
          v-on:updated-options="fetchActiveBorrowersWithPage"
          class="elevation-0"
        >
        </Table>
      </div>
      <div class="d-flex justify-start ma-5 pa-5 pl-0">
        <v-btn color="primary" class="mr-4" @click="handleClick">
          Send Now
        </v-btn>
        <v-btn text @click="showTimeModal = true"> Schedule For Later </v-btn>
      </div>
    </v-layout>
    <TimeSchedulerModal
      v-bind:display="showTimeModal"
      v-bind:selectedCount="selected.length"
      v-on:close-modal="showTimeModal = false"
      v-on:schedule="handleClick"
    ></TimeSchedulerModal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import TimeSchedulerModal from './TimeSchedulerModal.vue';
import TemplateDetailCard from '../TemplateDetailCard.vue';
import Table from '../../../components/Table/Table.vue';

export default {
  name: 'ConfirmationStep',
  components: {
    Table,
    TemplateDetailCard,
    TimeSchedulerModal,
  },
  props: {
    selected: {
      type: Array,
    },
    smsSelectionObject: {
      type: Object,
    },
  },
  data() {
    return {
      headers: [
        {
          value: 'ref',
          text: 'Account Ref',
        },
        {
          value: 'name',
          text: 'Account name',
        },
        {
          value: 'phone',
          text: 'Phone Number',
        },
        {
          value: 'partner_defined_id',
          text: 'KE Number',
        },
        {
          value: 'partner',
          text: 'Distributor',
        },
      ],
      showTimeModal: false,
      templateProp: null,
      actionProp: null,
    };
  },
  methods: {
    ...mapActions('borrowers', ['fetchBorrowers']),
    async sendBulkSms({ date, time } = null) {
      if (!date || !time) {
        const data = {
          templateId: this.currentTemplate.id,
          receipientIds:
            (!this.smsSelectionObject.selectedAll &&
              this.selected.map((item) => item.id)) ||
            [],
          sendSmsFilterFields:
            typeof this.$store.getters['sms/sendSmsFilterFields'] ===
            'undefined'
              ? this.$store.dispatch(`sms/setSendSmsFilterFields`, {
                  data: 'none',
                })
              : this.$store.getters['sms/sendSmsFilterFields'],
          smsSelectionObject: this.smsSelectionObject,
        };
        this.$store.dispatch(`sms/sendBulkSms`, { data });
        this.$store.dispatch(`sms/setSendSmsFilterFields`, {});
      } else {
        const scheduledDateTime = new Date(`${date} ${time}`).toISOString();
        const data = {
          sms_template: this.currentTemplate.url,
          scheduled_at: scheduledDateTime,
          // post receipient as a dictionary. It is stored in a JSONField
          receipients: this.selected.reduce((prev, borrower) => {
            const borroweObject = {
              id: borrower.id,
              ref: borrower.ref,
              name: borrower.name,
              phone: borrower.phone,
              status: borrower.status,
              partner: borrower.partner,
              partner_defined_id: borrower.partner_defined_id,
              message_status: 'PENDING',
            };
            // eslint-disable-next-line no-param-reassign
            prev[borrower.id] = borroweObject;
            return prev;
          }, {}),
        };
        await this.$store.dispatch(`sms/scheduleBulkSms`, { data });
      }
      this.$router.push('/smstemplates');
    },

    handleClick({ date, time } = null) {
      let message = `Are you sure you wish to send this sms to ${this.smsSelectionObject.selectedBorrowersCount} borrowers`;
      if (date && time) {
        message += ` on ${date} at ${time}`;
      }
      this.$confirm({
        message: `${message}?`,
        button: {
          no: 'Cancel',
          yes: 'Yes',
        },
        callback: (confirm) => {
          if (confirm) {
            this.sendBulkSms({ date, time });
          }
          this.showTimeModal = false;
        },
      });
    },
    async fetchActiveBorrowersWithPage(options) {
      const status = this.smsSelectionObject.searchParams?.status || '';
      const { page } = options;
      await this.fetchBorrowers({
        status,
        page,
        searchTerm: '',
        searchParams: this.smsSelectionObject.searchParams,
      });
    },
    async searchBorrower(searchParams) {
      this.selected = [];
      this.searchParams = searchParams || {};
      const status = searchParams?.status || '';
      await this.fetchBorrowers({
        status,
        searchTerm: '',
        searchParams,
      });
    },
  },
  computed: {
    ...mapGetters('auth', { isSuperUser: 'isSuperUser' }),
    ...mapGetters('smstemplates', ['currentTemplate']),
    ...mapGetters('sms', ['sendSmsFilterFields']),
    ...mapGetters('borrowers', ['borrowers']),
  },
  beforeMount() {
    if (this.smsSelectionObject.selectedAll) {
      this.searchBorrower(this.smsSelectionObject.searchParams);
    }
  },
};
</script>

<style scoped>
.content {
  margin-right: 30px;
  padding-bottom: 30px;
}
.light {
  color: #8c98a4;
}
.dark {
  font-weight: 500;
}
.details {
  font-size: 15px;
  padding-right: 50px;
}
</style>
