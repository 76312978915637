<template>
  <div class="col-12">
    <v-data-table
      v-model="selected"
      :page="page"
      :pageCount="numberOfPages"
      :headers="headers"
      :items="tableData"
      :show-select="allowSelection"
      :search="search"
      :custom-filter="filterTableData"
      :options.sync="options"
      :selected.sync="selected"
      :server-items-length="serverItemsLength"
      :footer-props="{ itemsPerPageOptions: [itemsPerPage] }"
      class="{`$elevation rounded-lg`}"
      @click:row="handleClick"
      :loading="isDataLoading"
      loading-text="Loading... Please wait"
    >
      <template v-slot:[`item.status`]="{ item }">
        <v-chip :color="getChipColor(item.status)" dark class="rounded-lg">
          {{ item.status }}
        </v-chip>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <slot name="actions" :item="item"><!-- CONTENT GOES HERE--></slot>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  emits: ['updated-options'],
  props: {
    allowSelection: {
      type: Boolean,
      default: false,
    },
    elevation: {
      type: String,
      default: 'elevation-1',
    },
    tableData: {
      type: Array,
      validator: (value) => Array.isArray(value),
    },
    headers: {
      type: Array,
      default: () => [],
      validator: (value) => Array.isArray(value),
    },
    filterDisplayed: { type: Boolean, default: () => null },
    serverItemsLength: { type: Number, default: () => 0 },
    itemsPerPage: { type: Number, default: () => 30 },
    numberOfPages: { type: Number, default: () => 0 },
  },
  data() {
    return {
      selected: [],
      options: {},
      search: '',
      filterShowing: false,
      page: 1,
      totalRecords: 0,
    };
  },
  computed: {
    ...mapGetters('common', { isDataLoading: 'isDataLoading' }),
  },
  methods: {
    toggleFilter() {
      this.$emit('toggle-filter');
    },
    getChipColor(status) {
      // TODO: Sort chip colors properly for each dataset that will be sent
      // TODO: Add more colors
      if (['rejected', 'inactive', 'blacklisted'].includes(status))
        return 'red';
      if (['withdrawn', 'Admin Review', 'Draft'].includes(status))
        return 'orange';
      if (['expired'].includes(status)) return '#7E7E7E';
      return 'green';
    },
    filterTableData(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === 'string' &&
        value
          .toString()
          .toLocaleUpperCase()
          .indexOf(search.toLocaleUpperCase()) !== -1
      );
    },
    handleClick(data) {
      this.$emit('item-details', data);
    },
  },
  watch: {
    options: {
      handler() {
        this.$emit('updated-options', this.options);
      },
    },
    selected: {
      handler() {
        this.$store.dispatch(
          `borrowers/populateSendSmsBorrowersList`,
          this.selected,
        );
        this.$emit('selected-options', this.selected);
      },
    },
    deep: true,
  },
};
</script>
<style lang="scss">
.filter-container {
  padding: 0.5rem 1rem;
  max-height: 70px;
}
.v-chip.v-size--default {
  height: 22px;
}
</style>
