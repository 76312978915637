<template>
  <div class="width100">
    <PageHeader
      headingText="Borrower Profile"
      :breadcrumbs="items"
    ></PageHeader>
    <v-layout justify-center flex-column align-stretch class="ma-4">
      <v-card id="mpesadetailspage" elevation="2" class="ma-auto col-12 pl-7">
        <v-card-title id="cardTitle" class="pt-0 pb-0">
          <div class="d-flex flex-column">
            <v-card-subtitle class="pb-0 pl-0"> </v-card-subtitle>
            <p class="capitalise">
              {{ item.channel }} payment details - mpesa code:
              {{ item.merchant_request_id.toUpperCase() }}
            </p>
          </div>
          <v-spacer></v-spacer>
          <v-row align="center" justify="end">
            <v-btn
              v-if="item.isSuccessFull !== true && item.channel === 'paybill'"
              color="primary"
              dark
              @click="openReconForm"
            >
              Complete
            </v-btn>
          </v-row>
        </v-card-title>
        <v-list>
          <v-list-item>
            <v-simple-table class="pr-10">
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td>Amount (Kshs)</td>
                    <td>{{ item.amount }}</td>
                  </tr>
                  <tr>
                    <td>Phone Number</td>
                    <td>{{ item.phone_number }}</td>
                  </tr>
                  <tr>
                    <td>Merchant Request ID</td>
                    <td>{{ item.merchant_request_id }}</td>
                  </tr>
                  <tr>
                    <td>Paid On</td>
                    <td>{{ item.timestamp }}</td>
                  </tr>
                  <tr>
                    <td>Transaction Description</td>
                    <td>{{ item.narrative }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-simple-table class="pr-10">
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td>Account</td>
                    <td>{{ item.loan_account_number }}</td>
                  </tr>
                  <tr>
                    <td>Loan Ref</td>
                    <td>{{ item.loan_ref }}</td>
                  </tr>
                  <tr>
                    <td>Loan Reconciled</td>
                    <td
                      :style="[
                        item.isSuccessFull
                          ? {
                              color: '#699a4c',
                              'font-weight': '600 !important',
                            }
                          : {
                              color: '#da371e',
                              'font-weight': '600 !important',
                            },
                      ]"
                    >
                      {{ item.isSuccessFull }}
                    </td>
                  </tr>
                  <tr>
                    <td>Compeleted On</td>
                    <td>{{ item.completed_date }}</td>
                  </tr>
                  <tr>
                    <td>Channel</td>
                    <td>{{ item.channel }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-list-item>
        </v-list>
      </v-card>
      <PaymentReconForm
        v-bind:paymentItem="this.paymentProp"
        v-bind:display="this.showReconDialog"
        v-on:close-modal="showReconDialog = false"
      ></PaymentReconForm>
    </v-layout>
  </div>
</template>

<script>
import PaymentReconForm from './PaymentReconForm.vue';
import PageHeader from '../../components/PageHeader/PageHeader.vue';

export default {
  name: 'MpesaPaymentDetails',
  components: {
    PaymentReconForm,
    PageHeader,
  },
  data() {
    return {
      item: this.$route.params.dataToDisplay,
      items: [
        { text: 'Mpesa Payments', disabled: false, href: '#/mpesarepayments' },
        {
          text: this.$route.params.dataToDisplay.merchant_request_id,
          disabled: true,
          href: '',
        },
      ],
      showReconDialog: false,
      paymentProp: null,
    };
  },
  methods: {
    openReconForm() {
      this.paymentProp = this.item;
      this.showReconDialog = true;
    },
  },
};
</script>
