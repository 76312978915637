<template>
  <v-layout class="width100">
    <v-card
      v-if="currentTemplate"
      id="templatedetailspage"
      class="pa-4 elevation-0 width100"
    >
      <v-card-title class="pt-0 pb-0" v-if="isReview">
        <div class="d-flex flex-column">
          <p class="capitalise">{{ title || currentTemplate.name }} Template</p>
        </div>
        <v-spacer></v-spacer>
        <v-row v-if="this.isSuperUser" align="center" justify="end">
          <v-col class="shrink d-flex">
            <v-btn color="primary" dark text @click="openTemplateForm">
              Edit
            </v-btn>
            <v-btn
              color="primary"
              dark
              text
              v-if="
                currentTemplate.status === 'Draft' ||
                currentTemplate.status === 'Admin Review'
              "
              @click="openReviewForm"
            >
              Ask for Feedback
            </v-btn>
            <v-btn
              color="primary"
              dark
              text
              v-if="currentTemplate.status === 'Admin Review'"
              @click="openPublishForm"
            >
              Publish
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <div class="content ml-4 details">
        <v-row>
          <v-col lg="12" md="12" sm="12" class="details">
            <v-row v-if="!isReview">
              <v-col class="light col-2"> Template Name </v-col>
              <v-col class="dark col-7">
                {{ currentTemplate.name }}
              </v-col>
            </v-row>
            <v-row>
              <v-col class="light col-2"> SMS Category </v-col>
              <v-col class="dark col-7">
                {{ currentTemplate.category }}
              </v-col>
            </v-row>
            <v-row v-if="isReview">
              <v-col class="light col-2"> Status </v-col>
              <v-col class="col-7">
                <v-chip
                  :color="
                    currentTemplate.status === 'Published' ? 'green' : 'orange'
                  "
                  text-color="white"
                  >{{ currentTemplate.status }}</v-chip
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col class="light col-2"> Content </v-col>
              <v-card
                color="grey lighten-5 elevation-0"
                class="pa-5 dark col-7"
              >
                {{ currentTemplate.content }}
              </v-card>
            </v-row>
            <div class="mt-10 light" v-if="isReview">
              <small v-if="currentTemplate.created_at"
                >Created by {{ currentTemplate.created_by }} on
                {{ currentTemplate.created_at.slice(0, 10) }}</small
              >
              <br />
              <small v-if="currentTemplate.updated_at"
                >Updated by {{ currentTemplate.updated_by }} on
                {{ currentTemplate.updated_at.slice(0, 10) }}</small
              >
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <TemplateReviewForm
      v-bind:templateItem="this.templateProp"
      v-bind:display="this.showReviewDialog"
      v-on:close-modal="showReviewDialog = false"
      v-bind:action="this.actionProp"
    ></TemplateReviewForm>
  </v-layout>
</template>

<script>
import { mapGetters } from 'vuex';
import TemplateReviewForm from './TemplateReviewForm.vue';

export default {
  name: 'TemplateDetailCard',
  components: {
    TemplateReviewForm,
  },
  props: {
    title: { type: String, required: false },
    isReview: { type: Boolean, required: false },
  },
  data() {
    return {
      showReviewDialog: false,
      templateProp: null,
      actionProp: null,
      expand: false,
    };
  },
  methods: {
    openReviewForm() {
      this.expand = !this.expand;
      this.templateProp = this.currentTemplate;
      this.actionProp = 'Review';
      this.showReviewDialog = true;
    },
    openPublishForm() {
      this.expand = !this.expand;
      this.templateProp = this.currentTemplate;
      this.actionProp = 'Publish';
      this.showReviewDialog = true;
    },
    openTemplateForm() {
      this.$router.push({
        path: `/smstemplates/edit/${this.currentTemplate.id}`,
        params: { templateId: this.currentTemplate.id },
      });
    },
  },
  computed: {
    ...mapGetters('auth', { isSuperUser: 'isSuperUser' }),
    ...mapGetters('smstemplates', ['currentTemplate']),
  },
};
</script>

<style scoped>
.content {
  margin-right: 30px;
  padding-bottom: 30px;
}
.light {
  color: #8c98a4;
}
.dark {
  font-weight: 500;
}
.details {
  font-size: 15px;
  padding-right: 50px;
}
</style>
