import { getKycs, createOrEditKyc, deleteKyc } from '../api/kyc.api';

const state = () => ({
  kycs: {
    count: 0,
    links: {
      next: null,
      previous: null,
    },
    pages: 1,
    per_page: 30,
    results: [],
  },
});

const getters = {
  kycs: (state) => state.kycs,
};

const actions = {
  async fetchKycs({ commit }) {
    commit('initial_state');
    try {
      commit('common/data_requested', null, { root: true });
      const response = await getKycs();
      commit('set_kycs', response.data);
    } catch (error) {
      commit(
        'common/error_snackbar',
        'Failed to fetch Kycs. Please try again later.',
        {
          root: true,
        },
      );
    } finally {
      commit('common/response_received', null, { root: true });
    }
  },
  async createOrEditKyc({ commit }, { data, method, kycCode }) {
    try {
      const resp = await createOrEditKyc(data, method, kycCode);
      commit(method === 'POST' ? 'add_kycs' : 'updated_kycs', {
        data: resp.data,
        itemRef: kycCode,
      });
      commit(
        'common/success_snackbar',
        `Kyc ${method === 'POST' ? 'created' : 'edited'} successfully`,
        {
          root: true,
        },
      );
    } catch (errors) {
      const error = errors.response.data;
      throw Array.isArray(error) ? error : [error];
    }
  },

  async deleteKyc({ commit }, { kycCode }) {
    try {
      await deleteKyc(kycCode);
      commit('deleted_kycs', kycCode);
      commit('common/success_snackbar', 'Kyc deleted successfully', {
        root: true,
      });
    } catch (errors) {
      const error = errors.response.data;
      throw Array.isArray(error) ? error : [error];
    }
  },
};

const mutations = {
  initial_state(state) {
    state.kycs = {
      count: 0,
      links: {
        next: null,
        previous: null,
      },
      pages: 1,
      per_page: 30,
      results: [],
    };
  },
  set_kycs(state, data) {
    state.kycs = data;
  },
  updated_kycs(state, { ...data }) {
    state.kycs.results = state.kycs.results.map((value) => {
      let kycItem = value;
      if (kycItem.code === data.itemRef) {
        kycItem = { ...kycItem, ...data.data };
      }
      return kycItem;
    });
  },
  add_kycs(state, { ...data }) {
    state.kycs.results.unshift(data.data);
  },
  deleted_kycs(state, kycCode) {
    const { kycs } = state;
    const item = kycs.results.find((kyc) => kyc.code === kycCode);
    kycs.results.splice(kycs.results.indexOf(item), 1);
    state.kycs = kycs;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
