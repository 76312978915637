<template>
  <Modal
    :title="title"
    :openModal="display"
    v-on:close-modal="$emit('close-modal')"
    class="col-6"
  >
    <template v-slot:content>
      <div v-if="account">
        <ErrorAlert v-if="errors.length > 0" :errors="errors" sticky />
        <div class="card-footer">
          <Button
            type="submit"
            v-on:button-clicked="handleClick"
            styling="primary"
            text="Send"
            :disabled="isDisabled"
          ></Button>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from '../../components/Modal/Modal.vue';
import Button from '../../components/Button/Button.vue';
import ErrorAlert from '../../components/Alert/ErrorAlert.vue';

export default {
  name: 'SendOnboardingLink',
  emits: ['close-modal'],
  props: ['account', 'display'],
  components: { Modal, Button, ErrorAlert },
  data() {
    return {
      isLoading: false,
      errors: [],
    };
  },
  computed: {
    isDisabled() {
      return this.isLoading;
    },
    title() {
      return `Send onboarding link to ${this.account.name} phone: ${this.account.phone}`;
    },
  },
  methods: {
    handleClick() {
      this.$confirm({
        message: `Send onboarding link to this account phone number?`,
        button: {
          no: 'Cancel',
          yes: 'Yes',
        },
        callback: (confirm) => {
          if (confirm) {
            this.onboardLead();
          }
        },
      });
    },
    onboardLead() {
      const { id } = this.account;
      this.isLoading = true;
      this.$store
        .dispatch(`leads/onboardLead`, {
          id,
        })
        .then(() => {
          this.isLoading = false;
          this.$emit('close-modal');
        })
        .catch((err) => {
          this.isLoading = false;
          this.errors = err;
        });
    },
  },
};
</script>

<style scoped>
.capitalise {
  text-transform: capitalize;
}
</style>
