const addDSRSchema = {
  fields: [
    {
      type: 'input',
      inputType: 'text',
      label: 'First Name',
      placeholder: 'Enter first name',
      model: 'first_name',
      required: true,
    },
    {
      type: 'input',
      inputType: 'text',
      label: 'Last Name',
      placeholder: 'Enter last name',
      model: 'last_name',
    },
    {
      type: 'input',
      inputType: 'text',
      label: 'Phone Number',
      placeholder: 'Enter phone number',
      model: 'username',
      required: true,
    },
    {
      type: 'select',
      label: 'Select User Group',
      model: 'groups',
      required: true,
      values: () => {
        return {};
      },
    },
    {
      type: 'input',
      inputType: 'text',
      label: 'Password',
      placeholder: 'Enter password',
      model: 'password',
      required: true,
    },
  ],
};

const addDSRModel = {
  first_name: '',
  last_name: '',
  username: '',
  groups: [],
  password: '',
};

export { addDSRSchema, addDSRModel };
