<template>
  <v-row justify="center">
    <v-dialog
      v-model="showModal"
      scrollable
      max-width="900"
      :width="modalWidth || 'unset'"
      :height="modalHeight || 'unset'"
    >
      <v-card class="rounded-xl">
        <v-card-title>
          <span class="headline">{{ title }}</span>
          <v-spacer></v-spacer>
          <v-btn text @click="closeModal()">
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="mt-4">
          <v-container>
            <slot name="content"><!-- CONTENT GOES HERE--> </slot>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <slot name="footer"><!-- Footer Content--> </slot>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
// TODO: Figure out a way to make the width of the modal not take up too much of the screen
export default {
  name: 'Modal',
  emits: ['close-modal'],
  data() {
    return {
      showModal: this.openModal,
      modalWidth: this.width,
      modalHeight: this.height,
    };
  },
  methods: {
    closeModal() {
      this.$emit('close-modal');
    },
  },
  props: {
    title: { type: String, required: true },
    openModal: { type: Boolean, required: true },
    width: { type: Number, required: false },
    height: { type: Number, required: false },
  },
  watch: {
    openModal(newValue) {
      this.showModal = newValue;
    },
    showModal(newValue) {
      if (newValue === false) this.closeModal();
    },
  },
};
</script>

<style lang="scss">
.v-card__actions {
  padding: 0 24px 20px;
  .v-btn.v-btn {
    padding: 0 16px;
  }
}
.v-card__title {
  border-bottom: 1px solid;
  border-color: $gray;
}
.v-card__text {
  color: unset !important;
}

.v-dialog {
  border-radius: 24px;
}
</style>
