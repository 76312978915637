import Vue from 'vue';

const getBorrowers = (
  status = '',
  page = 1,
  searchTerm = '',
  searchParams = {},
) => {
  let url = `/api/v2/borrowers?page=${page}`;
  if (searchTerm) {
    url = `${url}&searchTerm=${searchTerm}`;
  }
  Object.keys(searchParams).forEach((key) => {
    if (searchParams[key]) {
      url += `&${key}=${searchParams[key]}`;
    }
  });
  // since we prioritize status from advance-filter.
  if (!searchParams.status) {
    url += `&status=${status}`;
  }
  return Vue.http.get(url);
};

const getBorrower = (ref) => {
  return Vue.http.get(`/api/v2/borrowers/${ref}`);
};

const getBusinessFiles = (ref) => {
  const url = `/api/v2/borrowers/${ref}/get_business_files`;
  return Vue.http.get(url);
};
const getAccountBusinesses = (ref) => {
  const url = `/api/v2/borrowers/${ref}/get_account_businesses`;
  return Vue.http.get(url);
};

const createOrEditBorrower = (data, method, ref = null) => {
  const url = `/api/v2/borrowers${method === 'PATCH' ? `/${ref}` : ''}`;
  return Vue.http({
    url,
    method,
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const createOrEditAccountBusiness = (data, method, ref = null) => {
  const url = `/api/v2/businesses${method === 'PATCH' ? `/${ref}` : ''}`;
  return Vue.http({
    url,
    method,
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const deleteBorrower = (ref) => Vue.http.delete(`/api/v2/borrowers/${ref}`);
const activateBorrower = (ref, comment) => {
  const url = `/api/v2/borrowers/${ref}/activate?can_send=true`;
  const method = 'PATCH';
  return Vue.http({
    url,
    method,
    data: { comment },
  });
};

const deactivateBorrower = (ref, comment) => {
  const url = `/api/v2/borrowers/${ref}/deactivate`;
  const method = 'PATCH';
  return Vue.http({
    url,
    method,
    data: { comment },
  });
};

const getBorrowerLoan = (borrowerRef) => {
  const url = `/api/v2/borrowers/${borrowerRef}/retrieve_borrower_loan`;
  return Vue.http.get(url);
};

const getBorrowerLoans = (ref) => {
  const url = `/api/v2/borrowers/${ref}/get_borrower_loans`;
  return Vue.http.get(url);
};

const createOrEditBorrowerComment = (data, method, id = null) => {
  const url = `/api/v2/borrowercomments${method === 'PATCH' ? `/${id}` : ''}`;
  return Vue.http({
    url,
    method,
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const getLoanFeedback = (loanId) => {
  const url = `/api/v2/loancomments?type=Feedback&resource=${loanId}`;
  return Vue.http.get(url);
};
const createKycFile = (payload) => {
  const data = payload;
  const resp = Vue.http({
    url: `api/v2/businesses_files`,
    data,
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return resp;
};
const editKycFile = async (data, id = null) => {
  const resp = await Vue.http({
    url: `/api/v2/businesses_files${id ? `/${id}` : ''}`,
    data,
    method: 'PATCH',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return resp;
};

export {
  getBorrowers,
  getBorrower,
  createOrEditBorrower,
  deleteBorrower,
  activateBorrower,
  deactivateBorrower,
  getBorrowerLoan,
  getBorrowerLoans,
  createOrEditBorrowerComment,
  getLoanFeedback,
  getBusinessFiles,
  getAccountBusinesses,
  createOrEditAccountBusiness,
  createKycFile,
  editKycFile,
};
