<template>
  <div className="width100">
    <PageHeader
      headingText="DSRs"
      btnText="Add DSR"
      v-on:action-button-clicked="openDSRForm()"
    ></PageHeader>

    <v-layout justify-center flex-column align-stretch class="ma-4">
      <v-text-field
        v-model="searchTerm"
        placeholder="Enter a search term"
        append-outer-icon="search"
        clearable
        @keydown.enter="searchDSR"
        @click:append-outer="searchDSR"
        @click:clear="onClearClicked"
        class="col-sm-6"
      >
      </v-text-field>

      <Table
        :tableData="this.dsrs.results"
        :options.sync="options"
        :serverItemsLength="this.dsrs.count"
        :itemsPerPage="this.dsrs.per_page"
        :headers="this.headers"
        :filterDisplayed.sync="this.showFilter"
        v-on:toggle-filter="this.toggleFilter"
        v-on:updated-options="fetchDSRsWithPage"
        class="ma-auto"
      >
        <template v-slot:actions="slotProps">
          <v-icon
            small
            class="mr-1"
            @click="showDSRDetails(slotProps.item)"
            style="font-size: 20px"
            color="grey"
          >
            mdi-eye
          </v-icon>
          <v-icon
            small
            class="mr-1"
            @click="openDSREditForm(slotProps.item)"
            style="font-size: 20px"
            color="grey"
          >
            mdi-pen
          </v-icon>
          <v-icon
            v-if="slotProps.item.is_active == true"
            small
            class="mr-1"
            @click="openDSRDeactivateConfirm(slotProps.item)"
            style="font-size: 20px"
            color="grey"
          >
            mdi-account
          </v-icon>
          <v-icon
            v-else
            small
            class="mr-1"
            @click="openDSRActivateConfirm(slotProps.item)"
            style="font-size: 20px"
            color="grey"
          >
            mdi-account-off
          </v-icon>
        </template>
      </Table>
      <CreateDSR
        :display="createDSRModalShow"
        :dsr="this.dsrProp"
        v-on:close-modal="createDSRModalShow = false"
      ></CreateDSR>
      <EditDSR
        :display="editDSRModalShow"
        :dsr="this.dsrProp"
        v-on:close-modal="editDSRModalShow = false"
      ></EditDSR>
      <ActivateDeactivateDSR
        v-bind:dsr="this.dsrProp"
        v-bind:display="this.showDeactivateModal"
        v-on:close-modal="showDeactivateModal = false"
        v-bind:isDeactivateAction="this.isDeactivateAction"
      ></ActivateDeactivateDSR>
    </v-layout>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PageHeader from '../../components/PageHeader/PageHeader.vue';
import Table from '../../components/Table/Table.vue';
import CreateDSR from './CreateDSR.vue';
import filterSchema from './filterSchema';
import ActivateDeactivateDSR from './ActivateDeactivateDSR.vue';
import EditDSR from './EditDSR.vue';
// import DSRForm from './DSRForm.vue';

export default {
  name: 'DSRs',
  components: { PageHeader, Table, CreateDSR, ActivateDeactivateDSR, EditDSR },
  data() {
    const isAdmin = this.$store.getters['auth/isAdmin'];
    const headers = [
      {
        value: 'first_name',
        text: 'First Name',
        searchable: true,
        sortable: true,
      },
      {
        value: 'last_name',
        text: 'Last Name',
      },
      {
        value: 'username',
        text: 'Phone',
      },
    ];
    if (isAdmin) {
      headers.push({
        value: 'action',
        text: 'Actions',
      });
    }
    return {
      searchTerm: '',
      isDeactivateAction: false,
      headers,
      showDeactivateModal: false,
      editDSRModalShow: false,
      showFilter: false,
      createDSRModalShow: false,
      createDSRData: null,
      options: {},
      dsrProp: null,
      dsrFilterSchema: filterSchema,
    };
  },
  mounted() {
    this.searchDSR();
  },
  computed: {
    ...mapGetters('auth', ['isAdmin', 'isSuperUser']),
    ...mapGetters('dsrs', ['dsrs']),
  },
  methods: {
    ...mapActions('dsrs', ['fetchDSRs']),
    async fetchDSRsWithPage(options) {
      const { page } = options;
      await this.fetchDSRs({ page, searchTerm: this.searchTerm });
    },
    async searchDSR() {
      await this.fetchDSRs({ searchTerm: this.searchTerm });
    },

    async onClearClicked() {
      await this.fetchDSRs({ page: 1 });
    },
    showDSRDetails(dsr) {
      this.$router.push({
        path: `/dsrs/view/${dsr.username}`,
        params: { ref: dsr.username },
      });
    },
    editDSRDetails(dsr) {
      this.$router.push({
        path: `/dsrs/edit/${dsr.id}`,
        params: { ref: dsr.id },
      });
      this.dsrProp = dsr;
    },
    openDSRDeactivateConfirm(dsr) {
      this.dsrProp = dsr;
      this.isDeactivateAction = true;
      this.showDeactivateModal = true;
    },
    openDSRActivateConfirm(dsr) {
      this.dsrProp = dsr;
      this.isDeactivateAction = false;
      this.showDeactivateModal = true;
    },
    isUserActive(prop) {
      if (prop.is_active === true) {
        return 'mdi-account';
      }
      return 'mid-account-off';
    },
    openDSRForm(dsr = null) {
      this.dsrProp = dsr;
      this.createDSRModalShow = true;
    },
    openDSREditForm(dsr = null) {
      this.dsrProp = dsr;
      this.editDSRModalShow = true;
    },
    toggleFilter() {
      this.showFilter = !this.showFilter;
    },
    iconAction() {},
  },
};
</script>
