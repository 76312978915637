const schema = {
  fields: [
    {
      label: 'Template',
      type: 'select',
      model: 'template',
      required: true,
      values: () => [],
    },
    {
      type: 'textArea',
      inputType: 'text',
      label: 'Message',
      model: 'message',
      required: true,
      max: 1000,
      min: 5,
      validator: ['required'],
    },
  ],
};
const model = {
  template: {},
  message: '',
  templateId: '',
};

export { schema, model };
