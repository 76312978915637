<template>
  <div class="content col-12">
    <v-row v-if="loanData">
      <v-col lg="3" md="6" sm="12" class="details">
        <v-row>
          <v-col class="light"> Principal Amount </v-col>
          <v-col class="dark">
            {{ loanData.principal }}
          </v-col>
        </v-row>

        <v-row>
          <v-col class="light"> Applied Amount </v-col>
          <v-col class="dark">
            {{ loanData.applied_amount }}
          </v-col>
        </v-row>

        <v-row>
          <v-col class="light"> Disbursed Date </v-col>
          <v-col class="dark">
            {{ formatter(loanData.disbursement_date) }}
          </v-col>
        </v-row>

        <v-row>
          <v-col class="light"> Due Date </v-col>
          <v-col class="dark">
            {{ formatter(loanData.due_date) }}
          </v-col>
        </v-row>

        <v-row>
          <v-col class="light"> Created At </v-col>
          <v-col class="dark">
            {{ loanData.created_at ? loanData.created_at.slice(0, 10) : '-' }}
          </v-col>
        </v-row>

        <v-row>
          <v-col class="light"> Grace Period </v-col>
          <v-col class="dark"> {{ loanData.grace_period }} </v-col>
        </v-row>

        <v-row>
          <v-col class="light"> Loan Cycle </v-col>
          <v-col class="dark">
            {{ loanData.loan_cycle }}
          </v-col>
        </v-row>
      </v-col>

      <v-col lg="3" md="6" sm="12" class="details">
        <v-row>
          <v-col class="light"> Loan Status </v-col>
          <v-col class="dark">
            {{ formatter(loanData.status) }}
          </v-col>
        </v-row>

        <v-row>
          <v-col class="light"> Collection Level </v-col>
          <v-col class="dark">
            {{ formatter(loanData.collection_level) }}
          </v-col>
        </v-row>

        <v-row>
          <v-col class="light"> Applicant </v-col>
          <v-col class="dark">
            {{ formatter(loanData.applicant) }}
          </v-col>
        </v-row>

        <v-row>
          <v-col class="light"> Loan Period </v-col>
          <v-col class="dark"> {{ loanData.loan_period }} Days </v-col>
        </v-row>

        <v-row>
          <v-col class="light"> Interest Rate </v-col>
          <v-col class="dark"> {{ loanData.interest_rate }} % </v-col>
        </v-row>

        <v-row>
          <v-col class="light"> Channel </v-col>
          <v-col class="dark">
            {{ formatter(loanData.channel) }}
          </v-col>
        </v-row>

        <v-row>
          <v-col class="light"> Collection Level </v-col>
          <v-col class="dark">
            {{ formatter(loanData.collection_level) }}
          </v-col>
        </v-row>
      </v-col>

      <v-col lg="3" md="6" sm="12" class="details">
        <v-row>
          <v-col class="light"> Disbursment Account </v-col>
          <v-col class="dark">
            {{ formatter(loanData.disbursement_account) }}
          </v-col>
        </v-row>

        <v-row>
          <v-col class="light"> Disbursal Code </v-col>
          <v-col class="dark">
            {{ formatter(loanData.disbursal_code) }}
          </v-col>
        </v-row>

        <v-row>
          <v-col class="light"> Processing Time </v-col>
          <v-col class="dark">
            {{ formatter(loanData.total_processing_time) }}
          </v-col>
        </v-row>

        <v-row>
          <v-col class="light"> Days in Arrears </v-col>
          <v-col class="dark">
            {{ loanData.days_in_arrears }}
          </v-col>
        </v-row>

        <v-row>
          <v-col class="light"> Outstanding Principal </v-col>
          <v-col class="dark"> {{ loanData.outstanding_principal }} KSH </v-col>
        </v-row>

        <v-row>
          <v-col class="light"> Total Expected Amount </v-col>
          <v-col class="dark">
            {{ loanData.total_expected_repayment }} KSH
          </v-col>
        </v-row>
      </v-col>

      <v-col lg="3" md="6" sm="12" class="details">
        <v-row>
          <v-col class="light"> Outstanding Rollover Fee </v-col>
          <v-col class="dark">
            {{ loanData.outstanding_rollover_fee }} KSH
          </v-col>
        </v-row>

        <v-row>
          <v-col class="light"> Outstanding Charges </v-col>
          <v-col class="dark"> {{ loanData.outstanding_charges }} KSH </v-col>
        </v-row>

        <v-row>
          <v-col class="light"> Outstanding Interest </v-col>
          <v-col class="dark"> {{ loanData.outstanding_interest }} KSH </v-col>
        </v-row>

        <v-row>
          <v-col class="light"> Outstanding Penalty </v-col>
          <v-col class="dark"> {{ loanData.outstanding_penalty }} KSH </v-col>
        </v-row>

        <v-row>
          <v-col class="light"> Outstanding Balance </v-col>
          <v-col class="dark"> {{ loanData.outstanding_balance }} KSH </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div v-if="!loanData" class="no-loan">
      <v-icon large style="font-size: 90px" color="green">
        mdi-currency-usd-off
      </v-icon>
      <h3>No Loan has been taken under this account</h3>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoanOverview',
  props: ['loanData'],
  methods: {
    formatter(text) {
      if (!text) return '-';
      // eslint-disable-next-line radix
      if (typeof text === 'number') return text;
      return text.slice(0, 1).toUpperCase() + text.slice(1);
    },
  },
};
</script>

<style scoped>
.wrapper {
  margin: 0 35px 60px;
  padding: 20px 30px 40px;
}
.content {
  padding-bottom: 30px;
  padding-top: 0px;
}
.light {
  color: #8c98a4;
}
.dark {
  font-weight: 500;
}
.details {
  font-size: 14px;
  padding-right: 50px;
}
.no-loan {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;
  padding: 60px 0;
}
.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: #f1f1f1;
}
</style>
