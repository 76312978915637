<template>
  <Modal
    v-if="application"
    :title="title"
    :openModal="display"
    v-on:close-modal="$emit('close-modal')"
  >
    <template v-slot:content>
      <div v-if="simpleMessage !== null" class="">
        <h3>{{ simpleMessage }}</h3>
      </div>

      <div v-if="simpleMessage === null">
        <component
          v-bind:is="vettingComponentToUse"
          v-bind:formErrors.sync="formErrors"
          v-on:submit-vetting="submitVetting"
          :application="application"
        ></component>
        <LoanAction
          v-bind:methodToDispatch="currentMethod"
          v-bind:loanAction="currentAction"
          v-bind:loanRef="currentLoanRef"
          v-bind:actionMessage="actionMessage"
          :visible="loanActionModalShow"
          @close="loanActionModalShow = false"
          @success="loanActionModalShow = false"
          @error="(payload) => (formErrors = payload)"
        />
        <DisburseLoan
          :loanRef="currentLoanRef"
          :partnerName="partnerName"
          :display="loanDisbursalModalShow"
          @close-modal="loanDisbursalModalShow = false"
        />
      </div>
    </template>
  </Modal>
</template>

<script>
import { mapGetters } from 'vuex';
import Modal from '../../components/Modal/Modal.vue';
import Button from '../../components/Button/Button.vue';
import LoanAction from '../Loans/LoanAction.vue';
import DisburseLoan from './DisburseLoan.vue';
import GenericVettingForm from './Vetting/GenericVettingForm.vue';

export default {
  name: 'VetApplication',
  emits: ['close-modal'],
  props: ['application', 'display'],
  components: {
    Modal,
    Button,
    LoanAction,
    DisburseLoan,
    GenericVettingForm,
  },
  data() {
    return {
      simpleMessage: null,
      formErrors: [],
      partnerName: null,
      loanActionModalShow: false,
      loanDisbursalModalShow: false,
      currentAction: '',
      currentLoanRef: '',
      actionMessage: '',
      currentMethod: 'loans/actOnLoan',
      vettingComponentToUse: null,
      title: `Vet Application`,
    };
  },

  watch: {
    display(newValue) {
      if (newValue === false) return;
      this.determineVettingStep(this.application);
    },
  },
  mounted() {
    this.formErrors = [];
    if (!this.application) this.$emit('close-modal');
  },
  methods: {
    determineVettingStep(payload) {
      const { status, partnerName } = payload;
      this.partnerName = partnerName.toUpperCase();
      this.simpleMessage = null;
      const action =
        this.application.status === 'approved' ? 'Withdraw' : 'Vet';
      this.title = `${action} Application ${this.application.ref}`;

      switch (true) {
        case this.isAdmin && status === 'rejected':
          this.simpleMessage = 'This application was rejected';
          break;
        case this.isAdmin && status === 'withdrawn':
          this.simpleMessage = 'This application was withdrawn';
          break;
        case this.isAdmin && status === 'disbursed':
          this.simpleMessage = 'This loan has been disbursed';
          break;
        case this.isAdmin:
          this.vettingComponentToUse = GenericVettingForm;
          break;
        default:
          this.simpleMessage = 'No actions available.';
          break;
      }
    },
    submitVetting(payload, action = null) {
      if (!action) return;
      this.formErrors = [];
      this.$store
        .dispatch(`applications/${action}`, {
          data: payload,
          application: this.application,
        })
        .then(() => {
          this.$emit('close-modal');
        })
        .catch((err) => {
          this.formErrors = err;
        });
    },
  },

  computed: {
    ...mapGetters('auth', { isAdmin: 'isAdmin' }),
  },
};
</script>
