const defaultSchema = {
  fields: [
    {
      type: 'select',
      label: 'Channel',
      model: 'channel',
      values: ['ussd', 'paybill'],
      default: 'ussd',
      styleClasses: 'col-md-3 pt-0 pl-0 mb-0',
    },
    {
      type: 'select',
      label: 'Completion Status',
      model: 'isSuccessFull',
      values: [true, false],
      default: true,
      styleClasses: 'col-md-3 pt-0 pl-0 mb-0',
    },
    {
      type: 'input',
      inputType: 'number',
      label: 'Lower Payment Amount',
      model: 'lowerPaymentAmount',
      styleClasses: 'col-md-3 pt-0',
    },
    {
      type: 'input',
      inputType: 'number',
      label: 'Upper Payment Amount',
      model: 'upperPaymentAmount',
      styleClasses: 'col-md-3 pt-0 pl-0 mb-0',
    },
    {
      type: 'input',
      inputType: 'date',
      label: 'Lower Date Range',
      model: 'lowerDateRange',
      required: false,
      format: 'YYYY-MM-DD',
      styleClasses: 'col-md-3 pt-0',
    },
    {
      type: 'input',
      inputType: 'date',
      label: 'Upper Date Range',
      model: 'upperDateRange',
      required: false,
      format: 'YYYY-MM-DD',
      styleClasses: 'col-md-3 pt-0',
    },
  ],
};

const defaultModel = {
  channel: '',
  isSuccessFull: '',
  lowerPaymentAmount: '',
  upperPaymentAmount: '',
  lowerDateRange: '',
  upperDateRange: '',
};

export { defaultModel, defaultSchema };
