<template>
  <div class="width100">
    <PageHeader
      headingText="Kycs"
      btnText="Add Kyc Item"
      v-on:action-button-clicked="openKycForm()"
    ></PageHeader>
    <v-layout justify-center flex-column align-stretch class="ma-4">
      <Table
        :tableData="this.kycs.results"
        :headers="this.headers"
        :options.sync="options"
        :serverItemsLength="this.kycs.count"
        :itemsPerPage="this.kycs.per_page"
        v-on:updated-options="fetchKycsWithPage"
        class="ma-auto"
      >
        <template v-slot:actions="slotProps">
          <v-icon
            small
            class="mr-6"
            @click="openKycForm(slotProps.item)"
            style="font-size: 20px"
            color="green"
          >
            mdi-lead-pencil
          </v-icon>

          <v-icon
            small
            @click="openDeleteKycForm(slotProps.item)"
            style="font-size: 20px"
            color="red"
          >
            mdi-delete
          </v-icon>
        </template>
      </Table>
      <KycForm
        v-bind:kycItem="this.kycProp"
        v-bind:display="this.showKycModal"
        v-on:close-modal="(showKycModal = false), (showDeleteKycModal = false)"
      ></KycForm>
      <DeleteKyc
        v-bind:kycItem="this.kycProp"
        v-bind:display="this.showDeleteKycModal"
        v-on:close-modal="(showKycModal = false), (showDeleteKycModal = false)"
      ></DeleteKyc>
    </v-layout>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Table from '../../components/Table/Table.vue';
import PageHeader from '../../components/PageHeader/PageHeader.vue';
import KycForm from './KycForm.vue';
import DeleteKyc from './DeleteKyc.vue';

export default {
  name: 'Kycs',
  components: {
    PageHeader,
    Table,
    KycForm,
    DeleteKyc,
  },
  data() {
    return {
      kycProp: null,
      kycItems: [],
      showKycModal: false,
      showDeleteKycModal: false,
      options: {},
      headers: [
        {
          value: 'code',
          text: 'Unique Code',
          sortable: true,
          searchable: true,
        },
        {
          value: 'value',
          text: 'Value',
          sortable: true,
          searchable: true,
        },
        {
          value: 'action',
          text: 'Actions',
        },
      ],
    };
  },
  async mounted() {
    await this.fetchKycs();
  },
  computed: {
    ...mapGetters('auth', { isLoggedIn: 'isLoggedIn' }),
    ...mapGetters('kycs', ['kycs']),
  },
  methods: {
    ...mapActions('kycs', ['fetchKycs']),
    async fetchKycsWithPage(options) {
      const { page } = options;
      await this.fetchKycs(page);
    },
    openKycForm(kycItem = null) {
      this.kycProp = kycItem;
      this.showKycModal = true;
    },
    openDeleteKycForm(kycItem) {
      this.kycProp = kycItem;
      this.showDeleteKycModal = true;
    },
  },
};
</script>
