<template>
  <Modal
    :title="title"
    :openModal="display"
    v-on:close-modal="$emit('close-modal')"
  >
    <template v-slot:content>
      <div v-if="!formModel"><h2>No Action Available</h2></div>
      <div v-if="formModel">
        <form action="" @submit.prevent="submitTemplate" id="SmsTemplateForm">
          <ErrorAlert
            v-if="formErrors.length > 0"
            :errors="formErrors"
            sticky
          />
          <vue-form-generator
            :schema="formSchema"
            :model="formModel"
          ></vue-form-generator>
          <div>
            Character Count: {{ wordCount }} (Fits:
            {{ smsCount }}
            SMS)
          </div>
        </form>
      </div>
    </template>
    <template v-slot:footer>
      <Button
        text="Submit"
        type="submit"
        form="SmsTemplateForm"
        styling="primary"
        validateBeforeSubmit
      ></Button>
    </template>
  </Modal>
</template>

<script>
import Modal from '../../components/Modal/Modal.vue';
import Button from '../../components/Button/Button.vue';
import ErrorAlert from '../../components/Alert/ErrorAlert.vue';
import {
  smsTemplateModel,
  smsTemplateSchema,
} from '../../formSchemas/sms/smsTemplateForm';

const initialData = () => ({
  formErrors: [],
  options: [],
  formModel: smsTemplateModel,
  formSchema: smsTemplateSchema,
  title: 'Create new SMS template',
  smsTemplateCount: 0,
  maxCharactersPerSMS: 160,
});

export default {
  name: 'SmsTemplateForm',
  emits: ['close-modal'],
  props: ['template', 'display'],
  components: {
    Modal,
    Button,
    ErrorAlert,
  },

  data() {
    return initialData();
  },

  methods: {
    submitTemplate() {
      this.formErrors = [];
      const formCopy = { ...this.formModel };

      const method = formCopy.url ? 'PATCH' : 'POST';
      const id = formCopy.url ? formCopy.url.split('/').pop() : null;
      this.$store
        .dispatch(`smstemplates/createOrEditTemplate`, {
          data: formCopy,
          method,
          id,
        })
        .then(() => {
          this.$emit('close-modal');
        })
        .catch((error) => {
          this.formErrors = error.response.data;
        });
    },

    checkTemplate() {
      if (this.template !== null) {
        this.title = `Edit ${this.template.name}`;
        this.formModel = { ...this.template };
      }
    },
  },

  watch: {
    template: {
      handler() {
        this.checkTemplate();
      },
      deep: true,
    },
    display: {
      handler() {
        if (!this.display) {
          Object.assign(this.$data, initialData());
        }
      },
    },
  },

  computed: {
    show: {
      get() {
        return this.display;
      },
    },
    wordCount() {
      if (this.formModel.content) {
        return this.formModel.content.length;
      }
      return 0;
    },
    smsCount() {
      if (this.formModel.content.isEmpty) {
        return 0;
      }
      const smsCount = Math.ceil(
        this.formModel.content.length / this.maxCharactersPerSMS,
      );
      return smsCount > 0 ? smsCount : 1;
    },
  },
};
</script>
