export default {
  fields: [
    {
      type: 'select',
      label: 'Status',
      model: 'status',
      values: () => {
        return [
          { id: 'active', name: 'Active' },
          { id: 'overdue', name: 'Overdue' },
          { id: 'repaid', name: 'Repaid' },
          { id: 'due_today', name: 'Due Today' },
          { id: 'freeze', name: 'Frozen' },
          { id: '', name: 'All Statuses' },
        ];
      },
      default: '',
    },
  ],
};
