<template>
  <div class="width100">
    <div class="d-flex justify-space-between align-center pb-2">
      <h4 class="pl-4">Sent SMSs</h4>
      <Button text="Send SMS" v-on:button-clicked="openSmsForm"></Button>
    </div>
    <v-data-table
      v-if="sms"
      :headers="headers"
      :items="sms.results"
      :server-items-length="sms.count"
      class="elevation-0 py-3 border"
    >
      <template v-slot:[`item.status`]="{ item }">
        <v-chip :color="getChipColor(item.status)" dark class="rounded-lg">
          {{ item.status }}
        </v-chip>
      </template>
    </v-data-table>
    <SmsTemplateSelectorModal
      v-bind:display="showSmsFormModal"
      v-on:close-modal="showSmsFormModal = false"
    ></SmsTemplateSelectorModal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Button from '../../../components/Button/Button.vue';
import SmsTemplateSelectorModal from './SmsTemplateSelectorModal.vue';

export default {
  name: 'SMSTable',
  components: { Button, SmsTemplateSelectorModal },

  data() {
    return {
      showSmsFormModal: false,
      options: {},
      headers: [
        {
          value: 'destination',
          text: 'Recipient',
        },
        {
          value: 'message',
          text: 'Message',
        },
        {
          value: 'created_at',
          text: 'Sent At',
        },
        {
          value: 'status',
          text: 'Status',
        },
      ],
    };
  },
  methods: {
    getChipColor(status) {
      if (['SUCCESS'].includes(status)) return 'green';
      if (['SENT'].includes(status)) return 'orange';
      return 'red';
    },
    openSmsForm() {
      this.showSmsFormModal = true;
    },
  },

  computed: {
    ...mapGetters('auth', { isLoggedIn: 'isLoggedIn' }),
    ...mapGetters('sms', ['sms']),
  },
};
</script>

<style scoped lang="scss">
h4 {
  font-weight: 500;
}
.parent {
  padding: 0 12px;
  background-color: #fff;
  border-radius: 5px;
  align-items: center;
}
.border {
  box-shadow: 6px 6px 18px rgba(0, 0, 0, 0.1) !important;
  border-radius: 4px;
  padding: 0px 10px;
  widows: 95%;
  margin: 0 auto 20px;
}
</style>
