const state = () => ({
  showSnackbar: false,
  snackbarIsWarning: false,
  snackbarMessage: '',
  dataLoading: true,
});

const getters = {
  snackbarDisplay: (state) => {
    return {
      show: state.showSnackbar,
      isWarning: state.snackbarIsWarning,
      text: state.snackbarMessage,
    };
  },
  isDataLoading: (state) => {
    return state.dataLoading;
  },
};

const actions = {
  clearState({ commit }) {
    const modules = [
      'applications',
      'auth',
      'dsrs',
      'borrowers',
      'commission',
      'common',
      'loans',
      'partners',
      'repayments',
      'users',
      'kycs',
      'products',
      'leads',
      'smstemplates',
    ];
    modules.forEach((mod) =>
      commit(`${mod}/intial_state`, null, { root: true }),
    );
  },
};

const mutations = {
  intial_state(state) {
    state.snackbarMessage = '';
    state.showSnackbar = false;
    state.snackbarIsWarning = false;
    state.dataLoading = false;
  },
  success_snackbar(state, message = null) {
    state.snackbarMessage = message || 'Success!';
    state.showSnackbar = true;
    state.snackbarIsWarning = false;
  },
  error_snackbar(state, message = null) {
    state.snackbarMessage = message || 'An error occured. Please try again.';
    state.showSnackbar = true;
    state.snackbarIsWarning = true;
  },
  data_requested(state) {
    state.dataLoading = true;
  },
  response_received(state) {
    state.dataLoading = false;
  },
  hide_snackbar(state) {
    state.showSnackbar = false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
