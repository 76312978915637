<template>
  <Modal
    :title="title"
    :openModal="display"
    v-on:close-modal="$emit('close-modal')"
  >
    <template v-slot:content>
      <form
        action=""
        @submit.prevent="handleClick"
        id="deleteTemplateForm"
      ></form>
    </template>
    <template v-slot:footer>
      <Button
        type="submit"
        form="deleteTemplateForm"
        styling="primary"
        text="Delete"
      ></Button>
    </template>
  </Modal>
</template>

<script>
import Modal from '../../components/Modal/Modal.vue';
import Button from '../../components/Button/Button.vue';

export default {
  name: 'DeleteTemplate',
  emits: ['close-modal'],
  props: ['template', 'display'],
  components: {
    Modal,
    Button,
  },
  data() {
    return {
      title: 'Delete SMS Template',
    };
  },
  methods: {
    handleClick() {
      this.$confirm({
        message: `Are you sure you want to delete this Sms Template?`,
        button: {
          no: 'Cancel',
          yes: 'Yes',
        },
        callback: (confirm) => {
          if (confirm) {
            this.deleteTemplate();
          }
        },
      });
    },
    deleteTemplate() {
      const { id } = this.template;
      this.$store
        .dispatch(`smstemplates/deleteTemplate`, {
          id,
        })
        .then(() => {
          this.$emit('close-modal');
        })
        .catch((err) => {
          this.formErrors = err;
        });
    },
    checkTemplate() {
      if (this.template !== null) {
        this.title = `Delete ${this.template.name} template?`;
      }
    },
  },
  watch: {
    template: {
      handler() {
        this.checkTemplate();
      },
      deep: true,
    },
  },
  mounted() {
    if (!this.template) this.$emit('close-modal');
    else this.checkTemplate();
  },
};
</script>
