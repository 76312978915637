<template>
  <div class="homepage">
    <v-layout justify-center flex-column align-stretch class="ma-4">
      <div class="col-6 ma-auto logout-container">
        <h2>Are you sure you want to log out?</h2>
        <Button v-on:button-clicked="logout()" text="Log out"></Button>
      </div>
    </v-layout>
  </div>
</template>

<script>
// TODO: Maybe change this to a modal??? Doesnt really matter, just more about preference
import Button from '../../components/Button/Button.vue';

export default {
  name: 'Logout',
  components: {
    Button,
  },
  methods: {
    async logout() {
      await this.$store.dispatch('auth/logout');
      this.$router.go();
    },
  },
};
</script>

<style lang="scss">
.homepage {
  width: 100%;
}
.logout-container {
  text-align: center;
}
</style>
