var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"width100"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"options":_vm.options,"items":_vm.scheduledSmss.results,"single-expand":true,"expanded":_vm.expanded,"server-items-length":_vm.scheduledSmss.count,"footer-props":{ itemsPerPageOptions: [_vm.scheduledSmss.per_page] },"item-key":"id","show-expand":""},on:{"update:options":function($event){_vm.options=$event},"update:expanded":function($event){_vm.expanded=$event},"updated-options":_vm.options},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-simple-table',{staticClass:"elevation-0 pl-1 mb-3",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("#")]),_c('th',{staticClass:"text-left"},[_vm._v("Borrower name")]),_c('th',{staticClass:"text-left"},[_vm._v("Phone")]),_c('th',{staticClass:"text-left"},[_vm._v("Ref")]),_c('th',{staticClass:"text-left"},[_vm._v("KE Number")]),_c('th',{staticClass:"text-left"},[_vm._v("Message Status")])])]),_c('tbody',_vm._l((Object.values(item.receipients)),function(borrower,index){return _c('tr',{key:borrower.name},[_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',[_c('a',{on:{"click":function($event){return _vm.goToBorrower(borrower)}}},[_vm._v(_vm._s(borrower.name))])]),_c('td',[_vm._v(_vm._s(borrower.phone))]),_c('td',[_vm._v(_vm._s(borrower.ref))]),_c('td',[_vm._v(_vm._s(borrower.partner_defined_id))]),_c('td',[_c('v-chip',{attrs:{"small":"","text-color":"white","color":borrower.message_status === 'SENT'
                        ? 'green'
                        : borrower.message_status === 'ERROR'
                        ? 'red'
                        : 'orange'}},[_vm._v(_vm._s(borrower.message_status))]),(borrower.error)?_c('small',[_vm._v(" - ("+_vm._s(borrower.error)+")")]):_vm._e()],1)])}),0)]},proxy:true}],null,true)})],1)]}},{key:"item.action",fn:function(ref){
                        var item = ref.item;
return [(item.status === 'Pending')?_c('Action',{attrs:{"item":item,"icon":_vm.deleteIcon,"action":_vm.confirmDelete,"color":_vm.redIcon}}):_c('div',[_vm._v("-")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }