<template>
  <div class="width100">
    <PageHeader headingText="DMS Data Refresh"></PageHeader>

    <v-layout justify-center flex-column align-stretch class="ma-4">
      <v-stepper v-model="step" vertical>
        <v-stepper-step
          step="1"
          :complete="step > 1"
          :rules="[() => !dmsValidationHasErrors]"
        >
          Upload Excel DMS Data
          <small>
            Ensure the DMS data is correctly formatted within a sheet titled
            SalesReportByCustomer for correct processing.
          </small>
        </v-stepper-step>

        <v-stepper-content step="1">
          <v-card color="grey lighten-5 elevation-0" class="mb-6 pa-6">
            <DmsDataForm
              :isLoading="dmsValidationInProgress"
              v-on:submitted="verifyRefresh"
            ></DmsDataForm>
          </v-card>
        </v-stepper-content>

        <v-stepper-step
          step="2"
          :complete="step > 2"
          :rules="[() => !dmsRefreshHasErrors]"
        >
          Confirm Generated Output
          <small>
            Download the generated output and confirm the data is correct.
          </small>
        </v-stepper-step>

        <v-stepper-content step="2">
          <v-card color="grey lighten-5 elevation-0" class="mb-6 pa-6">
            <div>
              <a :href="dmsRefreshDataLink" target="blank" class="pointer">
                <v-icon
                  large
                  class="mr-6"
                  style="font-size: 80px"
                  color="grey lighten-1"
                >
                  mdi-file
                </v-icon>

                <small>DMS Refresh Data Output</small>
              </a>
            </div>

            <v-btn
              class="mt-8 mb-3"
              color="primary"
              @click="executeRefresh"
              :loading="dmsRefreshInProgress"
            >
              It's a match! Proceed with data refresh
            </v-btn>
          </v-card>
        </v-stepper-content>

        <v-stepper-step step="3" :complete="step > 3">
          View Data Refresh Results
        </v-stepper-step>

        <v-stepper-content step="3">
          <v-card color="grey lighten-5 elevation-0" class="mb-6 pa-8">
            <v-alert dense text type="success">
              The DMS Data Refresh for {{ selectedPartnerRef }} has been
              executed successfully 💯.
            </v-alert>

            <div class="d-flex justify-start pt-3">
              <a
                :href="before_snapshot_url"
                target="blank"
                class="d-flex flex-column"
              >
                <v-icon
                  large
                  class="mr-16"
                  style="font-size: 80px"
                  color="grey lighten-1"
                >
                  mdi-file
                </v-icon>

                <small>Before snapshot</small>
              </a>

              <a
                :href="after_snapshot_url"
                target="blank"
                class="d-flex flex-column ml-16"
              >
                <v-icon large style="font-size: 80px" color="grey lighten-1">
                  mdi-file
                </v-icon>

                <small>After snapshot</small>
              </a>
            </div>
            <div class="font-weight-light mt-6 mb-6">
              The links are also accessible from slack 😎.
            </div>
            <v-btn color="primary" @click="step = 4"> Done </v-btn>
          </v-card>
        </v-stepper-content>
      </v-stepper>
    </v-layout>
  </div>
</template>

<script>
import { verifyDmsRefresh, executeDmsRefresh } from '../../api/dmsdata.api';
import PageHeader from '../../components/PageHeader/PageHeader.vue';
import DmsDataForm from './DmsDataForm.vue';

export default {
  components: {
    PageHeader,
    DmsDataForm,
  },
  data() {
    return {
      step: 1,

      dmsValidationHasErrors: false,
      dmsValidationInProgress: false,
      selectedPartnerRef: null,
      dmsRefreshDataLink: null,

      dmsRefreshHasErrors: false,
      dmsRefreshInProgress: false,

      before_snapshot_url: null,
      after_snapshot_url: null,
    };
  },
  methods: {
    async verifyRefresh(formData) {
      this.dmsValidationInProgress = true;
      this.dmsValidationHasErrors = false;
      await verifyDmsRefresh(formData)
        .then((res) => {
          this.selectedPartnerRef = res.data.partner.split('/').pop();
          this.dmsRefreshDataLink = res.data.fileUrl;
          this.dmsValidationInProgress = false;
          this.step = 2;
        })
        .catch(() => {
          this.dmsValidationInProgress = false;
          this.dmsValidationHasErrors = true;
        });
    },

    async executeRefresh() {
      this.dmsRefreshInProgress = true;
      this.dmsRefreshHasErrors = false;
      await executeDmsRefresh({
        partnerRef: this.selectedPartnerRef,
      })
        .then((res) => {
          this.dmsRefreshInProgress = false;

          const { beforeSnapshotUrl, afterSnapshotUrl } = res.data;
          this.before_snapshot_url = beforeSnapshotUrl;
          this.after_snapshot_url = afterSnapshotUrl;

          this.step = 3;
        })
        .catch(() => {
          this.dmsRefreshInProgress = false;
          this.prodRefreshHasErrors = true;
        });
    },
    onFileChanged(newFile) {
      this.fileUrl = newFile;
    },
  },
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}

a {
  text-decoration: none !important;
}
</style>
