import {
  getSmses,
  sendSingleSms,
  sendBulkSms,
  scheduleBulkSms,
} from '../api/sms.api';
import { formatDate } from '../utils/date';

const state = () => ({
  sms: {
    count: 0,
    links: {
      next: null,
      previous: null,
    },
    pages: 1,
    per_page: 30,
    results: [],
  },
  sendSmsFilterFields: {},
});

const getters = {
  sms: (state) => state.sms,
  sendSmsFilterFields: (state) => state.sendSmsFilterFields,
};

const actions = {
  async fetchSmses({ commit }, payload = {}) {
    commit('initial_state');
    const { page, searchTerm, searchParams } = payload;
    try {
      commit('common/data_requested', null, { root: true });
      const response = await getSmses(page, searchTerm, searchParams);
      commit('set_smss', response.data);
    } catch (error) {
      commit(
        'common/error_snackbar',
        'Failed to fetch Smses. Please try again later.',
        {
          root: true,
        },
      );
    } finally {
      commit('common/response_received', null, { root: true });
    }
  },
  async sendSingleSms({ commit }, { data }) {
    try {
      await sendSingleSms(data);
      commit('common/success_snackbar', 'Successfully initiated SMS', {
        root: true,
      });
    } catch (errors) {
      const message = errors.response.data;
      commit('common/error_snackbar', message.error, {
        root: true,
      });
    }
  },
  async sendBulkSms({ commit }, { data }) {
    try {
      await sendBulkSms(data);
      const messageCount = data.smsSelectionObject.selectedBorrowersCount;
      const alert = `Sending ${messageCount} SMS messages...`;
      commit('common/success_snackbar', alert, {
        root: true,
      });
    } catch (errors) {
      const message = errors.response.data;
      commit('common/error_snackbar', message.error, {
        root: true,
      });
    }
  },
  async scheduleBulkSms({ commit }, { data }) {
    try {
      const res = await scheduleBulkSms(data);
      const messageCount = Object.keys(res.data.receipients).length;
      const scheduledAt = res.data.scheduled_at.slice(0, 16);
      const alert = `Successfully scheduled ${messageCount} SMS messages for ${scheduledAt}`;
      commit('common/success_snackbar', alert, {
        root: true,
      });
    } catch (errors) {
      const message = errors.response.data;
      commit('common/error_snackbar', message.error, {
        root: true,
      });
    }
  },
  async setSendSmsFilterFields({ commit }, data) {
    try {
      commit('set_send_sms_filter_fields', data);
    } catch (error) {
      commit(
        'common/error_snackbar',
        `Failed to set sms filter fields. Please try again later.\n ${error}`,
        {
          root: true,
        },
      );
    } finally {
      commit('common/response_received', null, { root: true });
    }
  },
};

const mutations = {
  initial_state(state) {
    state.sms = {
      count: 0,
      links: {
        next: null,
        previous: null,
      },
      pages: 1,
      per_page: 30,
      results: [],
    };
  },
  set_smss(state, data) {
    data.results.forEach((sms) => {
      // eslint-disable-next-line no-param-reassign
      sms.created_at = formatDate(sms.created_at);
      return sms;
    });
    state.sms = data;
  },
  set_send_sms_filter_fields(state, data) {
    state.sendSmsFilterFields = data;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
