import Vue from 'vue';

const getSmsTemplates = (
  status = '',
  page = 1,
  searchTerm = '',
  searchParams = {},
) => {
  let url = `/api/v2/smstemplates?page=${page}`;
  if (searchTerm) {
    url = `${url}&searchTerm=${searchTerm}`;
  }
  Object.keys(searchParams).forEach((key) => {
    if (searchParams[key]) {
      url += `&${key}=${searchParams[key]}`;
    }
  });
  // since we prioritize status from advance-filter
  if (!searchParams.status) {
    url += `&status=${status}`;
  }
  return Vue.http.get(url);
};

const getTemplate = (id) => {
  return Vue.http.get(`/api/v2/smstemplates/${id}`);
};

const createOrEditTemplate = (data, method, id = null) =>
  Vue.http({
    url: `/api/v2/smstemplates${id ? `/${id}` : ''}`,
    method,
    data,
  });

const deleteTemplate = (id) => {
  return Vue.http.delete(`/api/v2/smstemplates/${id}`);
};

const reviewSmsTemplate = (payload) => {
  const { data, id } = payload;
  return Vue.http({
    url: `/api/v2/smstemplates/${id}/review`,
    method: 'PATCH',
    data,
  });
};

const publishSmsTemplate = (id) => {
  return Vue.http({
    url: `/api/v2/smstemplates/${id}/publish`,
    method: 'PATCH',
  });
};

const getScheduledSms = (
  status = '',
  page = 1,
  searchTerm = '',
  searchParams = {},
) => {
  let url = `/api/v2/scheduledsms?page=${page}`;
  if (searchTerm) {
    url = `${url}&searchTerm=${searchTerm}`;
  }
  Object.keys(searchParams).forEach((key) => {
    if (searchParams[key]) {
      url += `&${key}=${searchParams[key]}`;
    }
  });
  // since we prioritize status from advance-filter
  if (!searchParams.status) {
    url += `&status=${status}`;
  }
  return Vue.http.get(url);
};
const deleteScheduledSms = (id) => {
  return Vue.http.delete(`/api/v2/scheduledsms/${id}`);
};

export {
  getSmsTemplates,
  getTemplate,
  createOrEditTemplate,
  deleteTemplate,
  reviewSmsTemplate,
  publishSmsTemplate,
  getScheduledSms,
  deleteScheduledSms,
};
