/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import {
  getDSRs,
  getDSRBorrowers,
  createOrEditDSR,
  deactivateOrActivateDSR,
} from '../api/dsrs.api';

const state = () => ({
  dsrs: {
    count: 0,
    links: {
      next: null,
      previous: null,
    },
    pages: 1,
    per_page: 30,
    results: [],
  },
  dsr_borrowers: {
    count: 0,
    links: {
      next: null,
      previous: null,
    },
    pages: 1,
    per_page: 30,
    results: [],
  },
});

const getters = {
  dsrs: (state) => state.dsrs,
  getDSRBorrowers: (state) => state.dsr_borrowers,
};

const actions = {
  async fetchDSRs({ commit }, payload = {}) {
    commit('initial_state');
    const { page, searchTerm } = payload;
    try {
      commit('common/data_requested', null, { root: true });
      const response = await getDSRs(page, searchTerm);
      const { data } = response;
      commit('set_dsrs', response.data);
    } catch (error) {
      commit(
        'common/error_snackbar',
        `Failed to fetch dsrs. Please try again later.\n ${error}`,
        {
          root: true,
        },
      );
    } finally {
      commit('common/response_received', null, { root: true });
    }
  },
  async fetchDSRBorrowers({ commit }, payload = {}) {
    try {
      const { phone, searchTerm, status } = payload;
      const response = await getDSRBorrowers(phone, searchTerm, status);
      const { data } = response;
      commit('set_dsr_borrowers', response.data);
    } catch (error) {
      commit(
        'common/error_snackbar',
        `Failed to fetch borrowers linked to this DSR. Please try again later.\n ${error}`,
        {
          root: true,
        },
      );
    }
  },
  async createOrEditDSR({ commit }, { data, method, ref }) {
    try {
      const resp = await createOrEditDSR(data, method, ref);
      const response = await getDSRs(1, '');
      commit('set_dsrs', response.data);
      commit(
        'common/success_snackbar',
        `Dsr ${method === 'POST' ? 'created' : 'edited'} successfully`,
        {
          root: true,
        },
      );
    } catch (errors) {
      const error = errors.response.data;
      throw Array.isArray(error) ? error : [error];
    }
  },
  async deactivateOrActivateDSR({ commit }, { id, action }) {
    try {
      const resp = await deactivateOrActivateDSR(id, action);
      const response = await getDSRs(1, '');
      commit('set_dsrs', response.data);
      commit(
        'common/success_snackbar',
        action === 'activate'
          ? `DSR ${id} activated successfully`
          : `DSR ${id} deactivated successfully`,
        {
          root: true,
        },
      );
    } catch (errors) {
      const error = errors.response.data;
      throw Array.isArray(error) ? error : [error];
    }
  },
};

const mutations = {
  initial_state(state) {
    state.dsrs = {
      count: 0,
      links: {
        next: null,
        previous: null,
      },
      pages: 1,
      per_page: 30,
      results: [],
    };
  },
  set_dsrs(state, data) {
    state.dsrs = data;
  },
  set_dsr_borrowers(state, data) {
    state.dsr_borrowers = data;
  },
  updated_dsr(state, data) {
    state.dsrs = state.dsrs.map((value) => {
      let dsr = value;
      if (dsr.id === data.id) {
        dsr = { ...dsr, ...data };
      }
      return dsr;
    });
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
