export default {
  fields: [
    {
      type: 'input',
      inputType: 'password',
      label: 'Enter new password',
      model: 'newPassword',
      placeholder: '',
      required: true,
      validator: ['password'],
    },
    {
      type: 'input',
      inputType: 'password',
      label: 'Confirm new password',
      model: 'confirmPassword',
      placeholder: '',
      required: true,
      validator: ['password'],
    },
  ],
};
