<template>
  <v-layout justify-space-between flex-wrap class="ma-4">
    <div class="d-flex justify-space-between flex-wrap col-12 header">
      <h2>{{ headingText }}</h2>
      <Button
        v-if="btnText"
        :text="btnText"
        v-on:button-clicked="buttonClicked"
      ></Button>
    </div>
    <div v-if="breadcrumbs" class="capitalise justify-start flex-row">
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
    </div>
  </v-layout>
</template>

<script>
import Button from '../Button/Button.vue';

export default {
  name: 'PageHeader',
  components: { Button },
  emits: ['action-button-clicked'],
  props: {
    headingText: { type: String, required: true },
    btnText: { type: String, required: false, default: () => null },
    breadcrumbs: {
      type: Array,
    },
  },

  methods: {
    buttonClicked() {
      this.$emit('action-button-clicked');
    },
  },
};
</script>

<style lang="scss">
.header {
  padding-bottom: 0;
}
.v-application ol,
.v-application ul {
  padding-left: 12px;
}
.v-application .ma-4 {
  margin: 0 16px 16px !important;
}
h2 {
  color: #334d6e;
}
.v-btn--rounded {
  box-shadow: none;
  color: unset;
  text-transform: none !important;
  &:active,
  &:hover {
    transform: scale(1.01, 1.01);
    box-shadow: none;
  }

  &.btn--primary {
    background: $primary-green !important;
    border: 1px solid $primary-green;
    color: #ffffff;
  }

  &.v-btn.v-btn--disabled {
    background: $gray;
    border: $gray;
  }
}
</style>
