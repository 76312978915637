<template>
  <div class="width100">
    <!-- ACCOUNTS LIST -->
    <v-data-table
      :headers="headers"
      :options.sync="options"
      :items="scheduledSmss.results"
      :single-expand="true"
      :expanded.sync="expanded"
      :server-items-length="scheduledSmss.count"
      :footer-props="{ itemsPerPageOptions: [scheduledSmss.per_page] }"
      item-key="id"
      show-expand
      v-on:updated-options="options"
    >
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-simple-table class="elevation-0 pl-1 mb-3">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">#</th>
                  <th class="text-left">Borrower name</th>
                  <th class="text-left">Phone</th>
                  <th class="text-left">Ref</th>
                  <th class="text-left">KE Number</th>
                  <th class="text-left">Message Status</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(borrower, index) in Object.values(item.receipients)"
                  :key="borrower.name"
                >
                  <td>{{ index + 1 }}</td>
                  <td>
                    <a @click="goToBorrower(borrower)">{{ borrower.name }}</a>
                  </td>
                  <td>{{ borrower.phone }}</td>
                  <td>{{ borrower.ref }}</td>
                  <td>{{ borrower.partner_defined_id }}</td>
                  <td>
                    <v-chip
                      small
                      text-color="white"
                      :color="
                        borrower.message_status === 'SENT'
                          ? 'green'
                          : borrower.message_status === 'ERROR'
                          ? 'red'
                          : 'orange'
                      "
                      >{{ borrower.message_status }}</v-chip
                    >
                    <small v-if="borrower.error"
                      >&nbsp;- &nbsp; ({{ borrower.error }})</small
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </td>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <Action
          v-if="item.status === 'Pending'"
          :item="item"
          :icon="deleteIcon"
          :action="confirmDelete"
          :color="redIcon"
        />
        <div v-else>-</div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Action from '../../components/Action/Action.vue';

export default {
  name: 'ScheduledSmss',
  components: {
    Action,
  },
  data() {
    return {
      expanded: [],
      options: {},
      deleteIcon: 'mdi-delete',
      editIcon: 'mdi-lead-pencil',
      detailsIcon: 'mdi-eye',
      greenIcon: 'green',
      redIcon: 'red',
      greyIcon: 'grey',
      showAdvancedSearch: false,
      showTemplateModal: false,
      showDeleteModal: false,
      templateProp: null,
      searchTerm: '',
      headers: [
        {
          value: 'template_name',
          text: 'Template Name',
          width: '15%',
        },
        {
          value: 'template_category',
          text: 'SMS Category',
        },
        {
          value: 'created_at',
          text: 'Created On',
        },
        {
          value: 'scheduled_at',
          text: 'Scheduled For',
        },
        {
          value: 'status',
          text: 'Status',
        },
        {
          value: 'action',
          text: 'Actions',
          align: 'center',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('auth', ['isAdmin']),
    ...mapGetters('smstemplates', ['scheduledSmss']),
  },
  methods: {
    ...mapActions('smstemplates', ['fetchScheduledSmss']),

    toggleAdvancedSearch() {
      this.showAdvancedSearch = !this.showAdvancedSearch;
    },

    async fetchScheduledSmssPage(options) {
      const status = 'active';
      const { page } = options;
      await this.fetchScheduledSmss({
        status,
        page,
        searchTerm: this.searchTerm,
      });
    },

    async searchTemplate(searchParams) {
      const status = searchParams.status || '';
      await this.fetchScheduledSmss({
        status,
        searchTerm: this.searchTerm,
        searchParams,
      });
    },

    async onClearClicked() {
      await this.fetchSmsTemplates();
    },

    goToBorrower(borrower) {
      this.$router.push({
        path: `/borrowerprofile/${borrower.ref}`,
        params: { ref: borrower.ref },
      });
    },

    confirmDelete(scheduled) {
      this.$confirm({
        message: `Are you sure you want to delete this scheduled Sms?`,
        button: {
          no: 'Cancel',
          yes: 'Yes',
        },
        callback: (confirm) => {
          if (confirm) {
            this.$store.dispatch(`smstemplates/deleteScheduledSms`, {
              id: scheduled.id,
            });
          }
        },
      });
    },
  },
  watch: {
    options: {
      handler() {
        this.fetchScheduledSmssPage(this.options);
      },
    },
    deep: true,
  },
};
</script>

<style scoped lang="scss">
.theme--light.v-data-table {
  background: transparent;
}
.v-input {
  padding-right: 40%;
  padding-left: 16px;
  margin: 0;
}
.v-application p {
  padding-left: 50px;
}
.no-uppercase {
  text-transform: unset !important;
}
.left {
  padding: 1em;
}
.v-btn {
  color: #699a4c;
}
.right {
  padding: 1em;
}
.parent {
  padding: 0 12px;
  background-color: #fff;
  border-radius: 5px;
}
.active {
  background-color: #699a4c25;
}
</style>
