<template>
  <SimpleDialog
    :open="visible"
    :text="`${actionMessage} loan ${loanRef}`"
    @close="closeModal"
    @dialog-decision="(payload) => actOnDecision(payload)"
  />
</template>

<script>
import SimpleDialog from '../../components/SimpleDialog/SimpleDialog.vue';

export default {
  name: 'LoanAction',
  props: {
    methodToDispatch: { type: String, required: true },
    loanAction: { type: String, required: true },
    loanRef: { type: String, required: true },
    actionMessage: { type: String, required: true },
    visible: { type: Boolean, required: true },
  },
  components: {
    SimpleDialog,
  },
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },
  methods: {
    closeModal() {
      this.show = false;
    },
    actOnDecision(proceed) {
      if (proceed) {
        this.onClick();
        return;
      }
      this.show = false;
    },
    onClick() {
      this.$store
        .dispatch(this.methodToDispatch, {
          loanRef: this.loanRef,
          loanAction: this.loanAction,
        })
        .then(() => {
          this.$emit('success');
        })
        .catch((err) => {
          this.$emit('error', err);
        });
    },
  },
};
</script>
