<template>
  <div class="col-11" transition="scroll-y-transition">
    <v-expand-transition>
      <v-card elevation="2" class="pa-4" v-if="this.show">
        <h3>Filter Data</h3>
        <form action="" @submit.prevent="" id="dataFilter" class="mt-4">
          <vue-form-generator
            :schema="schema"
            :model="filterModel"
            :options="formOptions"
          ></vue-form-generator>
        </form>
      </v-card>
    </v-expand-transition>
  </div>
</template>
<script>
export default {
  name: 'DataFilter',
  props: {
    data: {
      type: Array,
      default: () => [],
      validator: (value) => Array.isArray(value),
    },
    schema: {
      type: Object,
      required: true,
      validator: (value) => !!value.fields,
    },
    model: { type: Object, required: true },
    show: { type: Boolean, default: () => false },
  },
  emits: ['filter-data'],
  data() {
    return {
      showFilter: false,
      filterModel: {},
      formOptions: {
        validateAfterChanged: true,
        validateDebounceTime: 1000,
      },
    };
  },
  methods: {},
  mounted() {
    this.filterModel = this.model;
  },
  watch: {
    filterModel: {
      handler(newModel) {
        const newData = this.data.filter((dataValues) => {
          let notValidForFilter;
          Object.keys(newModel).forEach((key) => {
            if (newModel[key].length < 1) {
              return;
            }
            if (newModel[key].toUpperCase() !== dataValues[key].toUpperCase())
              notValidForFilter = true;
          });
          if (notValidForFilter !== undefined) return false;
          return true;
        });
        this.$emit('filter-data', newData, newModel);
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss">
.filter-container {
  padding: 0.5rem 1rem;
  max-height: 70px;
}
</style>
