<template>
  <Modal
    :openModal="display"
    v-model="show"
    v-on:close-modal="$emit('close-modal')"
    :title="title"
    class="col-6"
  >
    <template v-slot:content>
      <form action="" @submit.prevent="handleClick" id="vettingForm">
        <ErrorAlert
          v-if="formErrors ? formErrors.length > 0 : false"
          :errors="formErrors"
          sticky
        />
        <div v-if="loanLimit" class="ml-2 mb-4">
          <b>
            <i> Loan Limit: {{ loanLimit.toLocaleString() }} </i>
          </b>
        </div>
        <vue-form-generator
          :schema="formSchema"
          :model="formModel"
        ></vue-form-generator>
        <Button
          text="Submit"
          type="submit"
          styling="primary"
          validateBeforeSubmit
          :disabled="isDisabled"
        ></Button>
      </form>
    </template>
  </Modal>
</template>

<script>
import ErrorAlert from '../../components/Alert/ErrorAlert.vue';
import Button from '../../components/Button/Button.vue';
import Modal from '../../components/Modal/Modal.vue';
import {
  veewLoanDisbursalSchema,
  veewLoanDisbursalModel,
} from '../../formSchemas/veewLoanDisbursalForm';

export default {
  name: 'DisburseLoan',
  props: [
    'loanRef',
    'display',
    'partnerName',
    'interestRate',
    'loanPeriod',
    'disbursalCode',
    'principal',
    'approvedAmount',
    'vsmTillNumber',
  ],
  components: { ErrorAlert, Button, Modal },
  data() {
    return {
      loanLimit: null,
      formErrors: [],
      isLoading: false,
    };
  },
  computed: {
    isDisabled() {
      return this.isLoading;
    },
    show: {
      get() {
        return this.display;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
    title() {
      return `Disburse loan ${this.loanRef}`;
    },
    formModel() {
      // TODO make this model generic for any partner
      const {
        interestRate,
        loanPeriod,
        disbursalCode,
        principal,
        approvedAmount,
        vsmTillNumber,
        ...rest
      } = veewLoanDisbursalModel;
      return {
        interestRate: this.interestRate,
        loanPeriod: this.loanPeriod,
        disbursalCode: this.disbursalCode,
        principal: this.principal,
        approvedAmount: this.approvedAmount,
        vsmTillNumber: this.vsmTillNumber,
        ...rest,
      };
    },
    formSchema() {
      return veewLoanDisbursalSchema; // TODO make this model generic for any partner
    },
  },
  methods: {
    handleClick() {
      this.$confirm({
        message: `Are you sure you want to disburse this loan?`,
        button: {
          no: 'Cancel',
          yes: 'Yes',
        },
        callback: (confirm) => {
          if (confirm) {
            this.disburseLoanFunds();
          }
        },
      });
    },
    disburseLoanFunds() {
      this.formErrors = [];
      this.isLoading = true;
      this.$store
        .dispatch('applications/disburseLoanAction', {
          data: this.formModel,
          ref: this.loanRef,
        })
        .then(() => {
          this.isLoading = false;
          this.$emit('close-modal');
        })
        .catch((err) => {
          this.isLoading = false;
          this.formErrors = err;
        });
    },
  },
};
</script>
