import {
  getSmsTemplates,
  getTemplate,
  createOrEditTemplate,
  deleteTemplate,
  reviewSmsTemplate,
  publishSmsTemplate,
  getScheduledSms,
  deleteScheduledSms,
} from '../api/smstemplates.api';
import { formatDate } from '../utils/date';

const state = () => ({
  smsTemplates: {
    count: 0,
    links: {
      next: null,
      previous: null,
    },
    pages: 1,
    per_page: 30,
    results: [],
  },
  currentTemplate: {},
  scheduledSmss: {
    count: 0,
    links: {
      next: null,
      previous: null,
    },
    pages: 1,
    per_page: 30,
    results: [],
  },
});

const getters = {
  smsTemplates: (state) => state.smsTemplates,
  currentTemplate: (state) => state.currentTemplate,
  scheduledSmss: (state) => state.scheduledSmss,
};

const actions = {
  async fetchTemplate({ commit }, templateId) {
    try {
      const { data } = await getTemplate(templateId);
      commit('set_current_template', data);
    } catch (error) {
      commit(
        'common/error_snackbar',
        `Failed to fetch active borrower. An error happened ${error}`,
        {
          root: true,
        },
      );
    }
  },
  async activeLoanNotSelectedError({ commit }) {
    try {
      commit(
        'common/error_snackbar',
        `To send sms in Debt Collection category you need to select either Active, Due or Overdue option on the loan status multi-select dropdown`,
        {
          root: true,
        },
      );
    } catch (error) {
      commit(
        'common/error_snackbar',
        `Failed to display alert for Debt Collection sms categories.`,
        {
          root: true,
        },
      );
    }
  },
  async overdueLoanNotSelectedError({ commit }) {
    try {
      commit(
        'common/error_snackbar',
        `To send sms in PTP Reminder category you need to select either Overdue or Frozen option on the loan status multi-select dropdown`,
        {
          root: true,
        },
      );
    } catch (error) {
      commit(
        'common/error_snackbar',
        `Failed to display alert for  PTP Reminder sms categories`,
        {
          root: true,
        },
      );
    }
  },
  resetCurrentTemplate({ commit }) {
    commit('unset_current_template');
  },
  async fetchSmsTemplates({ commit }, payload = {}) {
    const { status, page, searchTerm, searchParams } = payload;
    try {
      commit('common/data_requested', null, { root: true });
      const response = await getSmsTemplates(
        status,
        page,
        searchTerm,
        searchParams,
      );
      const resp = await getSmsTemplates(status, 2, searchTerm, searchParams);
      // merge all templates to one at this point
      response.data.results = response.data.results.concat(resp.data.results);
      const { data } = response;
      commit('set_smstemplates', data);
    } catch (error) {
      commit(
        'common/error_snackbar',
        `Failed to fetch Sms Templates. Please try again later.\n ${error}`,
        {
          root: true,
        },
      );
    } finally {
      commit('common/response_received', null, { root: true });
    }
  },
  async createOrEditTemplate({ commit }, { data, method, id }) {
    try {
      const resp = await createOrEditTemplate(data, method, id);
      commit(method === 'POST' ? 'add_template' : 'update_template', {
        data: resp.data,
        id,
      });
      commit(
        'common/success_snackbar',
        `Sms template ${method === 'POST' ? 'added' : 'updated'} successfully`,
        {
          root: true,
        },
      );
    } catch (errors) {
      const error = errors.response.data;
      throw Array.isArray(error) ? error : [error];
    }
  },
  async deleteTemplate({ commit }, { id }) {
    try {
      await deleteTemplate(id);
      commit('deleted_template', id);
      commit('common/success_snackbar', 'Sms Template deleted successfully', {
        root: true,
      });
    } catch (errors) {
      const error = errors.response.data;
      throw Array.isArray(error) ? error : [error];
    }
  },
  async reviewTemplate({ commit }, { data, id }) {
    try {
      const payload = {
        data,
        id,
      };
      const resp = await reviewSmsTemplate(payload);
      commit('update_template', {
        data: resp.data,
        id,
      });
      commit('common/success_snackbar', 'Review submitted successfully', {
        root: true,
      });
    } catch (errors) {
      const error = errors.response.data;
      throw Array.isArray(error) ? error : [error];
    }
  },
  async publishTemplate({ commit }, { id }) {
    try {
      const resp = await publishSmsTemplate(id);
      commit('update_template', {
        data: resp.data,
        id,
      });
      commit('common/success_snackbar', 'Sms Template published successfully', {
        root: true,
      });
    } catch (errors) {
      const error = errors.response.data;
      throw Array.isArray(error) ? error : [error];
    }
  },
  async fetchScheduledSmss({ commit }, payload = {}) {
    const { status, page, searchTerm, searchParams } = payload;
    try {
      commit('common/data_requested', null, { root: true });
      const response = await getScheduledSms(
        status,
        page,
        searchTerm,
        searchParams,
      );
      const { data } = response;
      commit('set_scheduled_smss', data);
    } catch (error) {
      commit(
        'common/error_snackbar',
        `Failed to fetch Scheduled Sms. Please try again later.\n ${error}`,
        {
          root: true,
        },
      );
    } finally {
      commit('common/response_received', null, { root: true });
    }
  },
  async deleteScheduledSms({ commit }, { id }) {
    try {
      await deleteScheduledSms(id);
      commit('deleted_scheduled_sms', id);
      commit('common/success_snackbar', 'Scheduled SMS deleted successfully', {
        root: true,
      });
    } catch (errors) {
      const error = errors.response.data;
      throw Array.isArray(error) ? error : [error];
    }
  },
};

const mutations = {
  initial_state(state) {
    state.smsTemplates = {
      count: 0,
      links: {
        next: null,
        previous: null,
      },
      pages: 1,
      per_page: 30,
      results: [],
    };
    state.currentTemplate = null;
  },
  set_current_template(state, template) {
    state.currentTemplate = template;
  },
  unset_current_template() {
    state.currentTemplate = [];
  },
  set_smstemplates(state, data) {
    state.smsTemplates = data;
    const formattedData = data.results.map((record) => {
      const item = { ...record };
      item.created_at = formatDate(item.created_at);
      item.updated_at = formatDate(item.updated_at);
      return item;
    });
    state.smsTemplates.results = formattedData;
  },
  add_template(state, data) {
    const item = { ...data.data };
    item.created_at = formatDate(item.created_at);
    item.updated_at = formatDate(item.updated_at);
    state.smsTemplates.results.unshift(item);
  },
  update_template(state, { ...data }) {
    state.smsTemplates.results = state.smsTemplates.results.map((rec) => {
      let template = rec;
      // eslint-disable-next-line eqeqeq
      if (template.id == data.id) {
        template = { ...template, ...data.data };
        template.created_at = formatDate(template.created_at);
        template.updated_at = formatDate(template.updated_at);
      }
      return template;
    });
  },
  deleted_template(state, id) {
    const { results } = state.smsTemplates;
    const templateItem = results.find((tmpl) => tmpl.id === id);
    results.splice(results.indexOf(templateItem), 1);
    state.smsTemplates.results = results;
  },
  set_scheduled_smss(state, data) {
    state.scheduledSmss = data;
    const formattedData = data.results.map((record) => {
      const item = { ...record };
      item.created_at = formatDate(item.created_at);
      item.updated_at = formatDate(item.updated_at);
      item.scheduled_at = formatDate(item.scheduled_at);
      return item;
    });
    state.scheduledSmss.results = formattedData;
  },
  deleted_scheduled_sms(state, id) {
    const { results } = state.scheduledSmss;
    const templateItem = results.find((sms) => sms.id === id);
    results.splice(results.indexOf(templateItem), 1);
    state.scheduledSmss.results = results;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
