<template>
  <Modal
    :title="title"
    :openModal="display"
    v-on:close-modal="$emit('close-modal')"
    v-model="show"
    class="col-6"
  >
    <template v-slot:content>
      <div v-if="!formModel"><h2>No Action Available</h2></div>
      <div v-if="formModel">
        <form action="" @submit.prevent="submitKycFile" id="kycFileForm">
          <ErrorAlert
            v-if="formErrors.length > 0"
            :errors="formErrors"
            sticky
          />
          <vue-form-generator
            :schema="formSchema"
            :model="formModel"
          ></vue-form-generator>
          <div class="form-group col-md-6 pt-0 field-input">
            <v-file-input
              counter
              small-chips
              truncate-length="15"
              label="Upload File"
              @change="onFileChanged($event)"
            ></v-file-input>
          </div>
        </form>
      </div>
    </template>

    <template v-slot:footer>
      <Button
        text="Submit"
        type="submit"
        form="kycFileForm"
        styling="primary"
        validateBeforeSubmit
        :disabled="isDisabled"
      ></Button>
    </template>
  </Modal>
</template>

<script>
import { mapGetters } from 'vuex';
import Modal from '../../components/Modal/Modal.vue';
import ErrorAlert from '../../components/Alert/ErrorAlert.vue';
import Button from '../../components/Button/Button.vue';
import {
  kycFileSchema,
  kycFileModel,
} from '../../formSchemas/borrower-profile/kycFileForm';

export default {
  name: 'KycFileForm',
  emits: ['close-modal'],
  props: ['fileItem', 'display', 'borrowerDetails'],
  components: {
    Modal,
    ErrorAlert,
    Button,
  },
  data() {
    return {
      formModel: kycFileModel,
      formSchema: kycFileSchema,
      borrowerRef: this.$route.params.ref,
      fileUrl: [],
      formErrors: [],
      title: 'Upload Kyc File',
      isLoading: false,
    };
  },
  methods: {
    submitKycFile() {
      this.formErrors = [];
      const formCopy = { ...this.formModel };
      const id = this.fileItem ? this.fileItem.id : null;
      const formData = new FormData();
      formData.append('fileUrl', this.fileUrl, this.fileUrl.name);
      formData.append('file_type', formCopy.file_type);
      formData.append('validity_status', formCopy.validity_status);
      formData.append('validity_date', formCopy.validity_date);
      formData.append('business', this.currentBorrower.businesses[0].url);
      formData.append('validity_date', formCopy.validity_date);
      formData.append('borrower_ref', this.borrowerDetails.borrower_ref);
      formData.append('business_name', this.borrowerDetails.business_name);
      this.isLoading = true;
      const action =
        this.title === 'Upload Kyc File' ? 'uploadKycFile' : 'kycFileEdit';
      this.$store
        .dispatch(`borrowers/${action}`, {
          data: formData,
          id,
        })
        .then(() => {
          this.isLoading = false;
          this.$emit('close-modal');
        })
        .catch((err) => {
          this.isLoading = false;
          this.formErrors = err;
        });
    },
    checkFile() {
      this.formErrors = [];
      if (this.fileItem != null) {
        this.title = `Editing ${this.fileItem.fileName}`;
        this.formModel = { ...this.fileItem };
      }
    },
    addIdToDropdownOptions(options) {
      const data = [];
      options.forEach((option) => {
        const entry = { ...option, id: option.ke_number };
        data.push(entry);
      });
      return data;
    },
    onFileChanged(newFile) {
      this.fileUrl = newFile;
    },
  },
  watch: {
    fileItem: {
      handler() {
        this.checkFile();
      },
      deep: true,
    },
  },
  async mounted() {
    this.formModel = kycFileModel;
    this.formSchema = this.schemaWithBusinesses;
    this.checkFile();
  },
  computed: {
    ...mapGetters('auth', ['isAdmin']),
    ...mapGetters('borrowers', ['currentBorrower']),
    isDisabled() {
      return this.isLoading;
    },
    show: {
      get() {
        return this.display;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
    schemaWithBusinesses() {
      const accountBusinesses = this.currentBorrower.businesses;
      const businessOptionsWithId = this.addIdToDropdownOptions(
        accountBusinesses,
      );
      const finalData = {
        fields: [],
      };
      this.formSchema.fields.forEach((field) => {
        if (field.label === 'Business') {
          const { values, ...rest } = field;
          const newField = { ...rest, values: () => businessOptionsWithId };
          finalData.fields.push(newField);
          return;
        }
        finalData.fields.push(field);
      });
      return finalData;
    },
  },
};
</script>
