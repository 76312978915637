const smsTemplateSchema = {
  fields: [
    {
      type: 'input',
      inputType: 'text',
      label: 'Name',
      model: 'name',
      required: true,
      max: 150,
      min: 5,
      styleClasses: 'col-md-6 pt-0 pl-0',
    },
    {
      type: 'select',
      label: 'Category',
      model: 'category',
      required: true,
      values: ['Engagement', 'Debt Collection', 'PTP Reminder'],
      styleClasses: 'col-md-6 pt-0 pl-0',
    },
    {
      type: 'textArea',
      inputType: 'text',
      label: 'Content',
      model: 'content',
      placeholder: 'Type the template',
      required: true,
      max: 1000,
      min: 5,
      validator: ['required'],
    },
  ],
};

const smsTemplateModel = {
  name: '',
  content: '',
};

const templateKeys = {
  'Borrower Keys': ['[Borrower Name]', '[KE Number]', '[Phone Number]'],
  'Loan Keys': [
    '[Loan Amount]]',
    '[Loan Disbursal Date]',
    '[Total Expected Repayment]',
    '[Loan Due Date]',
    '[Loan Balance]',
    '[Loan Period]',
    '[Days Until Due]',
    '[Days Overdue]',
    '[Total Repayment So Far]',
    '[Accrued Interest]',
    '[Accrued Penalty]',
    '[Outstanding Penalty]',
  ],
  'PTP Keys': ['[PTP Amount]', '[PTP Due Date]', '[Agent]', '[Contact Method]'],
};

export { smsTemplateModel, smsTemplateSchema, templateKeys };
