<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      app
      :permanent="$vuetify.breakpoint.mdAndUp"
      class="sidenav"
    >
      <v-layout justify-center>
        <v-img
          :src="require('../../assets/logo.png')"
          max-height="75"
          :max-width="160"
          alt="crediation logo"
          class="sidenav__logo"
        ></v-img>
      </v-layout>

      <v-divider width="100%" class="nav-divider"></v-divider>
      <div style="display: flex; flex-direction: column">
        <v-list dense nav>
          <v-list-item
            v-for="item in tabsList"
            :key="item.title"
            link
            :to="item.link"
            :class="item['active'] ? 'active' : ''"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
    </v-navigation-drawer>

    <v-app-bar app flat v-if="!this.$vuetify.breakpoint.mdAndUp">
      <!-- Appbar for displaying the nav toggle on smaller screens -->
      <v-layout justify-space-between>
        <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
        <v-img
          :src="require('../../assets/logo.png')"
          max-height="55"
          :max-width="200"
          alt="crediation logo"
        ></v-img>
        <div></div>
      </v-layout>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  // TODO: Add proper styling for the selected page
  name: 'NavBar',
  data() {
    const isSuperUser = this.$store.getters['auth/isSuperUser'];
    const isAdmin = this.$store.getters['auth/isAdmin'];
    const isCrmUser = this.$store.getters['auth/isCrmUser'];
    let tabsList = [];

    if (isAdmin) {
      tabsList = [
        { title: 'Leads Management', icon: 'mdi-filter', link: '/leads' },
        {
          title: 'Applications',
          icon: 'mdi-file-document-outline',
          link: '/applications',
          active: true,
        },
        { title: 'Loans', icon: 'mdi-bank', link: '/loans' },
        {
          title: 'Borrowers',
          icon: 'mdi-account-group',
          link: '/borrowers',
        },
        {
          title: 'Mpesa Payments',
          icon: 'mdi-cash',
          link: '/mpesarepayments',
        },
        {
          title: 'Product Management',
          icon: 'mdi-folder-edit',
          link: '/products',
        },
        {
          title: 'KYC Management',
          icon: 'mdi-shield',
          link: '/kycs',
        },
        {
          title: 'CRM',
        },
        {
          title: 'Borrower Profiles',
          icon: 'mdi-chart-line',
          link: '/accounts',
        },
        {
          title: 'SMS Management',
          icon: 'mdi-message-bulleted',
          link: '/smstemplates',
        },
      ];
    }
    // if (isSuperUser) {
    //   tabsList.splice(11, 0, {
    //     title: 'DSRs',
    //   });
    // }
    // if (isSuperUser) {
    //   tabsList.splice(12, 0, {
    //     title: 'View DSRs',
    //     icon: 'mdi-account-group',
    //     link: '/dsrs',
    //   });
    // }
    if (isSuperUser) {
      tabsList.splice(5, 0, {
        title: 'DMS Data Refresh',
        icon: 'mdi-refresh',
        link: '/dmsdatarefresh',
      });
    }
    if (isCrmUser) {
      tabsList = [
        {
          title: 'CRM',
        },
        {
          title: 'Borrower Profiles',
          icon: 'mdi-chart-line',
          link: '/accounts',
        },
      ];
    }
    tabsList.push({
      title: 'Logout',
      icon: 'mdi-location-exit',
      link: '/logout',
    });
    return {
      tabsList,
      drawer: false,
    };
  },
  props: {},
  methods: {},
};
</script>

<style lang="scss">
.sidenav {
  border: none;
  box-shadow: 6px 0px 18px rgba(0, 0, 0, 0.16);
  height: 100%;
  position: fixed;
}
.sidenav__logo {
  margin-top: 6px;
  margin-right: 60px;
}
.nav-divider {
  margin-top: 8px;
  margin-bottom: 16px;
}
.v-list--nav {
  padding: 0;
}
.v-list--nav .v-list-item:before {
  border-radius: 0;
}
.v-list--nav .v-list-item {
  padding-left: 1.5rem;

  div,
  div i {
    font-weight: 500;
    color: $black;
  }

  &:hover,
  .active {
    background-color: none !important;
    div,
    div i {
      color: $primary-green !important;
    }
  }

  .v-list-item .v-list-item--link::before {
    background-color: $white;
  }
  .v-list-item__icon {
    margin-right: 10px;
  }
}
.v-list--nav .v-list-item.active {
  div,
  div i {
    color: $primary-green !important;
  }
}
</style>
