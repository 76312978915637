const dmsRefreshFileSchema = {
  fields: [
    {
      type: 'select',
      label: 'Partner',
      model: 'partner',
      values: () => {
        return {};
      },
      styleClasses: 'col-md-6 pt-0 pl-0 mb-0',
    },
  ],
};

const dmsRefreshFileModel = {
  partner: {},
  fileUrl: '',
  year: '',
  month: '',
  weekDates: '',
};

export { dmsRefreshFileModel, dmsRefreshFileSchema };
