<template>
  <div class="width100">
    <PageHeader headingText="Lead Details" :breadcrumbs="items"></PageHeader>
    <v-layout justify-center flex-column align-stretch class="ma-4">
      <v-row align="center" justify="end" class="ma-auto col-10 pl-7">
        <v-col
          class="shrink"
          v-if="item.status !== 'rejected' && item.status !== 'completed'"
        >
          <v-btn color="primary" dark @click="expand = !expand">
            Actions
            <v-icon right dark>mdi-menu-down</v-icon>
          </v-btn>
          <v-expand-x-transition>
            <v-card v-show="expand" max-width="344" class="mx-auto">
              <v-card-text>
                <v-btn
                  text
                  v-if="item.status === 'pending'"
                  @click="openVettingModal"
                >
                  Vet Account
                </v-btn>
                <v-btn
                  text
                  v-if="item.status === 'approved'"
                  @click="openOnboardingLinkModal"
                >
                  Send On-Boarding Link
                </v-btn>
                <v-btn
                  text
                  v-if="item.status === 'approved'"
                  @click="openCompletenModal"
                >
                  Complete
                </v-btn>
              </v-card-text>
            </v-card>
          </v-expand-x-transition>
        </v-col>
      </v-row>
      <v-card id="mpesadetailspage" elevation="2" class="ma-auto col-10 pl-7">
        <v-card-title id="cardTitle" class="pt-0 pb-0">
          <div class="d-flex flex-column">
            <v-card-subtitle class="pb-0 pl-0"> </v-card-subtitle>
            <p>{{ item.name }} details</p>
          </div>
        </v-card-title>
        <v-list>
          <v-list-item>
            <v-simple-table class="pr-10">
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td>Full Name:</td>
                    <td>{{ item.name }}</td>
                  </tr>
                  <tr>
                    <td>Phone Number:</td>
                    <td>{{ item.phone }}</td>
                  </tr>
                  <tr>
                    <td>Registration Type:</td>
                    <td>{{ item.registration_type }}</td>
                  </tr>
                  <tr>
                    <td>Account Status:</td>
                    <td
                      :style="[
                        item.status === 'rejected'
                          ? {
                              color: '#da371e',
                              'font-weight': '600 !important',
                            }
                          : {
                              color: '#699a4c',
                              'font-weight': '600 !important',
                            },
                      ]"
                    >
                      {{ item.status }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-simple-table class="pr-10">
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td>Business Name:</td>
                    <td>{{ item.business_name }}</td>
                  </tr>
                  <tr>
                    <td>Business Location:</td>
                    <td>{{ item.business_location }}</td>
                  </tr>
                  <tr>
                    <td>Distributor:</td>
                    <td>{{ item.distributor }}</td>
                  </tr>
                  <tr>
                    <td>Admin Comment:</td>
                    <td>{{ item.comment }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-list-item>
        </v-list>
      </v-card>
      <VetAccount
        :account="currentAccount"
        :display="leadVettingModalShow"
        @close-modal="leadVettingModalShow = false"
      />
      <SendOnboardingLink
        :account="currentAccount"
        :display="leadsendLinkModalShow"
        @close-modal="leadsendLinkModalShow = false"
      />
      <CompleteAccount
        :account="currentAccount"
        :display="completeLeadModalShow"
        @close-modal="completeLeadModalShow = false"
      />
    </v-layout>
  </div>
</template>

<script>
import VetAccount from './VetAccount.vue';
import SendOnboardingLink from './SendOnboardingLink.vue';
import CompleteAccount from './CompleteAccount.vue';
import PageHeader from '../../components/PageHeader/PageHeader.vue';

export default {
  name: 'LeadDetails',
  components: {
    PageHeader,
    VetAccount,
    SendOnboardingLink,
    CompleteAccount,
  },
  data() {
    return {
      item: this.$route.params.dataToDisplay,
      items: [
        { text: 'Leads', disabled: false, href: '#/leads' },
        {
          text: this.$route.params.dataToDisplay.name,
          disabled: true,
          href: '',
        },
      ],
      expand: false,
      leadVettingModalShow: false,
      leadsendLinkModalShow: false,
      completeLeadModalShow: false,
      currentAccount: '',
      showReconDialog: false,
      paymentProp: null,
    };
  },
  methods: {
    openVettingModal() {
      this.expand = !this.expand;
      this.currentAccount = this.item;
      this.leadVettingModalShow = true;
    },
    openOnboardingLinkModal() {
      this.expand = !this.expand;
      this.currentAccount = this.item;
      this.leadsendLinkModalShow = true;
    },
    openCompletenModal() {
      this.expand = !this.expand;
      this.currentAccount = this.item;
      this.completeLeadModalShow = true;
    },
  },
};
</script>
