<template>
  <Modal
    :title="title"
    :openModal="display"
    v-on:close-modal="$emit('close-modal')"
  >
    <template v-slot:content>
      <form
        action=""
        @submit.prevent="deleteProduct"
        id="deleteProductForm"
      ></form>
    </template>
    <template v-slot:footer>
      <Button
        type="submit"
        form="deleteProductForm"
        styling="primary"
        text="Delete"
      ></Button>
    </template>
  </Modal>
</template>

<script>
import Modal from '../../components/Modal/Modal.vue';
import Button from '../../components/Button/Button.vue';

export default {
  name: 'DeleteProduct',
  emits: ['close-modal'],
  props: ['product', 'display'],
  components: {
    Modal,
    Button,
  },
  data() {
    return {
      title: 'Delete Product',
    };
  },
  methods: {
    deleteProduct() {
      const { ref } = this.product;
      this.$store
        .dispatch(`products/deleteProduct`, {
          ref,
        })
        .then(() => {
          this.$emit('close-modal');
        })
        .catch((err) => {
          this.formErrors = err;
        });
    },
    checkProduct() {
      if (this.product !== null) {
        this.title = `Delete ${this.product.name}?`;
      }
    },
  },
  watch: {
    product: {
      handler() {
        this.checkProduct();
      },
      deep: true,
    },
  },
  mounted() {
    if (!this.product) this.$emit('close-modal');
    else this.checkProduct();
  },
};
</script>
