<template>
  <div class="text-center">
    <v-dialog v-model="showDialog" width="500">
      <v-card>
        <v-card-text class="pa-6">
          <h3>
            {{ text }}
          </h3>
        </v-card-text>
        <v-card-actions>
          <v-btn color="danger" text @click="dialogDecision(false)">
            Close
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="handleClick"> I accept </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: 'SimpleDialog',
  emits: ['dialog-decision'],
  methods: {
    handleClick() {
      this.$confirm({
        message: `Are you sure you want to continue with this action?`,
        button: {
          no: 'Cancel',
          yes: 'Yes',
        },
        callback: (confirm) => {
          if (confirm) {
            this.dialogDecision(true);
          }
        },
      });
    },
    dialogDecision(decision) {
      this.$emit('dialog-decision', decision);
      this.showDialog = false;
    },
  },
  computed: {
    showDialog: {
      get() {
        return this.open;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },
  props: {
    open: { type: Boolean, required: true },
    text: { type: String, required: true },
  },
};
</script>

<style lang="scss">
.v-dialog {
  width: auto;
}
.v-card__actions {
  margin-right: 1.5rem;
  padding-bottom: 1.5rem;
  border-radius: 0 0 24px 24px;
  .v-btn.v-btn {
    padding: 0 16px;
  }
}
.v-card__title {
  border-bottom: 1px solid;
  border-color: $gray;
}
.v-card__text {
  color: unset !important;
}

.v-dialog {
  border-radius: 24px;
}
</style>
