import Vue from 'vue';

const makeSignupRequest = (data) => Vue.http.post('/api/v2/users', data);

const getUserGroups = (page = 1, searchTerm = '') => {
  let url = `/api/v2/groups?page=${page}`;
  if (searchTerm) {
    url = `${url}`;
  }
  return Vue.http.get(url);
};

export { makeSignupRequest, getUserGroups };
