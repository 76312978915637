<template>
  <div class="width100">
    <PageHeader
      headingText="Borrower Profiles"
      :breadcrumbs="breadcrumbs"
    ></PageHeader>
    <v-layout justify-center flex-column align-stretch class="ma-4">
      <div class="col-12">
        <v-card class="pl-3">
          <Loan
            v-if="currentBorrower && currentBorrowerLoan"
            v-bind:title="title"
            v-bind:loanData="currentBorrowerLoan"
          ></Loan>
        </v-card>
      </div>
    </v-layout>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PageHeader from '../../components/PageHeader/PageHeader.vue';
import Loan from './Loan.vue';

export default {
  name: 'BorrowerLoan',
  components: {
    PageHeader,
    Loan,
  },
  data() {
    return {
      title: 'Loan',
      tab: null,
      selectedComponent: 'account-details',
      loanRef: this.$route.params.loanRef,
      breadcrumbs: [
        { text: 'Borrower', disabled: false, href: '#/accounts' },
        {
          text: this.$route.params.ref,
          disabled: false,
          href: `#/borrowerprofile/${this.$route.params.ref}`,
        },
        {
          text: `Loan ${this.$route.params.loanRef}`,
          disabled: true,
          href: '',
        },
      ],
    };
  },
  beforeMount() {
    this.fetchBorrowerLoan(this.loanRef);
  },
  updated() {
    if (this.loanRef) {
      this.title = `Loan ${this.loanRef}`;
    }
  },
  methods: {
    ...mapActions('borrowers', [
      'fetchBorrowerLoan',
      'resetCurrentBorrowerLoan',
    ]),
    setSelectedComponent(component) {
      this.selectedComponent = component;
    },
  },
  computed: {
    ...mapGetters('auth', ['isAdmin']),
    ...mapGetters('borrowers', ['currentBorrowerLoan']),
    ...mapGetters('borrowers', ['currentBorrower']),
  },
};
</script>
