<template>
  <div class="width100">
    <PageHeader headingText="Borrower Profiles"></PageHeader>
    <v-layout justify-center flex-column align-stretch class="ma-4">
      <!-- SEARCH FIELDS -->
      <div class="col-12 ma-auto">
        <AdvancedAccountSearch
          v-if="showAdvancedSearch"
          v-on:search="searchBorrower"
          v-on:clear="onClearClicked"
        ></AdvancedAccountSearch>
        <div class="d-flex justify-space-between flex-wrap col-12 parent">
          <v-btn
            v-bind:class="{ active: showAdvancedSearch }"
            class="no-uppercase ma-3"
            text
            @click="toggleAdvancedSearch"
          >
            Advanced Search
            <v-icon dark right> mdi-filter-outline</v-icon>
          </v-btn>
          <v-text-field
            v-model="searchTerm"
            placeholder="Search by ref, name or phone number"
            append-outer-icon="search"
            clearable
            @keydown.enter="searchBorrower"
            @click:append-outer="searchBorrower"
            @click:clear="onClearClicked"
          >
          </v-text-field>
        </div>
      </div>

      <!-- ACCOUNTS LIST -->
      <Table
        :tableData="borrowers.results"
        :options.sync="options"
        :serverItemsLength="borrowers.count"
        :itemsPerPage="borrowers.per_page"
        :headers="headers"
        v-on:updated-options="fetchActiveBorrowersWithPage"
        class="ma-auto"
      >
        <template v-slot:actions="slotProps">
          <v-btn
            class="no-uppercase"
            @click="openAccountDetails(slotProps.item)"
            text
          >
            View Details
          </v-btn>
        </template>
      </Table>
    </v-layout>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PageHeader from '../../components/PageHeader/PageHeader.vue';
import Table from '../../components/Table/Table.vue';
import AdvancedAccountSearch from './AdvancedAccountSearch.vue';

export default {
  name: 'Accounts',
  components: {
    PageHeader,
    Table,
    AdvancedAccountSearch,
  },
  data() {
    return {
      showAdvancedSearch: false,
      options: {},
      searchTerm: '',
      headers: [
        {
          value: 'ref',
          text: 'Account Ref',
        },
        {
          value: 'name',
          text: 'Account name',
        },
        {
          value: 'phone',
          text: 'Phone Number',
        },
        {
          value: 'partner',
          text: 'Distributor',
          sortable: true,
          searchable: true,
        },
        {
          value: 'loan_limit',
          text: 'Loan Limit',
        },
        {
          value: 'created_at',
          text: 'Created On',
        },
        {
          value: 'action',
          sortable: true,
          searchable: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters('auth', ['isAdmin']),
    ...mapGetters('borrowers', ['borrowers']),
  },
  methods: {
    ...mapActions('borrowers', ['fetchBorrowers']),

    toggleAdvancedSearch() {
      this.showAdvancedSearch = !this.showAdvancedSearch;
    },

    async fetchActiveBorrowersWithPage(options) {
      const status = 'active';
      const { page } = options;
      await this.fetchBorrowers({ status, page, searchTerm: this.searchTerm });
    },

    async searchBorrower(searchParams) {
      const status = searchParams.status || '';
      await this.fetchBorrowers({
        status,
        searchTerm: this.searchTerm,
        searchParams,
      });
    },

    async onClearClicked() {
      await this.fetchBorrowers();
    },

    openAccountDetails(item) {
      this.$router.push({
        path: `/borrowerprofile/${item.ref}`,
        params: { ref: item.ref },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.v-input {
  padding-left: 40%;
  margin-top: 0;
}
.v-application p {
  padding-left: 50px;
}
.no-uppercase {
  text-transform: unset !important;
}
.left {
  padding: 1em;
}
.v-btn {
  color: #699a4c;
}
.right {
  padding: 1em;
}
.parent {
  padding: 0 12px;
  background-color: #fff;
  border-radius: 5px;
}
.active {
  background-color: #699a4c25;
}

#parent4 {
  justify-content: space-between;
}
</style>
