<template>
  <Modal
    :title="title"
    :openModal="display"
    v-on:close-modal="$emit('close-modal')"
  >
    <template v-slot:content>
      <form
        action=""
        @submit.prevent="handleClick"
        id="deactivateDSRForm"
      ></form>
    </template>
    <template v-slot:footer>
      <Button
        type="submit"
        v-on:button-clicked="deactivateDSR"
        styling="primary"
        v-bind:text="isDeactivateAction ? 'Deactivate' :'Activate',"
      ></Button>
    </template>
  </Modal>
</template>

<script>
import Modal from '../../components/Modal/Modal.vue';
import Button from '../../components/Button/Button.vue';

export default {
  name: 'ActivateORDeactivateDSR',
  emits: ['close-modal'],
  props: ['dsr', 'display', 'isDeactivateAction'],
  components: {
    Modal,
    Button,
  },
  data() {
    return {
      title: 'Activate/Deactivate DSR',
      is_active: true,
      isLoading: false,
      action: '',
      buttonText: '',
    };
  },
  computed: {
    isDisabled() {
      return this.isLoading;
    },
  },
  methods: {
    handleClick() {
      this.$confirm({
        message: `Are you sure you want to deactivate this DSR?`,
        button: {
          no: 'Cancel',
          yes: 'Yes',
        },
        callback: (confirm) => {
          if (confirm) {
            this.deactivateDSR();
          }
        },
      });
    },
    deactivateDSR() {
      const { id } = this.dsr;
      this.isLoading = true;
      const action = this.isDeactivateAction ? 'deactivate' : 'activate';
      this.$store
        .dispatch(`dsrs/deactivateOrActivateDSR`, {
          id,
          action,
        })
        .then(() => {
          this.isLoading = false;
          this.$emit('close-modal');
        })
        .catch((err) => {
          this.isLoading = false;
          this.formErrors = err;
        });
    },
    checkDSR() {
      if (this.dsr !== null) {
        this.title = this.isDeactivateAction
          ? `Deactivate ${this.dsr.first_name} (${this.dsr.id}) DSR?`
          : `Activate ${this.dsr.first_name} (${this.dsr.id}) DSR?`;
      }
    },
  },
  watch: {
    dsr: {
      handler() {
        this.checkDSR();
      },
      deep: true,
    },
  },
  mounted() {
    if (!this.dsr) this.$emit('close-modal');
    else this.checkDSR();
  },
};
</script>
