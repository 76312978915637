import Vue from 'vue';

const getKycs = (page = 1) => {
  return Vue.http.get(`/api/v2/kyc_items?page=${page}`);
};

const createOrEditKyc = (data, method, kycCode = null) =>
  Vue.http({
    url: `/api/v2/kyc_items${kycCode ? `/${kycCode}` : ''}`,
    method,
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

const deleteKyc = (kycCode) => Vue.http.delete(`/api/v2/kyc_items/${kycCode}`);

export { getKycs, createOrEditKyc, deleteKyc };
