const formatCurrency = (amount) => {
  if (amount === undefined) return amount;
  if (typeof amount === 'number') {
    return `KSH ${amount.toLocaleString('en-US')}`;
  }
  return `KSH ${parseInt(amount, 10).toLocaleString('en-US')}`;
};

const unformatCurrency = (formattedCurrency) => {
  if (!formattedCurrency) return formattedCurrency;
  let unformattedCurrency = formattedCurrency.replaceAll(',', '');
  unformattedCurrency = unformattedCurrency.includes('KSH')
    ? unformattedCurrency.slice(3)
    : unformattedCurrency;
  return parseInt(unformattedCurrency, 10);
};

// eslint-disable-next-line import/prefer-default-export
export { formatCurrency, unformatCurrency };
