<template>
  <div class="width100">
    <div class="d-flex justify-space-between align-center pb-2">
      <h4 class="pl-4">{{ heading }}</h4>
      <Button
        v-if="showAddButton"
        v-on:button-clicked="openCommentForm"
        :text="btnText"
      ></Button>
    </div>
    <v-data-table
      :headers="headers"
      :items="comments"
      class="elevation-0 py-3 border"
      @click:row="handleRowClick"
    >
    </v-data-table>
  </div>
</template>

<script>
import Button from '../../components/Button/Button.vue';

export default {
  name: 'CommentsTable',
  components: { Button },
  props: ['comments', 'commentType', 'heading', 'btnText', 'showAddButton'],
  data() {
    return {
      headers: [],
      commentProp: null,
    };
  },
  methods: {
    handleRowClick(item) {
      this.$emit('open-comment-detail', item);
    },
    openCommentForm() {
      this.$emit('open-comment-detail');
    },
    setTableData() {
      let headers = [
        {
          value: 'created_at',
          text: 'Date',
        },
        {
          value: 'comment',
          text: this.commentType === 'feedback' ? 'Outcome' : 'Comment',
        },
        {
          value: 'created_by',
          text: 'Created By',
        },
        {
          value: 'updated_by',
          text: 'Updated By',
        },
        {
          value: 'updated_at',
          text: 'Last Updated On',
        },
      ];
      if (this.commentType !== 'feedback') {
        headers.push({
          value: 'link',
          text: 'External Link',
        });
      }
      if (this.commentType === 'feedback') {
        headers = [
          ...headers,
          {
            value: 'contact_method',
            text: 'Contact Method',
          },
          {
            value: 'ptp_booked',
            text: 'PTP Booked',
          },
          {
            value: 'next_contact_date',
            text: 'Next Contact Date',
          },
        ];
      }
      this.headers = headers;
    },
  },
  beforeMount() {
    this.setTableData();
  },
};
</script>

<style scoped lang="scss">
.border {
  box-shadow: 6px 6px 18px rgba(0, 0, 0, 0.1) !important;
  border-radius: 4px;
  padding: 0px 10px;
  widows: 95%;
  margin: 0 auto 20px;
}
</style>
