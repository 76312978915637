<template>
  <div class="d-flex flex-column justify-center align-center mb-6">
    <h1>Error 404</h1>
    <h4>We could'nt find what you were looking for =(</h4>
    <br />
    <router-link to="/">Return Home</router-link>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
};
</script>

<style lang="scss" scoped>
div {
  height: 100vh;
}
h2 {
  display: block;
}
</style>
