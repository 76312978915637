<template>
  <div class="width100">
    <PageHeader
      headingText="Applications"
      btnText="Apply for a Loan"
      v-on:action-button-clicked="openCreateApplication()"
    ></PageHeader>
    <v-layout justify-center flex-column align-stretch class="ma-4">
      <v-text-field
        v-model="searchTerm"
        placeholder="Enter a search term"
        append-outer-icon="search"
        clearable
        @keydown.enter="searchApplication"
        @click:append-outer="searchApplication"
        @click:clear="onClearClicked"
      >
      </v-text-field>
      <DataFilter
        v-on:filter-data="this.changeFilterData"
        :schema="this.applicationFilterSchema"
        :data="this.applications.results"
        :model="this.filterModel"
        :show.sync="this.showFilter"
        class="ma-auto"
      ></DataFilter>

      <Table
        :tableData="this.applications.results"
        :options.sync="options"
        :serverItemsLength="this.applications.count"
        :itemsPerPage="this.applications.per_page"
        :headers="this.headers"
        :filterDisplayed.sync="this.showFilter"
        v-on:toggle-filter="this.toggleFilter"
        v-on:updated-options="fetchApplicationsWithPage"
        class="ma-auto"
      >
        <template
          v-slot:actions="slotProps"
          v-bind:fetchIcon="this.fetchIcon"
          v-bind:fetchAction="this.fetchAction"
        >
          <Action
            :icon="fetchIcon(slotProps.item.status)"
            :action="fetchAction(slotProps.item)"
            :item="slotProps.item"
            v-if="isAdmin"
          />
          <Action
            v-if="isSuperUser && slotProps.item.status === 'approved'"
            :item="slotProps.item"
            :action="vetApplication"
            :icon="'mdi-arrow-u-left-top'"
          />
        </template>
      </Table>

      <DisburseLoan
        :loanRef="currentLoanRef"
        :partnerName="currentPartnerName"
        :display="loanDisbursalModalShow"
        :interestRate="currentInterestRate"
        :loanPeriod="currentLoanPeriod"
        :disbursalCode="currentDisbursalCode"
        :principal="currentAmount"
        :vsmTillNumber="currentTillNumber"
        @close-modal="loanDisbursalModalShow = false"
      />

      <VetApplication
        v-if="this.isAdmin"
        :display="vettingApplicationModalShow"
        :application="vettingApplicationData"
        v-on:close-modal="vettingApplicationModalShow = false"
      ></VetApplication>

      <CreateApplication
        :display="createApplicationModalShow"
        :application="createApplicationData"
        v-on:close-modal="createApplicationModalShow = false"
      ></CreateApplication>

      <LoanAction
        v-bind:methodToDispatch="currentMethod"
        v-bind:loanAction="currentAction"
        v-bind:loanRef="currentLoanRef"
        v-bind:actionMessage="actionMessage"
        :visible="loanActionModalShow"
        @close="loanActionModalShow = false"
        @success="loanActionModalShow = false"
        @error="(payload) => (formErrors = payload)"
      />
    </v-layout>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Action from '../../components/Action/Action.vue';
import LoanAction from '../Loans/LoanAction.vue';
import Table from '../../components/Table/Table.vue';
import DataFilter from '../../components/DataFilter/DataFilter.vue';
import PageHeader from '../../components/PageHeader/PageHeader.vue';
import filterSchema from './filterSchema';
import VetApplication from './VetApplication.vue';
import CreateApplication from './CreateApplication.vue';
import DisburseLoan from './DisburseLoan.vue';

export default {
  name: 'Applications',
  components: {
    Action,
    Table,
    DataFilter,
    PageHeader,
    VetApplication,
    CreateApplication,
    LoanAction,
    DisburseLoan,
  },
  data() {
    const isAdmin = this.$store.getters['auth/isAdmin'];
    const headers = [
      {
        value: 'ref',
        text: 'Loan Ref',
        searchable: true,
      },
      {
        value: 'status',
        text: 'Status',
        searchable: true,
        sortable: true,
      },
      {
        value: 'borrower',
        text: 'Borrower',
      },
      {
        value: 'applicant',
        text: 'Applicant',
      },
      {
        value: 'principal',
        text: 'Amount',
      },
      {
        value: 'interest_rate',
        text: 'Interest Rate',
      },
      {
        value: 'partnerName',
        text: 'Partner',
        searchable: true,
        sortable: true,
        visible: isAdmin,
      },
    ];
    if (isAdmin) {
      headers.push({
        value: 'action',
        text: 'Actions',
      });
    }
    return {
      filteredApplications: [],
      applicationFilterSchema: filterSchema,
      filterModel: { status: '' },
      vettingApplicationData: null,
      vettingApplicationModalShow: false,
      createApplicationModalShow: false,
      loanDisbursalModalShow: false,
      createApplicationData: null,
      showFilter: false,
      currentAction: '',
      currentLoanRef: '',
      currentPartnerName: '',
      currentMethod: '',
      currentLoanPeriod: '',
      currentInterestRate: '',
      currentDisbursalCode: '',
      currentTillNumber: '',
      currentAmount: '',
      loanActionModalShow: false,
      actionMessage: '',
      withdrawIcon: 'mdi-account-remove',
      validStatusBeforeWithdraw: ['approved', 'pending'],
      options: {},
      searchTerm: '',
      headers,
    };
  },
  computed: {
    ...mapGetters('auth', ['isAdmin', 'isSuperUser']),
    ...mapGetters('applications', ['applications']),
  },
  methods: {
    ...mapActions('applications', ['fetchApplications']),

    async fetchApplicationsWithPage(options) {
      const { page } = options;
      await this.fetchApplications({ page, searchTerm: this.searchTerm });
    },
    async searchApplication() {
      await this.fetchApplications({ searchTerm: this.searchTerm });
    },

    async onClearClicked() {
      await this.fetchApplications({ page: 1 });
    },

    changeFilterData(newData) {
      this.filteredApplications = newData;
    },
    toggleFilter() {
      this.showFilter = !this.showFilter;
    },
    vetApplication(application) {
      this.vettingApplicationModalShow = true;
      this.vettingApplicationData = application;
      // save current loan application data
      this.$store.dispatch(`applications/saveCurrentLoanApplication`, {
        data: this.vettingApplicationData,
      });
    },
    openCreateApplication() {
      this.createApplicationModalShow = true;
      this.createApplicationData = {};
    },
    openDisburseLoanModal(application) {
      this.loanDisbursalModalShow = true;
      this.currentLoanRef = application.ref;
      this.currentPartnerName = application.partnerName;
      this.currentInterestRate = application.interest_rate;
      this.currentLoanPeriod = application.loan_period;
      this.currentDisbursalCode = application.disbursal_code;
      this.currentAmount = application.principal;
      this.currentTillNumber = application.disbursement_account;
    },
    vetOrDisburse() {
      return this.openDisburseLoanModal;
    },
    fetchIcon(status) {
      // help me look for dope icons here
      switch (status) {
        case 'pending':
          return 'mdi-check';
        case 'approved':
          return 'attach_money';
        case 'rejected':
        default:
          return 'mdi-eye';
      }
    },
    fetchAction(application) {
      const { status } = application;
      switch (status) {
        case 'pending':
          return this.vetApplication;
        case 'rejected':
        case 'disbursed':
        default:
          return this.vetOrDisburse();
      }
    },
  },
};
</script>

<style scoped>
.v-input {
  padding-left: 16px;
}
.v-text-field {
  width: 50%;
}
</style>
