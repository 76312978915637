import Vue from 'vue';
import VueRouter from 'vue-router';

import store from './store';
import Login from './pages/Login/Login.vue';
import Logout from './pages/Logout/Logout.vue';
import NotFound from './pages/NotFound/NotFound.vue';
import ResetPassword from './pages/ResetPassword/ResetPassword.vue';
import ChangePassword from './pages/ChangePassword/ChangePassword.vue';
import Loans from './pages/Loans/Loans.vue';
import SingleLoan from './pages/Loans/SingleLoan.vue';
import LoanTermsPDF from './pages/Loans/LoanTermsPDF.vue';
import Borrowers from './pages/Borrowers/Borrowers.vue';
import DSRs from './pages/DSRs/DSRs.vue';
import DSRDetailPage from './pages/DSRs/DSRDetailPage.vue';
import BorrowerDetailPage from './pages/Borrowers/BorrowerDetailPage.vue';
import Applications from './pages/Applications/ApplicationsMainPage.vue';
import ProductManagement from './pages/ProductsManagement/Products.vue';
import ProductDetailsPage from './pages/ProductsManagement/ProductDetailsPage.vue';
import KycManagement from './pages/KycManagement/Kycs.vue';
import HealthPage from './pages/Health.vue';
import MpesaPayments from './pages/MpesaPayments/MpesaPayments.vue';
import MpesaPaymentDetails from './pages/MpesaPayments/MpesaPaymentDetails.vue';
import Leads from './pages/Leads/Leads.vue';
import LeadDetails from './pages/Leads/LeadDetails.vue';
import Accounts from './pages/BorrowerProfile/AccountPage.vue';
import BorrowerProfile from './pages/BorrowerProfile/BorrowerProfile.vue';
import BorrowerLoan from './pages/BorrowerProfile/BorrowerLoan.vue';
import SendTemplateSms from './pages/SmsTemplates/SendTemplateSms/Stepper.vue';
import TemplateDetail from './pages/SmsTemplates/TemplateDetail.vue';
import TemplateEditor from './pages/SmsTemplates/TemplateEditor.vue';
import DmsDataRefresh from './pages/DmsDataRefresh/DmsDataRefresh.vue';
import SMSManagement from './pages/SmsTemplates/SmsManagement.vue';

Vue.use(VueRouter);

const Router = new VueRouter({
  routes: [
    { path: '/' },
    {
      path: '/login',
      name: 'login',
      component: Login,
    },
    {
      path: '/logout',
      name: 'logout',
      component: Logout,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/reset-password',
      name: 'resetPassword',
      component: ResetPassword,
    },
    {
      path: '/change-password',
      name: 'changePassword',
      component: ChangePassword,
    },
    {
      path: '/leads',
      name: 'leads',
      component: Leads,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/leaddetails',
      name: 'leadDetails',
      component: LeadDetails,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/loan/:ref',
      name: 'loan',
      component: SingleLoan,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/loanterms/:ref',
      name: 'loanTerms',
      component: LoanTermsPDF,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/loans',
      name: 'loans',
      component: Loans,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/borrowers',
      name: 'borrowers',
      component: Borrowers,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/dsrs',
      name: 'dsrs',
      component: DSRs,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/dsrs/:mode/:ref',
      name: 'dsrsdetails',
      component: DSRDetailPage,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/borrowers/:mode',
      name: 'borrowerdetails',
      component: BorrowerDetailPage,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/borrowers/:mode/:ref',
      name: 'borrowerdetailspage',
      component: BorrowerDetailPage,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/applications',
      name: 'applications',
      component: Applications,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/mpesarepayments',
      name: 'mpesarepayments',
      component: MpesaPayments,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/mpesapaymentsdetails',
      name: 'mpesapaymentsdetails',
      component: MpesaPaymentDetails,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/products',
      name: 'products',
      component: ProductManagement,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/details',
      name: 'details',
      component: ProductDetailsPage,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/kycs',
      name: 'kycs',
      component: KycManagement,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/dmsdatarefresh',
      name: 'dmsdatarefresh',
      component: DmsDataRefresh,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/404',
      name: '404',
      component: NotFound,
      props: true,
    },
    {
      path: '/health',
      name: 'Health',
      component: HealthPage,
    },
    {
      path: '*',
      redirect: { name: '404', params: { resource: 'page' } },
    },
    {
      path: '/accounts',
      name: 'accounts',
      component: Accounts,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/borrowerprofile/:ref',
      name: 'borrowerprofile',
      component: BorrowerProfile,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/borrowerprofile/:ref/loan/:loanRef',
      name: 'borrowerloan',
      component: BorrowerLoan,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/smstemplates',
      name: 'smstemplates',
      component: SMSManagement,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/smstemplates/view/:templateId',
      name: 'templateDetail',
      component: TemplateDetail,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/smstemplates/send/:templateId',
      name: 'sendTemplateSms',
      component: SendTemplateSms,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/smstemplates/create',
      name: 'createTemplate',
      component: TemplateEditor,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/smstemplates/edit/:templateId',
      name: 'editTemplateSms',
      component: TemplateEditor,
      meta: {
        requiresAuth: true,
      },
    },
  ],
});

Router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters['auth/isLoggedIn'];
  const isAdmin = store.getters['auth/isAdmin'];
  const isCrmUser = store.getters['auth/isCrmUser'];
  if (['/'].includes(to.path)) {
    if (isAuthenticated && isAdmin) {
      next('/applications');
    } else if (isAuthenticated && isCrmUser) {
      next('/accounts');
    } else if (!isAuthenticated) {
      next('/login');
    }
    return;
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (isAuthenticated) {
      next();
      return;
    }
    next('/login');
  } else {
    next();
  }
});

export default Router;
