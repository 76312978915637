<template>
  <Modal
    :title="title"
    :openModal="display"
    v-on:close-modal="$emit('close-modal')"
  >
    <template v-slot:content>
      <div v-if="borrower">
        <div class="card-content">
          <div class="content">
            <h2 class="ma-3 mt-8">Borrower Details</h2>
            <v-simple-table>
              <tbody>
                <tr>
                  <td><b>Name: </b></td>
                  <td>{{ borrower.name }}</td>
                </tr>
                <tr>
                  <td><b>Ref: </b></td>
                  <td>{{ borrower.ref }}</td>
                </tr>
                <tr>
                  <td><b>Loan Limit: </b></td>
                  <td>{{ borrower.loan_limit }}</td>
                </tr>
                <tr>
                  <td><b>Maximum Loan Duration: </b></td>
                  <td>
                    {{ borrower.has_max_loan_duration ? '30' : '15' }} days
                  </td>
                </tr>
                <tr>
                  <td><b>Partner Defined ID: </b></td>
                  <td>{{ borrower.partner_defined_id }}</td>
                </tr>
                <tr>
                  <td><b>Partner: </b></td>
                  <td>{{ borrower.partner }}</td>
                </tr>
                <tr>
                  <td><b>Type: </b></td>
                  <td>{{ borrower.borrower_type }}</td>
                </tr>
                <tr>
                  <td><b>Tax Number: </b></td>
                  <td>{{ borrower.tax_number }}</td>
                </tr>
                <tr>
                  <td><b>Tax Doc Url: </b></td>
                  <td>{{ borrower.tax_doc_url }}</td>
                </tr>
                <tr>
                  <td><b>Status: </b></td>
                  <td>{{ borrower.status }}</td>
                </tr>
                <tr>
                  <td><b>Email: </b></td>
                  <td>{{ borrower.contact_email }}</td>
                </tr>
                <tr>
                  <td><b>Phone: </b></td>
                  <td>{{ borrower.phone }}</td>
                </tr>
                <tr>
                  <td><b>Director: </b></td>
                  <td v-if="borrower.directors">
                    {{ borrower.directors }}
                  </td>
                  <td v-else>-</td>
                </tr>
                <tr>
                  <td><b>Organization info: </b></td>
                  <td v-if="borrower.org_info">
                    {{ borrower.org_info }}
                  </td>
                  <td v-else>-</td>
                </tr>
                <tr>
                  <td><b>Borrower users: </b></td>
                  <td v-if="borrower.borrower_users">
                    {{ borrower.borrower_users }}
                  </td>
                  <td v-else>-</td>
                </tr>
                <tr>
                  <td><b>Other Information: </b></td>
                  <td v-if="borrower.metadata">
                    <a
                      v-if="borrower.metadata.liquor_license"
                      :href="borrower.metadata.liquor_license"
                      target="_blank"
                      >Liquor License</a
                    >
                    &nbsp; &nbsp;
                    <a
                      v-if="borrower.metadata.business_permit"
                      :href="borrower.metadata.business_permit"
                      target="_blank"
                      >Business Permit</a
                    >
                  </td>
                  <td v-else>-</td>
                </tr>
                <tr>
                  <td><b>Admin comments: </b></td>
                  <td>{{ borrower.comment }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from '../../components/Modal/Modal.vue';

export default {
  name: 'BorrowerDetails',
  emits: ['close-modal'],
  props: ['borrower', 'display'],
  components: {
    Modal,
  },
  data() {
    return {
      borrowerInfo: null,
      title: '',
    };
  },
  updated() {
    if (!this.borrower) this.$emit('close-modal');
    else {
      this.title = `Details for Borrower ${this.borrower.name}`;
    }
  },
};
</script>
