import Vue from 'vue';

const getSmses = (page = 1, searchTerm = '', searchParams = {}) => {
  let url = `/api/v2/sms_delivery?page=${page}`;
  if (searchTerm) {
    url = `${url}&search=${searchTerm}`;
  }
  if (searchParams.loan) {
    url += `&loan=${searchParams.loan}`;
  }
  if (searchParams.borrower) {
    url += `&borrower=${searchParams.borrower}`;
  }

  return Vue.http.get(url);
};

const sendSingleSms = (payload) =>
  Vue.http.post(`/api/v2/sms_delivery/send_sms`, payload);

const sendBulkSms = (payload) =>
  Vue.http.post(`/api/v2/sms_delivery/send_bulk_sms`, payload);

const scheduleBulkSms = (payload) =>
  Vue.http.post(`/api/v2/scheduledsms`, payload);

// eslint-disable-next-line import/prefer-default-export
export { getSmses, sendSingleSms, sendBulkSms, scheduleBulkSms };
