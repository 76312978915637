import { getUserGroups } from '../api/users.api';

const state = () => ({
  usergroups: [],
});

const getters = {
  usergroups: (state) => state.usergroups,
};

const actions = {
  async fetchUserGroups({ commit }, payload = {}) {
    const { page, searchTerm } = payload;
    // commit('initial_state');
    try {
      commit('common/data_requested', null, { root: true });
      const response = await getUserGroups(page, searchTerm);
      commit('set_user_groups', response.data);
    } catch (error) {
      commit(
        'common/error_snackbar',
        `Failed to fetch user groups. Please try again later.\n ${error}`,
        {
          root: true,
        },
      );
    } finally {
      commit('common/response_received', null, { root: true });
    }
  },
};

const mutations = {
  intial_state(state) {
    state.usergroups = [];
  },
  set_user_groups(state, data) {
    state.usergroups = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
