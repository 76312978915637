<template>
  <Modal
    :title="title"
    :openModal="display"
    v-on:close-modal="$emit('close-modal')"
  >
    <template v-slot:content>
      <form action="" @submit.prevent="deleteKycItem" id="deleteKycForm">
        <ErrorAlert v-if="formErrors.length > 0" :errors="formErrors" sticky />
        <vue-form-generator
          :schema="formSchema"
          :model="formModel"
        ></vue-form-generator>
      </form>
    </template>
    <template v-slot:footer>
      <Button
        type="submit"
        form="deleteKycForm"
        styling="primary"
        text="Delete"
      ></Button>
    </template>
  </Modal>
</template>

<script>
import Modal from '../../components/Modal/Modal.vue';
import Button from '../../components/Button/Button.vue';
import ErrorAlert from '../../components/Alert/ErrorAlert.vue';

export default {
  name: 'DeleteKyc',
  emits: ['close-modal'],
  props: ['kycItem', 'display'],
  components: {
    Modal,
    Button,
    ErrorAlert,
  },
  data() {
    return {
      title: 'Delete Kyc Item',
      formModel: null,
      formSchema: null,
      formErrors: [],
    };
  },
  methods: {
    deleteKycItem() {
      const kycCode = this.kycItem.url
        ? this.kycItem.url.split('/').pop()
        : null;

      this.$store
        .dispatch(`kycs/deleteKyc`, {
          kycCode,
        })
        .then(() => {
          this.$emit('close-modal');
        })
        .catch((err) => {
          this.formErrors = err;
        });
    },
    checkKycItem() {
      if (this.kycItem !== null) {
        this.title = `Delete ${this.kycItem.value} item?`;
      }
    },
  },
  watch: {
    kycItem: {
      handler() {
        this.checkKycItem();
      },
      deep: true,
    },
  },
  mounted() {
    if (!this.kycItem) this.$emit('close-modal');
    else this.checkKycItem();
  },
};
</script>
