const extraChargeSchema = {
  fields: [
    {
      type: 'select',
      label: 'Charge Type',
      model: 'chargetype',
      values: ['Bank charges', 'Uber fee', 'Collection fee'],
      required: true,
      validator: ['required'],
    },
    {
      type: 'input',
      inputType: 'text',
      label: 'Amount',
      model: 'amount',
      required: true,
      max: 150,
      min: 5,
      validator: ['required'],
    },
  ],
};

const extraChargeModel = {
  amount: '',
  chargetype: '',
};

export { extraChargeSchema, extraChargeModel };
