const veewLoanDisbursalSchema = {
  fields: [
    {
      type: 'input',
      inputType: 'date',
      label: 'Please select the date of disbursement',
      model: 'date',
      required: true,
      format: 'YYYY-MM-DD',
      validator: ['required'],
    },
    {
      type: 'input',
      inputType: 'number',
      label: 'Applied Amount',
      model: 'principal',
      readonly: true,
      disabled: true,
    },
    {
      type: 'input',
      inputType: 'number',
      label: 'Approved Amount',
      model: 'approvedAmount',
      required: true,
    },
    {
      type: 'input',
      inputType: 'text',
      label: 'Interest Rate',
      model: 'interestRate',
      readonly: true,
      disabled: true,
    },
    {
      type: 'input',
      inputType: 'number',
      label: 'Enter agreed loan period (days)',
      model: 'loanPeriod',
      readonly: true,
      disabled: true,
    },
    {
      type: 'input',
      inputType: 'text',
      label: 'till number',
      placeholder:
        "Enter the vsm's till number that the money was disbursed to",
      model: 'vsmTillNumber',
      required: true,
    },
    {
      type: 'input',
      inputType: 'text',
      label: 'mpesa code',
      placeholder: 'Enter the mpesa code in the mpesa message',
      model: 'disbursalCode',
      required: true,
    },
  ],
};

const veewLoanDisbursalModel = {
  interestRate: '',
  vsmTillNumber: '',
  loanPeriod: '',
  principal: '',
  date: '',
  disbursalCode: '',
  approvedAmount: '',
};

export { veewLoanDisbursalSchema, veewLoanDisbursalModel };
