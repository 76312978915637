import {
  makeLoginRequest,
  getRefreshToken,
  verifyToken,
  makeLogoutRequest,
  getResetPasswordLink,
  changePassword,
  validatePasswordResetToken,
} from '../api/auth.api';
import { decrypt } from '../utils/cryptography';

const state = () => ({
  user: localStorage.getItem('user') || {},
  token: localStorage.getItem('token') || '',
  refresh: localStorage.getItem('refreshToken') || {},
  verify: localStorage.getItem('verifyToken') || {},
  refreshTokenExpiryDate: localStorage.getItem('refreshTokenExpiryDate') || {},
  groups: localStorage.getItem('groups')
    ? JSON.parse(localStorage.getItem('groups'))
    : [],
});

const getters = {
  isLoggedIn: (state) => state.token.length > 1,
  isAdmin: (state) =>
    !!state.groups.find((g) =>
      ['Admin Users', 'Super Admin Users'].includes(g),
    ),
  isSuperUser: (state) => state.groups.includes('Super Admin Users'),
  isCrmUser: (state) => state.groups.includes('Default Users'),
  isTokenValid: (state) => state.verify,
  getRefreshTokenExpiryDate: (state) => state.refreshTokenExpiryDate,
  getRefreshToken: (state) => state.refresh,
};

const actions = {
  async login({ commit }, userData) {
    try {
      const encryptedData = await makeLoginRequest(userData);
      const authToken = await decrypt(encryptedData.data);
      const obj = JSON.parse(authToken);
      const { access, refresh, groups } = obj;
      const response = {
        accessToken: access,
        refreshToken: refresh,
        groups,
      };
      const date = new Date();
      date.setDate(date.getDate() + 30);
      await commit('refresh_token_expiry_date', date);
      commit('login_success', response);
      commit('common/success_snackbar', 'Successfully Logged In!', {
        root: true,
      });
    } catch (error) {
      if (error.response.status === 403) {
        commit('common/error_snackbar', error.response.data, {
          root: true,
        });
      } else {
        commit(
          'common/error_snackbar',
          `Invalid credentials, please try again `,
          {
            root: true,
          },
        );
      }
    }
  },
  async validateResetToken({ commit }, token) {
    try {
      await validatePasswordResetToken(token);
    } catch (error) {
      commit('common/error_snackbar', 'Reset link invalid. Please try again', {
        root: true,
      });
      if (Array.isArray(error.response.data.errors.msg)) {
        throw error.response.data.errors.msg;
      } else {
        const err = [error.response.data.errors.msg];
        throw err;
      }
    }
  },
  async changePassword({ commit }, userData) {
    try {
      await changePassword(userData);
    } catch (error) {
      commit('logout');
      if (Array.isArray(error.response.data.errors.msg)) {
        throw error.response.data.errors.msg;
      } else {
        const err = [error.response.data.errors.msg];
        throw err;
      }
    }
    commit('logout');
    commit('common/success_snackbar', 'Your password has been reset', {
      root: true,
    });
  },
  async resetPassword({ commit }, userData) {
    try {
      await getResetPasswordLink(userData);
    } catch (error) {
      commit('logout');
      if (Array.isArray(error.response.data.errors.msg)) {
        throw error.response.data.errors.msg;
      } else {
        const err = [error.response.data.errors.msg];
        throw err;
      }
    }
  },
  async refreshAccessToken({ commit }) {
    try {
      const refreshTokenData = {
        refresh: localStorage.getItem('refreshToken'),
      };
      const responseRefresh = await getRefreshToken(refreshTokenData);
      commit('update_token', responseRefresh.data);
    } catch (error) {
      commit('logout');
    }
  },
  async verifyAccessToken({ commit }) {
    let responseRefresh;
    try {
      const accessTokenData = {
        token: localStorage.getItem('refreshToken'),
      };
      responseRefresh = await verifyToken(accessTokenData);
      commit('token_status', responseRefresh.data);
    } catch (error) {
      commit('logout');
    }
    return responseRefresh;
  },
  async verifyRefreshTokenValidity({ commit }) {
    let responseRefresh;
    try {
      const accessTokenData = {
        token: localStorage.getItem('refreshToken'),
      };
      responseRefresh = await verifyToken(accessTokenData);
      commit('token_status', responseRefresh.data);
    } catch (error) {
      commit('logout');
    }
    return responseRefresh.data;
  },
  async logout({ commit }) {
    try {
      const refresh = localStorage.getItem('refreshToken');
      if (refresh) {
        await makeLogoutRequest({ refresh });
      }
      commit('logout');
    } catch (error) {
      commit('logout');
    }
  },
};

const mutations = {
  intial_state(state) {
    state.allBorrowers = [];
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('groups');
    state.user = '';
    state.token = '';
    state.groups = [];
  },
  login_success(state, { accessToken, refreshToken, groups }) {
    localStorage.setItem('token', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
    localStorage.setItem('groups', JSON.stringify(groups));
    state.token = accessToken;
    state.refresh = refreshToken;
    state.groups = groups;
  },
  update_token(state, { access, refresh }) {
    localStorage.setItem('token', access);
    localStorage.setItem('refreshToken', refresh);
    state.token = access;
    state.refresh = refresh;
  },
  refresh_token_expiry_date(state, date) {
    localStorage.setItem('refreshTokenExpiryDate', date);
    state.refreshTokenExpiryDate = date;
  },
  token_status(state, validityStatus) {
    state.verify = validityStatus;
    localStorage.setItem('refreshTokenValidity', validityStatus);
  },
  logout() {
    this.dispatch('common/clearState', { root: true });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
