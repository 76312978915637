<template>
  <Modal
    :openModal="display"
    v-model="show"
    v-on:close-modal="$emit('close-modal')"
    :title="title"
    class="col-6"
  >
    <template v-slot:content>
      <form action="" @submit.prevent="handleClick" id="repaymentForm">
        <ErrorAlert
          v-if="formErrors ? formErrors.length > 0 : false"
          :errors="formErrors"
          sticky
        />
        <vue-form-generator
          :schema="formSchema"
          :model="formModel"
        ></vue-form-generator>
        <div class="form-group col-md-6 pt-3 pb-0 mb-0 field-input">
          <Button
            text="Submit"
            type="submit"
            styling="primary"
            validateBeforeSubmit
            :disabled="isDisabled"
          ></Button>
        </div>
      </form>
    </template>
  </Modal>
</template>

<script>
import Modal from '../../components/Modal/Modal.vue';
import Button from '../../components/Button/Button.vue';
import {
  extraChargeSchema,
  extraChargeModel,
} from '../../formSchemas/extraCharge';
import ErrorAlert from '../../components/Alert/ErrorAlert.vue';

export default {
  name: 'AddExtraChargeModal',
  emits: ['close-modal'],
  props: ['loanRef', 'loanUrl', 'display', 'formErrors', 'repaymentChannel'],
  components: { Modal, ErrorAlert, Button },
  data() {
    return {
      formModel: {},
      formSchema: {},
      loanLimit: null,
      isLoading: false,
      fileUrl: {},
      fileName: '',
    };
  },
  computed: {
    show: {
      get() {
        return this.display;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
    title() {
      return `Add extra charge for ${this.loanRef}`;
    },
    isDisabled() {
      return this.isLoading;
    },
  },
  mounted() {
    this.formModel = extraChargeModel;
    this.formSchema = extraChargeSchema;
  },
  methods: {
    handleClick() {
      this.$confirm({
        message: `Are you sure you want to add the extra charge?`,
        button: {
          no: 'Cancel',
          yes: 'Yes',
        },
        callback: (confirm) => {
          if (confirm) {
            this.submitExtraCahrge();
          }
        },
      });
    },
    onFileChanged(newFile) {
      this.fileUrl = newFile;
      this.fileName = this.fileUrl.name;
    },
    submitExtraCahrge() {
      const { chargetype, amount } = this.formModel;

      const ref = this.loanRef;
      const data = {
        loan: this.loanUrl,
        charge_type: chargetype,
        amount,
      };

      this.isLoading = true;
      this.$store
        .dispatch(`loans/addExtraCharge`, {
          data,
          ref,
        })
        .then(() => {
          this.isLoading = false;
          this.formModel = {};
          this.$emit('close-modal');
        })
        .catch((err) => {
          this.isLoading = false;
          this.formErrors = err;
        });
    },
  },
};
</script>

<style scoped>
.capitalise {
  text-transform: capitalize;
}
</style>
