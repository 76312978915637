import getAllPartners from '../api/partners.api';

const state = () => ({
  allPartners: [],
});

const getters = {
  allPartners: (state) => state.allPartners,
  partnerGivenName: (state) => (partnerName) =>
    state.allPartners.find(({ name }) => name.toUpperCase() === partnerName),
  partnerUsingId: (state) => (partnerid) =>
    state.allPartners.find(({ id }) => id === partnerid),
};

const actions = {
  async fetchPartners({ commit }) {
    try {
      const response = await getAllPartners();
      const partnersPages = response.data.pages;
      const allPartnersList = [];
      for (let i = 1; i <= partnersPages; i += 1) {
        // eslint-disable-next-line no-await-in-loop
        const resp = await getAllPartners(i);
        resp.data.results.forEach((partner) => {
          allPartnersList.push(partner);
        });
      }
      commit('set_partners', allPartnersList);
    } catch (error) {
      commit(
        'common/error_snackbar',
        'Failed to fetch partners. Please try again later.',
        {
          root: true,
        },
      );
    }
  },
};

const mutations = {
  intial_state(state) {
    state.allPartners = [];
  },
  set_partners(state, data) {
    state.allPartners = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
