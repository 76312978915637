import {
  getAllRepayments,
  makeRepayment,
  reverseRepayment,
} from '../api/repayments.api';

const state = () => ({
  allRepayments: [],
});

const getters = {
  allRepayments: (state) =>
    state.allRepayments.length ? state.allRepayments : [],
  partnerGivenName: (state) => (partnerName) =>
    state.allPartners.find(({ name }) => name.toUpperCase() === partnerName),
  partnerUsingId: (state) => (partnerid) =>
    state.allPartners.find(({ id }) => id === partnerid),
};

const actions = {
  async fetchRepayments({ commit }, { isAdmin, partnerId }) {
    try {
      const response = await getAllRepayments(isAdmin, partnerId);
      commit('set_repayments', response.data);
    } catch (error) {
      // commit('resource_error');
    }
  },

  async makeRepayment({ commit }, { data }) {
    try {
      const response = await makeRepayment(data);
      commit('add_repayment', response.data);
      commit(
        'common/success_snackbar',
        `${response.data.amount.toLocaleString()} has been logged successfully for ` +
          `loan ${response.data.ref}`,
        {
          root: true,
        },
      );
      return response;
    } catch (errors) {
      const error = errors.response.data;
      throw Array.isArray(error) ? error : [error];
    }
  },

  async reverseRepayment({ commit }, { ref }) {
    try {
      await reverseRepayment(ref);
      commit('reverse_repayment', ref);
      commit('common/success_snackbar', 'Repayment reversed successfully', {
        root: true,
      });
    } catch (errors) {
      const error = errors.response.data;
      throw Array.isArray(error) ? error : [error];
    }
  },
};

const mutations = {
  intial_state(state) {
    state.allRepayments = [];
  },
  set_repayments(state, data) {
    state.allRepayments = data;
  },
  add_repayment(state, data) {
    state.allRepayments.push(data);
  },
  reverse_repayment(state, data) {
    state.allRepayments = state.allRepayments.filter(
      (repayment) => repayment.ref !== data.ref,
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
