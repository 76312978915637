import Vue from 'vue';
import { encrypt } from '../utils/cryptography';

const makeLoginRequest = (payload) =>
  Vue.http.post('/api/v2/token/create/', { data: encrypt(payload) });

const verifyToken = (data) => Vue.http.post('/api/v2/token/verify/', data);

const getRefreshToken = (data) => Vue.http.post('/api/v2/token/refresh/', data);

const validateToken = (data) => Vue.http.post('/api/v2/validate-token', data);

const changePassword = (data) =>
  Vue.http.post('/api/v2/auth/password-reset', { data: encrypt(data) });

const getResetPasswordLink = (data) =>
  Vue.http.post('/api/v2/auth/password-reset/token', data);

const validatePasswordResetToken = (token) =>
  Vue.http.post('/api/v2/auth/password-reset/validate', { token });

const refreshExpiredToken = (data) =>
  Vue.http.post('/api/v2/refresh-token', data);

const makeLogoutRequest = (data) =>
  Vue.http.post('/api/v2/token/invalidate/', { ...data });

export {
  makeLoginRequest,
  verifyToken,
  getRefreshToken,
  validateToken,
  changePassword,
  getResetPasswordLink,
  refreshExpiredToken,
  makeLogoutRequest,
  validatePasswordResetToken,
};
