import Vue from 'vue';

const getProducts = (page = 1) => Vue.http.get(`/api/v2/products?page=${page}`);

const createOrEditProduct = (data, method, ref = null) =>
  Vue.http({
    url: `/api/v2/products${ref ? `/${ref}` : ''}`,
    method,
    data,
  });

const deleteProduct = (ref) => Vue.http.delete(`/api/v2/products/${ref}`);

export { getProducts, deleteProduct, createOrEditProduct };
