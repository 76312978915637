import Vue from 'vue';
import Vuex from 'vuex';
import common from './common';
import auth from './auth';
import loans from './loans';
import partners from './partners';
import borrowers from './borrowers';
import products from './products';
import applications from './applications';
import dsrs from './dsrs';
import users from './users';
import usergroups from './usergroups';
import repayments from './repayments';
import kycs from './kycs';
import mpesapayments from './mpesapayments';
import leads from './leads';
import smstemplates from './smstemplates';
import sms from './sms';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    auth,
    common,
    loans,
    borrowers,
    products,
    partners,
    repayments,
    users,
    usergroups,
    applications,
    dsrs,
    kycs,
    mpesapayments,
    leads,
    smstemplates,
    sms,
  },
  strict: debug,
});
