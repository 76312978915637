<template>
  <PreAuthLayout>
    <template>
      <div v-if="!this.resetLinkSent">
        <h2 class="sign-in-txt">Reset Password</h2>
        <form action="" @submit.prevent="resetPassword" id="resetPasswordForm">
          <ErrorAlert
            v-if="this.formErrors.length > 0"
            :errors="this.formErrors"
          />
          <vue-form-generator
            :schema="resetSchema"
            :model="resetModel"
            :options="formOptions"
          ></vue-form-generator>
          <fieldset class="btn-login-container">
            <Button
              text="Send Reset Email"
              type="submit"
              styling="primary"
              class="btn--login"
              validateBeforeSubmit
            ></Button>
            Want to sign in?
            <router-link to="/login">Click Here</router-link>
          </fieldset>
        </form>
      </div>

      <div v-if="this.resetLinkSent">
        <h2>Password Reset Link Sent!</h2>
        If you do not get an email in the next 5 minutes please
        <a href="#" @click="resetLinkSent = false">try again</a>
      </div>
    </template>
  </PreAuthLayout>
</template>

<script>
import Button from '../../components/Button/Button.vue';
import PreAuthLayout from '../../components/PreAuthLayout/PreAuthLayout.vue';
import ErrorAlert from '../../components/Alert/ErrorAlert.vue';
import resetPasswordSchema from '../../formSchemas/resetPasswordForm';

export default {
  // TODO: Fix the actual link that goes to the reset password form
  name: 'ResetPassword',
  components: {
    Button,
    ErrorAlert,
    PreAuthLayout,
  },
  data() {
    return {
      resetModel: { email: '' },
      resetSchema: resetPasswordSchema,
      resetLinkSent: false,
      formErrors: [],
      formOptions: {
        validateAfterChanged: true,
        validateDebounceTime: 1000,
      },
    };
  },
  methods: {
    resetPassword() {
      this.formErrors = [];
      this.$store
        .dispatch('auth/resetPassword', this.resetModel)
        .then(() => {
          this.resetLinkSent = true;
        })
        .catch((err) => {
          this.formErrors = err;
        });
    },
  },
};
</script>

<style lang="scss">
h2.sign-in-txt {
  padding: 20px 0 32px 6px;
}

.btn-login-container {
  // padding: 2px 20px;
  border: none;
  button {
    width: 100%;
    margin-bottom: 20px;
  }
}

.login {
  width: 100%;
}
</style>
