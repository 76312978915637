<template>
  <Modal
    :openModal="display"
    v-model="show"
    v-on:close-modal="$emit('close-modal')"
    :title="title"
    class="col-6"
  >
    <template v-slot:content>
      <form autocomplete="off" action="" @submit.prevent="submitRequest">
        <input type="hidden" autocomplete="false" />
        <ErrorAlert
          v-if="formErrors ? formErrors.length > 0 : false"
          :errors="formErrors"
          sticky
        />

        <vue-form-generator
          :schema="formSchema"
          :model="formModel"
        ></vue-form-generator>
        <Button
          text="Submit"
          type="submit"
          styling="primary"
          validateBeforeSubmit
          :disabled="isDisabled"
        ></Button>
      </form>
    </template>
  </Modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Modal from '../../components/Modal/Modal.vue';
import Button from '../../components/Button/Button.vue';
import { editDSRSchema, editDSRModel } from '../../formSchemas/dsrs/dsrForm';
import ErrorAlert from '../../components/Alert/ErrorAlert.vue';

const initialData = () => ({
  formErrors: [],
  formModel: editDSRModel,
  formSchema: editDSRSchema,
  title: 'Edit DSR',
});

export default {
  name: 'DSRForm',
  emits: ['close-modal'],
  props: ['dsr', 'display'],
  components: { Modal, ErrorAlert, Button },
  data() {
    return initialData();
  },
  computed: {
    isDisabled() {
      return this.isLoading;
    },
    show: {
      get() {
        return this.display;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
    ...mapGetters('usergroups', ['usergroups']),
  },
  watch: {
    dsr: {
      handler() {
        this.checkDSR();
      },
      deep: true,
    },
    display: {
      handler() {
        if (!this.display) {
          Object.assign(this.$data, initialData());
        }
      },
    },
  },
  async mounted() {
    await this.fetchUserGroups();
    this.formModel = editDSRModel;
    this.formSchema = editDSRSchema;
  },
  methods: {
    ...mapActions('usergroups', ['fetchUserGroups']),

    validateSelection(selection) {
      this.selected = selection;
    },
    checkDSR() {
      if (this.dsr !== null) {
        this.title = `Edit ${this.dsr.first_name}`;
        this.formModel = { ...this.dsr };
      }
    },
    addIdToDropdownOptions(options) {
      const data = [];
      if (options) {
        options.results.forEach((option) => {
          const entry = option.name;
          data.push(entry);
        });
      }
      return data;
    },
    submitRequest() {
      const groupsList = [];
      this.formErrors = [];
      const formCopy = { ...this.formModel };
      const method = formCopy.url ? 'PATCH' : 'POST';
      const finalObject = this.formModel;
      groupsList.push(finalObject.groups);
      finalObject.groups = groupsList;
      this.isLoading = true;
      const payload = {
        id: this.dsr.id,
        url: this.dsr.url,
        username: this.formModel.username,
        first_name: this.formModel.first_name,
        last_name: this.formModel.last_name,
        groups: this.dsr.groups,
        is_active: this.dsr.is_active,
      };
      const ref = method === 'PATCH' ? this.dsr.id : '';
      this.$store
        .dispatch(`dsrs/createOrEditDSR`, {
          data: payload,
          method,
          ref,
        })
        .then(() => {
          this.isLoading = false;
          this.formModel = {};
          this.$emit('close-modal');
        })
        .catch((err) => {
          this.isLoading = false;
          this.formErrors = err;
        });
    },
  },
};
</script>

<style lang="scss">
.capitalise {
  text-transform: capitalize;
}
.span {
  margin-left: 5px;
}

.dropdown {
  display: block;
  margin-bottom: 12px !important;
  .dropdown-input {
    min-width: 100% !important;
    max-width: 100% !important;
    background-image: none !important;
    border-radius: 24px !important;
    border: 1px solid rgb(14, 151, 71) !important;
  }
}

#__lpform_dropdown_icon {
  display: none !important;
}
</style>
