import Vue from 'vue';

const getLoan = (ref) => {
  return Vue.http.get(`/api/v2/loans/${ref}`);
};

const getFilteredLoans = (status, page = 1, searchTerm = '') => {
  let url = `/api/v2/loans?page=${page}&status=${status}`;
  if (searchTerm) {
    url = `${url}&search=${searchTerm}`;
  }
  return Vue.http.get(url);
};

const getOutstandingLoanBalance = (loanRef, date) => {
  return Vue.http.get(
    `/api/v2/loans/${loanRef}/outstanding_balance?date=${date}`,
  );
};

const actOnLoan = (loanRef, loanAction) => {
  return Vue.http.patch(`/api/v2/loans/${loanRef}/${loanAction}`);
};

const disburseLoan = (payload) => {
  const { ref, data } = payload;
  return Vue.http({
    url: `/api/v2/loans/${ref}/disburse`,
    method: 'PATCH',
    data,
  });
};

const createOrEditLoanComment = (data, method, ref = null) => {
  const url = `/api/v2/loancomments${method === 'PATCH' ? `/${ref}` : ''}`;
  return Vue.http({
    url,
    method,
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const addExtraCharge = (data) => {
  return Vue.http.post(`/api/v2/extra_charges`, data);
};

const postWaiver = (data) => {
  return Vue.http.post(`/api/v2/waivers`, data);
};

export {
  getOutstandingLoanBalance,
  actOnLoan,
  disburseLoan,
  getFilteredLoans,
  getLoan,
  addExtraCharge,
  createOrEditLoanComment,
  postWaiver,
};
