<template>
  <div class="width100">
    <AdvancedAccountSearch
      v-if="showAdvancedSearch"
      v-on:search="searchBorrower"
      v-on:clear="onClearClicked"
      class="ma-auto"
    ></AdvancedAccountSearch>
    <!-- SEARCH FIELDS -->
    <v-row class="row ma-auto pt-0">
      <v-col>
        <v-btn
          v-bind:class="{ active: showAdvancedSearch }"
          class="mr-3"
          text
          @click="toggleAdvancedSearch"
        >
          Filter Borrowers
          <v-icon dark right> mdi-filter-outline</v-icon>
        </v-btn>
      </v-col>
      <v-col class="d-flex justify-end">
        <v-btn
          color="primary"
          @click="$emit('confirm', selected, smsSelectionObject)"
          :disabled="!selected.length"
        >
          Next
        </v-btn>
      </v-col>
    </v-row>

    <!-- ACCOUNTS LIST -->
    <Table
      :tableData="borrowers.results"
      :options.sync="options"
      :selected.sync="selected"
      :allowSelection="true"
      :serverItemsLength="borrowers.count"
      :itemsPerPage="borrowers.per_page"
      :headers="headers"
      v-on:updated-options="fetchActiveBorrowersWithPage"
      v-on:selected-options="onSelectionChanged"
      class="elevation-0"
    >
    </Table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Table from '../../../components/Table/Table.vue';
import AdvancedAccountSearch from '../../BorrowerProfile/AdvancedAccountSearch.vue';

export default {
  name: 'SelectRetailersStep',
  components: {
    Table,
    AdvancedAccountSearch,
  },
  data() {
    return {
      showAdvancedSearch: false,
      options: {},
      searchTerm: '',
      selected: [],
      searchParams: {},
      smsSelectionObject: {},
      singleSelect: false,
      allBorrowersSelected: false,
      smsFiltersApplied: false,
      borrowers_per_page: 0,
      headers: [
        {
          value: 'name',
          text: 'Borrower name',
        },
        {
          value: 'phone',
          text: 'Phone Number',
        },
        {
          value: 'partner',
          text: 'Distributor',
        },
        {
          value: 'ref',
          text: 'Borrower Ref',
        },
        {
          value: 'partner_defined_id',
          text: 'KE Number',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('auth', ['isAdmin']),
    ...mapGetters('borrowers', ['borrowers']),
    ...mapGetters('borrowers', ['sendSmsBorrowersList']),
    ...mapGetters('smstemplates', ['currentTemplate']),
    ...mapGetters('sms', ['sendSmsFilterFields']),
    perPage() {
      return 1;
    },
  },
  methods: {
    ...mapActions('borrowers', ['fetchBorrowers']),
    onSelectionChanged() {
      this.selected = (this.borrowers.count && this.sendSmsBorrowersList) || [];
      const selectedAll =
        this.borrowers.per_page === this.sendSmsBorrowersList.length ||
        this.borrowers.count === this.sendSmsBorrowersList.length;
      this.smsSelectionObject = {
        searchParams: this.searchParams,
        selectedBorrowersCount: selectedAll
          ? this.borrowers.count
          : this.sendSmsBorrowersList.length,
        selectedAll,
      };
    },
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },
    toggleAdvancedSearch() {
      this.showAdvancedSearch = !this.showAdvancedSearch;
    },

    async fetchActiveBorrowersWithPage(options) {
      const status = 'active';
      const { page } = options;
      await this.fetchBorrowers({ status, page, searchTerm: this.searchTerm });
    },

    async searchBorrower(searchParams) {
      this.selected = [];
      this.searchParams = searchParams || {};
      const status = searchParams.status || '';
      await this.fetchBorrowers({
        status,
        searchTerm: this.searchTerm,
        searchParams,
      });
    },

    async onClearClicked() {
      await this.fetchBorrowers();
    },
  },
};
</script>

<style scoped>
.v-btn {
  color: #699a4c;
}
.active {
  background-color: #699a4c25;
}
</style>
