var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"width100"},[_c('PageHeader',{attrs:{"headingText":"Borrower Profile","breadcrumbs":_vm.items}}),_c('v-layout',{staticClass:"ma-4",attrs:{"justify-center":"","flex-column":"","align-stretch":""}},[_c('v-card',{staticClass:"ma-auto col-12 pl-7",attrs:{"id":"mpesadetailspage","elevation":"2"}},[_c('v-card-title',{staticClass:"pt-0 pb-0",attrs:{"id":"cardTitle"}},[_c('div',{staticClass:"d-flex flex-column"},[_c('v-card-subtitle',{staticClass:"pb-0 pl-0"}),_c('p',{staticClass:"capitalise"},[_vm._v(" "+_vm._s(_vm.item.channel)+" payment details - mpesa code: "+_vm._s(_vm.item.merchant_request_id.toUpperCase())+" ")])],1),_c('v-spacer'),_c('v-row',{attrs:{"align":"center","justify":"end"}},[(_vm.item.isSuccessFull !== true && _vm.item.channel === 'paybill')?_c('v-btn',{attrs:{"color":"primary","dark":""},on:{"click":_vm.openReconForm}},[_vm._v(" Complete ")]):_vm._e()],1)],1),_c('v-list',[_c('v-list-item',[_c('v-simple-table',{staticClass:"pr-10",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('tr',[_c('td',[_vm._v("Amount (Kshs)")]),_c('td',[_vm._v(_vm._s(_vm.item.amount))])]),_c('tr',[_c('td',[_vm._v("Phone Number")]),_c('td',[_vm._v(_vm._s(_vm.item.phone_number))])]),_c('tr',[_c('td',[_vm._v("Merchant Request ID")]),_c('td',[_vm._v(_vm._s(_vm.item.merchant_request_id))])]),_c('tr',[_c('td',[_vm._v("Paid On")]),_c('td',[_vm._v(_vm._s(_vm.item.timestamp))])]),_c('tr',[_c('td',[_vm._v("Transaction Description")]),_c('td',[_vm._v(_vm._s(_vm.item.narrative))])])])]},proxy:true}])}),_c('v-simple-table',{staticClass:"pr-10",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('tr',[_c('td',[_vm._v("Account")]),_c('td',[_vm._v(_vm._s(_vm.item.loan_account_number))])]),_c('tr',[_c('td',[_vm._v("Loan Ref")]),_c('td',[_vm._v(_vm._s(_vm.item.loan_ref))])]),_c('tr',[_c('td',[_vm._v("Loan Reconciled")]),_c('td',{style:([
                      _vm.item.isSuccessFull
                        ? {
                            color: '#699a4c',
                            'font-weight': '600 !important',
                          }
                        : {
                            color: '#da371e',
                            'font-weight': '600 !important',
                          } ])},[_vm._v(" "+_vm._s(_vm.item.isSuccessFull)+" ")])]),_c('tr',[_c('td',[_vm._v("Compeleted On")]),_c('td',[_vm._v(_vm._s(_vm.item.completed_date))])]),_c('tr',[_c('td',[_vm._v("Channel")]),_c('td',[_vm._v(_vm._s(_vm.item.channel))])])])]},proxy:true}])})],1)],1)],1),_c('PaymentReconForm',{attrs:{"paymentItem":this.paymentProp,"display":this.showReconDialog},on:{"close-modal":function($event){_vm.showReconDialog = false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }