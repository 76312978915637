import Vue from 'vue';

const verifyDmsRefresh = (data) => {
  const url = `/api/v2/purchasehistory`;

  return Vue.http({
    url,
    method: 'POST',
    data,
  });
};

const executeDmsRefresh = (data) => {
  const url = `/api/v2/purchasehistory/dms_data_refresh`;

  return Vue.http({
    url,
    method: 'POST',
    data,
  });
};

// eslint-disable-next-line import/prefer-default-export
export { verifyDmsRefresh, executeDmsRefresh };
