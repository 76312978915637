<template>
  <div class="width100">
    <PageHeader
      headingText="Borrower Profiles"
      :breadcrumbs="breadcrumbs"
    ></PageHeader>
    <v-layout justify-center flex-column align-stretch class="ma-4">
      <v-tabs v-model="tab" class="col-12">
        <template>
          <v-tab @click="setSelectedComponent('account-details')"
            >Details</v-tab
          >
          <v-tab @click="setSelectedComponent('borrower-loans')">LOANS</v-tab>
          <v-tab @click="setSelectedComponent('kyc-documents')"
            >KYC Documents</v-tab
          >
          <v-tab-item class="ma-4">
            <account-details
              v-if="selectedComponent === 'account-details'"
              v-bind:currentBorrower="this.currentBorrower"
            >
            </account-details>
          </v-tab-item>

          <v-tab-item>
            <borrower-loans
              v-if="selectedComponent === 'borrower-loans'"
              :borrowerRef="borrowerRef"
            ></borrower-loans>
          </v-tab-item>

          <v-tab-item>
            <kyc-documents
              v-if="selectedComponent === 'kyc-documents'"
              :borrowerRef="borrowerRef"
            ></kyc-documents>
          </v-tab-item>
        </template>
      </v-tabs>
    </v-layout>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PageHeader from '../../components/PageHeader/PageHeader.vue';
import AccountDetails from './AccountDetails.vue';
import BorrowerLoans from './BorrowerLoans.vue';
import KycDocuments from './KycDocuments.vue';

export default {
  name: 'BorrowerProfile',
  components: {
    PageHeader,
    AccountDetails,
    BorrowerLoans,
    KycDocuments,
  },
  data() {
    return {
      tab: null,
      selectedComponent: 'account-details',
      borrowerRef: this.$route.params.ref,
      breadcrumbs: [
        { text: 'Borrower', disabled: false, href: '#/accounts' },
        {
          text: this.$route.params.ref,
          disabled: true,
          href: '',
        },
      ],
    };
  },
  beforeMount() {
    this.fetchBorrower(this.borrowerRef);
  },
  methods: {
    ...mapActions('borrowers', ['fetchBorrower']),
    setSelectedComponent(component) {
      this.selectedComponent = component;
    },
  },
  computed: {
    ...mapGetters('auth', ['isAdmin']),
    ...mapGetters('borrowers', ['currentBorrower']),
  },
};
</script>
