<template>
  <div class="width100">
    <v-card-title> {{ currentBorrower.name }} Loans </v-card-title>

    <Table
      :tableData="borrowerLoans.results"
      :options.sync="options"
      :serverItemsLength="borrowerLoans.count"
      :itemsPerPage="borrowerLoans.per_page"
      :headers="headers"
      v-on:updated-options="fetchBorrowerLoansWithPage(borrowerRef)"
      class="ma-auto"
    >
      <template v-slot:actions="slotProps">
        <v-btn
          class="no-uppercase"
          @click="openLoan(slotProps.item, borrowerRef)"
          text
        >
          View Details
        </v-btn>
      </template>
    </Table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Table from '../../components/Table/Table.vue';

export default {
  name: 'Accounts',
  components: {
    Table,
  },
  props: ['borrowerRef'],
  data() {
    return {
      options: {},
      searchTerm: '',
      ref: '',
      loanRefProp: '',
      showLoanDetailModal: false,
      headers: [
        {
          value: 'ref',
          text: 'Loan Ref',
        },
        {
          value: 'status',
          text: 'Loan Status',
        },
        {
          value: 'principal',
          text: 'Loan Amount',
        },
        {
          value: 'disbursement_date',
          text: 'Date Disbursed',
        },
        {
          value: 'loan_period',
          text: 'Duration',
          sortable: true,
          searchable: true,
        },
        {
          value: 'interest_rate',
          text: 'Interest Rate',
        },
        {
          value: 'due_date',
          text: 'Due Date',
        },
        {
          value: 'total_expected_repayment',
          text: 'Repayment Amount',
        },
        {
          value: 'action',
          sortable: true,
          searchable: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters('borrowers', ['borrowerLoans']),
    ...mapGetters('borrowers', ['currentBorrower']),
  },
  methods: {
    ...mapActions('borrowers', ['fetchBorrowerLoans']),

    async fetchBorrowerLoansWithPage(borrowerRef) {
      this.ref = borrowerRef;
      await this.fetchBorrowerLoans(this.ref);
    },

    openLoan(item, borrowerRef) {
      this.$router.push({
        path: `/borrowerprofile/${borrowerRef}/loan/${item.ref}`,
        params: { ref: borrowerRef, loanRef: item.ref },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.wrapper {
  margin: 0 35px;
  padding-left: 28px;
  padding-bottom: 40px;
  margin-top: 50px;
}
.title {
  margin-left: 30px;
}

.v-input {
  padding-left: 40%;
  margin-top: 0;
}
.v-application p {
  padding-left: 50px;
}
.no-uppercase {
  text-transform: unset !important;
}
.left {
  padding: 1em;
}
.v-btn {
  color: #699a4c;
}
.right {
  padding: 1em;
}
.parent {
  padding: 0 12px;
  background-color: #fff;
}

#parent4 {
  justify-content: space-between;
}
</style>
