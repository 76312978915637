<template>
  <Modal
    :openModal="display"
    v-model="show"
    v-on:close-modal="$emit('close-modal')"
    :title="title"
    class="col-6"
  >
    <template v-slot:content>
      <form action="" @submit.prevent="handleClick" id="repaymentForm">
        <ErrorAlert
          v-if="formErrors ? formErrors.length > 0 : false"
          :errors="formErrors"
          sticky
        />
        <div v-if="loanLimit" class="ml-2 mb-4">
          <b>
            <i> Loan Limit: {{ loanLimit.toLocaleString() }} </i>
          </b>
        </div>
        <vue-form-generator
          :schema="formSchema"
          :model="formModel"
        ></vue-form-generator>
        <Button
          text="Submit"
          type="submit"
          styling="primary"
          validateBeforeSubmit
          :disabled="isDisabled"
        ></Button>
      </form>
    </template>
  </Modal>
</template>

<script>
import Modal from '../../components/Modal/Modal.vue';
import Button from '../../components/Button/Button.vue';
import {
  repaymentSchema,
  repaymentModel,
} from '../../formSchemas/makeRepayment';
import ErrorAlert from '../../components/Alert/ErrorAlert.vue';

export default {
  name: 'MakeRepaymentModal',
  emits: ['close-modal'],
  props: ['loanRef', 'display', 'formErrors', 'repaymentChannel'],
  components: { Modal, ErrorAlert, Button },
  data() {
    return {
      formModel: {},
      formSchema: {},
      loanLimit: null,
      isLoading: false,
    };
  },
  computed: {
    show: {
      get() {
        return this.display;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
    title() {
      return `Make repayment for loan ${this.loanRef}`;
    },
    isDisabled() {
      return this.isLoading;
    },
  },
  mounted() {
    this.formModel = repaymentModel;
    this.formSchema = repaymentSchema;
  },
  methods: {
    handleClick() {
      this.$confirm({
        message: `Are you sure you want to log this repayment?`,
        button: {
          no: 'Cancel',
          yes: 'Yes',
        },
        callback: (confirm) => {
          if (confirm) {
            this.submitRepayment();
          }
        },
      });
    },
    submitRepayment() {
      const { date, repaymentChannel, ...rest } = this.formModel;

      const data = {
        ...rest,
        repayment_channel: repaymentChannel,
        repayment_date: date,
        loan: `${process.env.VUE_APP_BASE_URL}/api/v2/loans/${this.loanRef}`,
      };

      this.isLoading = true;
      this.$store
        .dispatch(`repayments/makeRepayment`, {
          data,
        })
        .then(() => {
          this.isLoading = false;
          this.$emit('close-modal');
        })
        .catch((err) => {
          this.isLoading = false;
          this.formErrors = err;
        });
    },
  },
};
</script>

<style scoped>
.capitalise {
  text-transform: capitalize;
}
</style>
