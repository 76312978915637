<template>
  <div class="width100">
    <PageHeader headingText="Mpesa Repayments"></PageHeader>
    <v-layout justify-center flex-column align-stretch class="ma-4">
      <!-- SEARCH FIELDS -->
      <div class="col-12 ma-auto">
        <AdvancedPaymentSearch
          v-if="showAdvancedSearch"
          v-on:search="searchPayment"
          v-on:clear="onClearClicked"
        ></AdvancedPaymentSearch>

        <div class="d-flex justify-space-between flex-wrap col-12 parent">
          <v-btn
            v-bind:class="{ active: showAdvancedSearch }"
            class="no-uppercase ma-3"
            text
            @click="toggleAdvancedSearch"
          >
            Advanced Search
            <v-icon dark right> mdi-filter-outline</v-icon>
          </v-btn>
          <v-text-field
            class="col-6"
            v-model="searchTerm"
            placeholder="Enter a search term"
            append-outer-icon="search"
            clearable
            @keydown.enter="searchPayment"
            @click:append-outer="searchPayment"
            @click:clear="onClearClicked"
          >
          </v-text-field>
        </div>
      </div>
      <Table
        :tableData="this.mpesapayments.results"
        :headers="this.headers"
        :options.sync="options"
        :serverItemsLength="this.mpesapayments.count"
        :itemsPerPage="this.mpesapayments.per_page"
        v-on:item-details="openRepaymentDetails"
        v-on:updated-options="fetchMpesaPayments"
        class="ma-auto"
      >
      </Table>
    </v-layout>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import PageHeader from '../../components/PageHeader/PageHeader.vue';
import Table from '../../components/Table/Table.vue';
import AdvancedPaymentSearch from './AdvancedPaymentSearch.vue';

export default {
  name: 'MpesaPayments',
  components: {
    PageHeader,
    Table,
    AdvancedPaymentSearch,
  },

  data() {
    return {
      options: {},
      searchTerm: '',
      showAdvancedSearch: false,
      headers: [
        {
          value: 'phone_number',
          text: 'Phone Number',
        },
        {
          value: 'amount',
          text: '(Kshs)',
        },
        {
          value: 'loan_account_number',
          text: 'Loan Account',
        },
        {
          value: 'merchant_request_id',
          text: 'Mpesa Code',
        },
        {
          value: 'channel',
          text: 'Mode',
        },
        {
          value: 'timestamp',
          text: 'Created on',
        },
        {
          value: 'loan_ref',
          text: 'Loan Ref',
        },
        {
          value: 'isSuccessFull',
          text: 'Is Completed?',
        },
        {
          value: 'completed_date',
          text: 'Completed on',
        },
      ],
    };
  },
  async mounted() {
    await this.fetchMpesaPayments();
  },
  computed: {
    ...mapGetters('auth', { isLoggedIn: 'isLoggedIn' }),
    ...mapGetters('mpesapayments', ['mpesapayments']),
  },
  methods: {
    ...mapActions('mpesapayments', ['fetchMpesaPayments']),

    toggleAdvancedSearch() {
      this.showAdvancedSearch = !this.showAdvancedSearch;
    },

    async fetchMpesaPaymentsPage(options) {
      const { page } = options;
      await this.fetchMpesaPayments({ page });
    },

    async searchPayment(searchParams) {
      await this.fetchMpesaPayments({
        searchTerm: this.searchTerm,
        searchParams,
      });
    },

    async onClearClicked() {
      await this.fetchMpesaPayments({ page: 1 });
    },

    openRepaymentDetails(item) {
      const dataToDisplay = item;
      this.$router.push({
        name: 'mpesapaymentsdetails',
        params: { dataToDisplay },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.parent {
  padding: 0 12px;
  background-color: #fff;
  border-radius: 5px;
  align-items: center;
}
</style>
