<template>
  <div class="login">
    <v-app-bar flat>
      <v-layout
        justify-space-between
        align-center
        flex-wrap
        class="ma-4 pt-10 mb-16 pt-4"
        style="margin-bottom: 40px"
      >
        <v-img
          :src="require('../../assets/logo.png')"
          max-height="70"
          max-width="200"
          alt="crediation logo"
        ></v-img>
        <a href="https://crediation.com">
          <Button
            text="Visit Website"
            styling="primary"
            class="d-none d-sm-flex"
          ></Button>
        </a>
      </v-layout>
    </v-app-bar>
    <v-layout
      justify-center
      align-center
      align-stretch
      flex-wrap
      class="ma-4 pt-10"
    >
      <v-col md="6" class="d-none d-md-flex">
        <v-col md="12">
          <v-img
            :src="require('../../assets/homepage_banner.png')"
            alt="home page banner"
          ></v-img>
        </v-col>
      </v-col>
      <v-col sm="12" md="6" class="d-flex justify-center">
        <v-col sm="12" md="9" class="d-flex flex-column align-self-center">
          <slot>
            <!-- CONTENT GOES HERE -->
          </slot>
        </v-col>
      </v-col>
    </v-layout>
  </div>
</template>

<script>
import Button from '../Button/Button.vue';

export default {
  name: 'PreAuthLayout',
  components: {
    Button,
  },
};
</script>
