<template>
  <v-icon
    small
    class="mr-1"
    @click="action(item)"
    style="font-size: 20px"
    :color="color"
  >
    {{ icon }}
  </v-icon>
</template>

<script>
export default {
  name: 'Action',
  props: {
    color: { type: String },
    item: { type: Object, required: true },
    icon: { type: String, required: true },
    action: { type: Function, required: true },
  },
};
</script>
