<template>
  <v-alert
    dense
    prominent
    type="error"
    :icon="false"
    :class="sticky ? 'sticky' : ''"
  >
    <div v-if="errors.length > 1">
      The following errors occured:
      <ul>
        <li v-for="err in errors" :key="err">{{ err }}</li>
      </ul>
    </div>
    <div v-if="errors.length === 1">{{ errors[0] }}</div>
  </v-alert>
</template>

<script>
export default {
  name: 'ErrorAlert',
  props: {
    errors: { type: Array, required: true },
    sticky: { type: Boolean, default: false },
  },
};
</script>

<style lang="scss" scoped>
.snackbar .v-snack__wrapper {
  border-radius: 10px;

  .v-snack__content {
    text-align: center;
    font-weight: 500;
  }
}

.v-alert.v-sheet.error {
  background: $red !important;
  font-weight: 500;
  border-radius: 10px;
}
.sticky {
  position: sticky;
  top: 0;
}
</style>
