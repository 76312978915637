const commentSchema = {
  fields: [
    {
      type: 'textArea',
      inputType: 'text',
      label: 'Comment',
      model: 'comment',
      placeholder: 'Enter comment',
      required: true,
      max: 1000,
      min: 5,
      validator: ['required'],
    },
    {
      type: 'input',
      inputType: 'text',
      label: 'Link',
      model: 'link',
      placeholder: 'For example: https://www.google.com',
      required: false,
      max: 150,
      min: 5,
    },
  ],
};

const commentModel = {
  id: '',
  comment: '',
  link: '',
  resource: '',
  created_at: '',
};

export { commentModel, commentSchema };
