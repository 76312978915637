<template>
  <PreAuthLayout>
    <template>
      <h2 class="sign-in-txt">Sign In</h2>
      <form action="" @submit.prevent="login" id="loginForm">
        <ErrorAlert
          v-if="this.formErrors.length > 0"
          :errors="this.formErrors"
        />
        <vue-form-generator
          :schema="signInFormSchema"
          :model="signInModel"
          :options="formOptions"
        ></vue-form-generator>
        <fieldset class="btn-login-container">
          <Button
            text="Login"
            type="submit"
            styling="primary"
            class="btn--login"
            validateBeforeSubmit
          ></Button>
          Forgot Password?
          <router-link to="/reset-password">Click Here</router-link>
        </fieldset>
      </form>
    </template>
  </PreAuthLayout>
</template>

<script>
import Button from '../../components/Button/Button.vue';
import PreAuthLayout from '../../components/PreAuthLayout/PreAuthLayout.vue';
import ErrorAlert from '../../components/Alert/ErrorAlert.vue';
import signInSchema from '../../formSchemas/signInForm';

export default {
  name: 'Login',
  components: {
    Button,
    ErrorAlert,
    PreAuthLayout,
  },
  data() {
    return {
      signInModel: { username: '', password: '' },
      signInFormSchema: signInSchema,
      formErrors: [],
      formOptions: {
        validateAfterChanged: true,
        validateDebounceTime: 1000,
      },
    };
  },
  methods: {
    login() {
      this.formErrors = [];
      this.$store
        .dispatch('auth/login', this.signInModel)
        .then(() => {
          this.$router.push('/');
        })
        .catch((err) => {
          this.formErrors = err;
        });
    },
  },
};
</script>

<style lang="scss">
h2.sign-in-txt {
  padding: 20px 0 32px 6px;
}

.btn-login-container {
  border: none;
  button {
    width: 100%;
    margin-bottom: 20px;
  }
}

.login {
  width: 100%;
}
</style>
