<template>
  <div class="width100">
    <PageHeader headingText="DSR Borrowers"></PageHeader>
    <v-layout justify-center flex-column align-stretch class="ma-4">
      <v-text-field
        v-model="searchTerm"
        placeholder="Enter a search term"
        append-outer-icon="search"
        clearable
        @keydown.enter="searchBorrower"
        @click:append-outer="searchBorrower"
        @click:clear="onClearClicked"
      >
      </v-text-field>
      <v-tabs v-model="tab" class="col-12">
        <v-tab
          v-for="item in tabItems"
          :key="item.tab"
          @click="changeTab(item.tab, item.firstClick)"
          >{{ item.tab }}</v-tab
        >
      </v-tabs>

      <v-tabs-items v-model="tab" class="ma-4">
        <v-tab-item v-for="item in tabItems" :key="item.tab">
          <v-card flat>
            <Table
              :tableData="getDSRBorrowers.results"
              :options.sync="options"
              :serverItemsLength="getDSRBorrowers.count"
              :itemsPerPage="getDSRBorrowers.per_page"
              :headers="headers"
              v-on:updated-options="getAllDSRBorrowers"
              class="ma-auto"
            >
              <template v-slot:actions="slotProps">
                <v-icon
                  small
                  class="mr-1"
                  @click="showBorrowerDetails(slotProps.item)"
                  style="font-size: 20px"
                  color="grey"
                >
                  mdi-eye
                </v-icon>
              </template>
            </Table>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-layout>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Table from '../../components/Table/Table.vue';
import PageHeader from '../../components/PageHeader/PageHeader.vue';

export default {
  name: 'Borrowers',
  components: {
    Table,
    PageHeader,
  },
  data() {
    return {
      tab: null,
      dsrPhone: this.$route.params.ref,
      filterModel: { status: '' },
      borrowerRef: '',
      borrowerName: '',
      showFormModal: false,
      showDetailsModal: false,
      showDeleteModal: false,
      showVetBorrowerModal: false,
      borrowerProp: null,
      createButtonText: null,
      options: {},
      searchTerm: '',
      headers: [
        {
          value: 'ref',
          text: 'Borrower ref',
          searchable: true,
          sortable: true,
        },
        {
          value: 'name',
          text: 'Borrower Name',
          searchable: true,
          sortable: true,
        },
        {
          value: 'loan_limit',
          text: 'Loan Limit',
          sortable: true,
        },
        {
          value: 'status',
          text: 'Status',
          sortable: true,
        },
        {
          value: 'partner_defined_id',
          text: 'KE Number',
          sortable: true,
        },
        {
          value: 'action',
          text: 'Actions',
        },
      ],
      tabItems: [
        {
          tab: 'active',
          firstClick: true,
        },
        {
          tab: 'awaiting_activation',
          firstClick: true,
        },
        {
          tab: 'inactive',
          firstClick: true,
        },
        {
          tab: 'blacklisted',
          firstClick: true,
        },
      ],
    };
  },
  async mounted() {
    this.getAllDSRBorrowers();
  },
  computed: {
    ...mapGetters('auth', ['isAdmin']),
    ...mapGetters('dsrs', ['getDSRBorrowers']),
  },
  methods: {
    ...mapActions('dsrs', ['fetchDSRBorrowers']),

    async getAllDSRBorrowers() {
      const status = this.tabItems[this.tab].tab;
      await this.fetchDSRBorrowers({ status, phone: this.dsrPhone });
    },
    toggleFilter() {
      this.showFilter = !this.showFilter;
    },
    showBorrowerDetails(borrower) {
      this.$router.push({
        path: `/borrowers/view/${borrower.ref}`,
        params: { ref: borrower.ref },
      });
    },

    async changeTab(status) {
      await this.fetchDSRBorrowers({
        phone: this.dsrPhone,
        searchTerm: this.searchTerm,
        status,
      });
    },

    async fetchBorrowersWithPage() {
      this.tabItems[this.tab].firstClick = false;
      const status = this.tabItems[this.tab].tab;
      // const { page } = options;
      await this.fetchDSRBorrowers({
        status,
        phone: this.dsrPhone,
        searchTerm: this.searchTerm,
      });
    },
    async searchBorrower() {
      this.tabItems[this.tab].firstClick = false;
      // const status = this.tabItems[this.tab].tab;
      await this.fetchDSRBorrowers({
        phone: this.dsrPhone,
        searchTerm: this.searchTerm,
      });
    },
    async onClearClicked() {
      this.tabItems[this.tab].firstClick = false;
      const status = this.tabItems[this.tab].tab;
      await this.fetchDSRBorrowers({ status, phone: this.dsrPhone });
    },
    openBorrowerForm(borrower = null) {
      this.$router.push({
        path: borrower
          ? `/borrowers/edit/${borrower.ref}`
          : '/borrowers/create',
        params: borrower ? { ref: borrower.ref } : {},
      });
      this.borrowerProp = borrower;
    },
    vetBorrower(borrower) {
      this.borrowerProp = borrower;
      this.showVetBorrowerModal = true;
    },
    openBorrowerDeleteConfirm(borrower) {
      this.borrowerProp = borrower;
      this.showDeleteModal = true;
    },
  },
};
</script>

<style scoped>
.v-input {
  padding-left: 16px;
}
.v-tabs {
  padding: 0 16px 0;
}
.v-text-field {
  width: 50%;
}
</style>
