<template>
  <Modal
    :title="title"
    :openModal="display"
    v-on:close-modal="$emit('close-modal')"
  >
    <template v-slot:content>
      <div v-if="!formModel"><h2>No Action Available</h2></div>
      <div v-if="formModel">
        <form action="" @submit.prevent="handleClick" id="PaymentReconForm">
          <ErrorAlert
            v-if="formErrors.length > 0"
            :errors="formErrors"
            sticky
          />
          <vue-form-generator
            :schema="formSchema"
            :model="formModel"
          ></vue-form-generator>
        </form>
      </div>
    </template>
    <template v-slot:footer>
      <Button
        text="Submit"
        type="submit"
        form="PaymentReconForm"
        styling="primary"
        validateBeforeSubmit
        :disabled="!isEnabled"
      ></Button>
    </template>
  </Modal>
</template>

<script>
import { mapGetters } from 'vuex';
import Modal from '../../components/Modal/Modal.vue';
import Button from '../../components/Button/Button.vue';
import ErrorAlert from '../../components/Alert/ErrorAlert.vue';

import {
  repaymentModel,
  repaymentSchema,
} from '../../formSchemas/reconPaymentForm';

const initialData = () => ({
  formErrors: [],
  formModel: repaymentModel,
  formSchema: repaymentSchema,
  title: 'Reconcile loan payment',
});

export default {
  name: 'PaymentReconForm',
  emits: ['close-modal'],
  props: ['paymentItem', 'display'],
  components: {
    Modal,
    Button,
    ErrorAlert,
  },
  data() {
    return initialData();
  },
  methods: {
    handleClick() {
      this.$confirm({
        message: `Are you sure you want to complete this repayment?`,
        button: {
          no: 'Cancel',
          yes: 'Yes',
        },
        callback: (confirm) => {
          if (confirm) {
            this.submitPaymentRecon();
          }
        },
      });
    },
    submitPaymentRecon() {
      this.formErrors = [];
      const paymentRef = this.paymentItem.merchant_request_id;
      const formCopy = { ...this.formModel };
      const { loanRef, loanAccountNumber } = formCopy;

      const formData = new FormData();
      formData.set('loanRef', loanRef);
      formData.set('loanAccountNumber', loanAccountNumber);

      this.$store
        .dispatch(`mpesapayments/reconPayment`, {
          data: formData,
          ref: paymentRef,
        })
        .then(() => {
          this.$emit('close-modal');
        })
        .catch((err) => {
          this.formErrors = err;
        });
    },
    checkPaymentItem() {
      if (this.paymentItem !== null) {
        this.title = `Complete payment for ${this.paymentItem.merchant_request_id} payment code`;
      }
    },
  },
  watch: {
    paymentItem: {
      handler() {
        this.checkPaymentItem();
      },
      deep: true,
    },
  },
  mounted() {
    if (!this.paymentItem) this.$emit('close-modal');
    else this.checkPaymentItem();
  },
  computed: {
    ...mapGetters('auth', { isLoggedIn: 'isLoggedIn' }),
    isEnabled() {
      const { loanRef, loanAccountNumber } = this.formModel;
      return loanRef.length > 6 && loanAccountNumber.length > 5;
    },
  },
};
</script>
