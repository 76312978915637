<template>
  <div class="width100">
    <v-layout justify-center flex-column align-stretch>
      <v-tabs v-model="tab">
        <v-tab
          v-for="item in tabItems"
          :key="item.tab"
          @click="changeTab(item.tab, item.firstClick)"
          >{{ item.tab }}</v-tab
        >
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item v-for="item in tabItems" :key="item.tab">
          <v-list-item-content v-if="selectedComponent === 'repayments'">
            <h4 class="pl-4 pb-4">Loan Repayments</h4>
            <v-data-table
              :headers="repaymentTableHeaders"
              :items="loan.repayments"
              class="elevation-0 py-3 border"
            >
            </v-data-table>
          </v-list-item-content>
          <v-list-item-content
            v-if="
              selectedComponent === 'feedback' ||
              selectedComponent === 'comments'
            "
          >
            <CommentsTable
              v-bind:showAddButton="currentBorrowerLoan.status !== 'repaid'"
              v-bind:commentType="selectedComponent"
              v-bind:comments="loan[item.tab]"
              v-bind:heading="title"
              v-bind:btnText="action"
              v-on:open-comment-detail="openCommentForm"
            ></CommentsTable>
          </v-list-item-content>
          <v-list-item-content v-if="selectedComponent === 'ptps'">
            <h4 class="pl-4 pb-4">Logged PTPs</h4>
            <v-data-table
              :headers="ptpTableHeaders"
              :items="loan.ptps"
              :hide-default-footer="true"
              class="elevation-0 py-3 border"
            >
            </v-data-table>
          </v-list-item-content>
          <v-list-item-content v-if="selectedComponent === 'messages'">
            <SMSTable />
          </v-list-item-content>
        </v-tab-item>
      </v-tabs-items>
      <CommentFormModal
        v-bind:comment="commentProp"
        v-bind:commentType="commentType"
        v-bind:resourceRef="currentBorrowerLoan.ref"
        v-bind:display="showCommentFormModal"
        v-on:close-modal="showCommentFormModal = false"
      ></CommentFormModal>
    </v-layout>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CommentsTable from './CommentsTable.vue';
import CommentFormModal from './CommentFormModal.vue';
import SMSTable from './Sms/Sms.vue';

export default {
  name: 'LoanComponentTabs',
  components: {
    CommentsTable,
    CommentFormModal,
    SMSTable,
  },
  props: ['loan'],
  data() {
    return {
      title: 'Loan Comments',
      action: 'Add Comment',
      tab: null,
      commentType: 'repayments',
      commentProp: null,
      selectedComponent: 'repayments',
      showCommentFormModal: false,
      repaymentTableHeaders: [
        { text: 'Repayment ref', align: 'start', value: 'ref' },
        { text: 'Repayment date', value: 'repayment_date' },
        { text: 'Amount repaid', value: 'amount' },
        { text: 'Channel', value: 'repayment_channel' },
        {
          text: 'Outstanding balance after repayment',
          value: 'outstanding_balance_after_repayment',
        },
      ],
      ptpTableHeaders: [
        { text: 'PTP due date', align: 'start', value: 'ptp_due' },
        { text: 'PTP Status', value: 'status' },
        { text: 'PTP Amount', value: 'ptp_amount' },
        { text: 'Booked on', value: 'created_at' },
        { text: 'Booked by', value: 'created_by' },
      ],
      tabItems: [
        {
          tab: 'repayments',
          firstClick: true,
        },
        {
          tab: 'comments',
          firstClick: true,
        },
        {
          tab: 'feedback',
          firstClick: true,
        },
        {
          tab: 'ptps',
          firstClick: true,
        },
        {
          tab: 'messages',
          firstClick: true,
        },
      ],
    };
  },
  methods: {
    changeTab(status, firstClick) {
      switch (status) {
        case 'repayments':
          this.title = 'Loan Repayments';
          this.selectedComponent = status;
          break;

        case 'comments':
          this.title = 'Loan Comments';
          this.action = 'Add Comment';
          this.commentType = 'comment';
          this.selectedComponent = status;
          break;

        case 'feedback':
          this.title = 'Loan Feedback';
          this.action = 'Add Feedback';
          this.commentType = 'feedback';
          this.selectedComponent = status;
          if (firstClick) {
            this.$store.dispatch(`borrowers/fetchLoanFeedback`, {
              loanId: this.loan.id,
            });
          }
          break;

        case 'ptps':
          this.title = 'Booked PTPs';
          this.selectedComponent = status;
          break;

        case 'messages':
          this.title = 'Booked PTPs';
          this.selectedComponent = status;
          if (firstClick) {
            this.$store.dispatch(`sms/fetchSmses`, {
              searchParams: {
                loan: this.loan.id,
              },
            });
          }
          break;

        default:
          break;
      }
    },
    openCommentForm(comment = null) {
      this.commentProp = comment;
      this.showCommentFormModal = true;
    },
  },
  computed: {
    ...mapGetters('borrowers', ['currentBorrowerLoan']),
  },
};
</script>

<style scoped lang="scss">
.border {
  box-shadow: 6px 6px 18px rgba(0, 0, 0, 0.1) !important;
  border-radius: 4px;
  padding: 0px 10px;
  widows: 95%;
  margin: 0 auto 20px;
}
</style>
