<template>
  <div class="width100">
    <PageHeader
      headingText="SMS Templates"
      :breadcrumbs="getBreadCrumbs"
    ></PageHeader>
    <v-layout justify-center flex-column align-stretch class="ma-4">
      <template>
        <div>
          <v-stepper v-model="e1" class="pt-5">
            <v-stepper-header class="elevation-0">
              <template v-for="n in steps">
                <v-stepper-step
                  :key="`Step${n}`"
                  :complete="e1 > n"
                  :step="n"
                  :editable="n === 1"
                >
                  {{ stepContent[n - 1]['title'] }}
                </v-stepper-step>

                <v-divider v-if="n !== steps" :key="n"></v-divider>
              </template>
            </v-stepper-header>

            <v-stepper-items class="pt-4">
              <v-stepper-content
                v-for="n in steps"
                :key="`${n}-content`"
                :step="n"
              >
                <div v-if="n === 1">
                  <SelectRetailersStep v-on:confirm="nextStep" />
                </div>

                <div v-if="n === 2">
                  <ConfirmationStep
                    :selected="selected"
                    :smsSelectionObject="smsSelectionObject"
                  />
                </div>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </div>
      </template>
    </v-layout>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PageHeader from '../../../components/PageHeader/PageHeader.vue';
import SelectRetailersStep from './SelectRetailersStep.vue';
import ConfirmationStep from './ConfirmationStep.vue';

export default {
  name: 'SendTemplateSms',
  components: {
    PageHeader,
    SelectRetailersStep,
    ConfirmationStep,
  },
  data() {
    const { templateId } = this.$route.params;
    return {
      templateId,
      selected: [],
      smsSelectionObject: {},
      e1: 1,
      steps: 2,
      stepContent: [
        {
          title: 'Select Borrowers',
          content:
            'Filter and selected borrowers who should receive the message',
        },
        {
          title: 'Schedule/Send Message',
          content: 'Confirm and send the message',
        },
      ],
      item: this.$route.params.dataToDisplay,
      showReviewDialog: false,
      templateProp: null,
      actionProp: null,
      expand: false,
    };
  },
  watch: {
    steps(val) {
      if (this.e1 > val) {
        this.e1 = val;
      }
    },
  },
  beforeMount() {
    this.fetchTemplate(this.templateId);
  },
  methods: {
    ...mapActions('smstemplates', ['fetchTemplate']),
    vetDebtCollectionCriteria(arr) {
      return (
        arr.includes('active') || arr.includes('due') || arr.includes('overdue')
      );
    },
    vetPTPRemiderCriteria(arr) {
      return (
        arr.includes('frozen') ||
        arr.includes('overdue') ||
        arr.includes('written off')
      );
    },
    nextStep(selected, smsSelectionObject) {
      const hasLoanStatus = this.vetDebtCollectionCriteria(
        this.sendSmsFilterFields.loanStatus,
      );
      const hasDueStatus = this.vetPTPRemiderCriteria(
        this.sendSmsFilterFields.loanStatus,
      );
      if (this.currentTemplate.category === 'Debt Collection') {
        if (hasLoanStatus) {
          this.selected = selected;
          this.smsSelectionObject = smsSelectionObject;
          this.e1 += 1;
        } else {
          // dispatch the alert here
          this.$store.dispatch('smstemplates/activeLoanNotSelectedError');
        }
      } else if (this.currentTemplate.category === 'PTP Reminder') {
        if (hasDueStatus) {
          this.selected = selected;
          this.smsSelectionObject = smsSelectionObject;
          this.e1 += 1;
        } else {
          // dispatch the action here
          this.$store.dispatch('smstemplates/overdueLoanNotSelectedError');
        }
      } else {
        this.selected = selected;
        this.smsSelectionObject = smsSelectionObject;
        this.e1 += 1;
      }
    },
    openReviewForm() {
      this.expand = !this.expand;
      this.templateProp = this.item;
      this.actionProp = 'Review';
      this.showReviewDialog = true;
    },
    openPublishForm() {
      this.expand = !this.expand;
      this.templateProp = this.item;
      this.actionProp = 'Publish';
      this.showReviewDialog = true;
    },
  },
  computed: {
    ...mapGetters('auth', { isSuperUser: 'isSuperUser' }),
    ...mapGetters('smstemplates', ['currentTemplate']),
    ...mapGetters('sms', ['sendSmsFilterFields']),
    getBreadCrumbs() {
      return [
        { text: 'SMS Template', disabled: false, href: '#/smstemplates' },
        {
          text: `${this.currentTemplate.name}`,
          disabled: true,
          href: '',
        },
      ];
    },
  },
};
</script>

<style scoped>
.v-stepper__content {
  padding: 8px 24px 16px;
}
.v-input[data-v-031390d0] {
  padding-left: 0;
  margin-top: 10px;
}
</style>
