const editDSRSchema = {
  fields: [
    {
      type: 'input',
      inputType: 'text',
      label: 'First Name',
      placeholder: 'Enter first name',
      model: 'first_name',
      required: true,
    },
    {
      type: 'input',
      inputType: 'text',
      label: 'Last Name',
      placeholder: 'Enter last name',
      model: 'last_name',
    },
    {
      type: 'input',
      inputType: 'text',
      label: 'Phone Number',
      placeholder: 'Enter phone number',
      model: 'username',
      required: true,
    },
  ],
};
const editDSRModel = {
  first_name: '',
  last_name: '',
  username: '',
};

export { editDSRSchema, editDSRModel };
