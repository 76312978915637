<template>
  <v-snackbar
    v-model="snackbar"
    :timeout="timeout"
    :top="true"
    :right="true"
    transition="slide-x-reverse-transition"
    :class="`snackbar snackbar--${isWarning ? 'warn' : 'success'}`"
  >
    {{ text }}
  </v-snackbar>
</template>

<script>
export default {
  name: 'SnackBar',
  props: {
    text: { type: String, required: true },
    timeout: {
      type: String,
      required: false,
      default: '3500',
      validator: (value) => {
        const num = Number.parseInt(value, 10);
        return num !== Number.isNaN() && num > -2 && num < 10000;
      },
    },
    isWarning: { type: Boolean, required: false, default: false },
    show: { type: Boolean, required: true },
  },
  data: () => ({
    snackbar: false,
  }),
  mounted() {
    this.snackbar = this.show;
  },
  watch: {
    snackbar(newVal) {
      if (!newVal) return this.$store.commit('common/hide_snackbar');
      return newVal;
    },
    show(newVal) {
      if (newVal) this.snackbar = true;
      else this.snackbar = false;
      return newVal;
    },
  },
};
</script>

<style lang="scss">
.snackbar .v-snack__wrapper {
  border-radius: 10px;

  .v-snack__content {
    text-align: center;
    font-weight: 500;
  }
}
.snackbar.snackbar--warn .v-snack__wrapper {
  background: $red;
}
.snackbar.snackbar--success .v-snack__wrapper {
  background: $primary-green;
}
</style>
