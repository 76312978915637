<template>
  <PreAuthLayout>
    <template>
      <div v-if="!this.resetLinkSent">
        <h2 class="sign-in-txt">Reset Password</h2>
        <p>Password Requirements</p>
        <ul>
          <li>At least 6 characters</li>
          <li>Should contain a special character</li>
          <li>Should contain a number</li>
        </ul>
        <form action="" @submit.prevent="changePassword" id="resetPasswordForm">
          <ErrorAlert
            v-if="this.formErrors.length > 0"
            :errors="this.formErrors"
          />
          <vue-form-generator
            :schema="resetSchema"
            :model="resetModel"
            :options="formOptions"
          ></vue-form-generator>
          <fieldset class="btn-login-container">
            <Button
              text="Update password"
              type="submit"
              styling="primary"
              class="btn--login"
              validateBeforeSubmit
            ></Button>
            Want to sign in?
            <router-link to="/login">Click Here</router-link>
          </fieldset>
        </form>
      </div>
    </template>
  </PreAuthLayout>
</template>

<script>
import Button from '../../components/Button/Button.vue';
import PreAuthLayout from '../../components/PreAuthLayout/PreAuthLayout.vue';
import ErrorAlert from '../../components/Alert/ErrorAlert.vue';
import changePasswordSchema from '../../formSchemas/changePasswordForm';

export default {
  // TODO: Fix the actual link that goes to the reset password form
  name: 'ChangePassword',
  components: {
    Button,
    ErrorAlert,
    PreAuthLayout,
  },
  data() {
    return {
      resetModel: { password: '', token: '' },
      resetSchema: changePasswordSchema,
      resetLinkSent: false,
      formErrors: [],
      formOptions: {
        validateAfterChanged: true,
        validateDebounceTime: 1000,
      },
    };
  },
  mounted() {
    const { token } = this.$route.query;
    if (!token) {
      this.$router.push({
        path: `/login`,
      });
    }

    this.$store.dispatch('auth/validateResetToken', token).catch((err) => {
      this.formErrors = err;
      this.$router.push({
        path: `/reset-password`,
      });
    });
  },
  methods: {
    validatePassword(password) {
      const passwordRegex = /^(?=.*[A-Za-z0-9])(?=.*[^A-Za-z0-9])(?=.*\d).{6,}$/;
      return passwordRegex.test(password);
    },
    changePassword() {
      this.formErrors = [];
      if (this.resetModel.confirmPassword !== this.resetModel.newPassword) {
        this.formErrors = ['The passwords do not match'];
        return;
      }
      this.resetModel.password = this.resetModel.newPassword;
      this.resetModel.token = this.$route.query.token;
      delete this.resetModel.newPassword;
      delete this.resetModel.confirmPassword;
      if (!this.validatePassword(this.resetModel.password)) {
        this.formErrors = ['Password criteria not met'];
        return;
      }
      this.$store
        .dispatch('auth/changePassword', this.resetModel)
        .then(() => {
          this.$router.push({
            path: `/login`,
          });
        })
        .catch((err) => {
          this.formErrors = err;
        });
    },
  },
};
</script>

<style lang="scss">
h2.sign-in-txt {
  padding: 20px 0 10px 6px;
}

p {
  padding: 0 12px;
  margin-bottom: 0 !important;
}

ul {
  margin-bottom: 20px;
}

li {
  line-height: 2em;
  list-style-type: disc;
}

.btn-login-container {
  // padding: 2px 20px;
  border: none;
  button {
    width: 100%;
    margin-bottom: 20px;
  }
}

.login {
  width: 100%;
}
</style>
