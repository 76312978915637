import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

// Disable button ripple effect
const VBtn = Vue.component('VBtn');
VBtn.options.props.ripple.default = false;

const vuetifyInstance = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#699A4C',
        secondary: '#050404',
        accent: '#F99D01',
        error: '#931621',
        info: '#84CAE7',
        success: '#699A4C',
        warning: '#E08E45',
      },
    },
  },
  icons: {
    iconfont: 'mdi', // default - only for display purposes
  },
});

export default vuetifyInstance;
