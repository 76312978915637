import Vue from 'vue';

const getApplications = (page = 1, searchTerm = '') => {
  let url = `/api/v2/loans?page=${page}&status=pending,approved`;
  if (searchTerm) {
    url = `${url}&search=${searchTerm}`;
  }
  return Vue.http.get(url);
};

const vetApplication = (refNo, payload) => {
  return Vue.http({
    url: `api/v2/loans/${refNo}/vet`,
    method: 'PATCH',
    data: payload,
  });
};

const createApplication = (payload) => {
  const data = payload;
  data.channel = 'portal';
  return Vue.http({
    url: `api/v2/loans`,
    method: 'POST',
    data,
  });
};

const uploadLoanTermsDocument = (payload) => {
  const data = payload;
  data.channel = 'portal';
  return Vue.http({
    url: `api/v2/loanterms_documents`,
    method: 'POST',
    data,
  });
};

export {
  getApplications,
  vetApplication,
  createApplication,
  uploadLoanTermsDocument,
};
