<template>
  <div class="width100">
    <PageHeader
      headingText="SMS Templates"
      :breadcrumbs="getBreadCrumbs"
    ></PageHeader>
    <v-layout justify-center flex-column align-stretch class="ma-4">
      <div class="elevation-1">
        <TemplateDetailCard :isReview="true" :smsTemplate="currentTemplate" />
      </div>
      <TemplateReviewForm
        v-bind:templateItem="this.templateProp"
        v-bind:display="this.showReviewDialog"
        v-on:close-modal="showReviewDialog = false"
        v-bind:action="this.actionProp"
      ></TemplateReviewForm>
    </v-layout>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import TemplateReviewForm from './TemplateReviewForm.vue';
import TemplateDetailCard from './TemplateDetailCard.vue';
import PageHeader from '../../components/PageHeader/PageHeader.vue';

export default {
  name: 'TemplateDetail',
  components: {
    TemplateReviewForm,
    TemplateDetailCard,
    PageHeader,
  },
  data() {
    const { templateId } = this.$route.params;
    return {
      templateId,
      showReviewDialog: false,
      templateProp: null,
      actionProp: null,
      expand: false,
    };
  },
  beforeMount() {
    this.fetchTemplate(this.templateId);
  },
  methods: {
    ...mapActions('smstemplates', ['fetchTemplate']),
    openReviewForm() {
      this.expand = !this.expand;
      this.templateProp = this.item;
      this.actionProp = 'Review';
      this.showReviewDialog = true;
    },
    openPublishForm() {
      this.expand = !this.expand;
      this.templateProp = this.item;
      this.actionProp = 'Publish';
      this.showReviewDialog = true;
    },
  },
  computed: {
    ...mapGetters('auth', { isSuperUser: 'isSuperUser' }),
    ...mapGetters('smstemplates', ['currentTemplate']),
    getBreadCrumbs() {
      return [
        { text: 'SMS Template', disabled: false, href: '#/smstemplates' },
        {
          text: `${this.currentTemplate.name}`,
          disabled: true,
          href: '',
        },
      ];
    },
  },
};
</script>

<style scoped>
.content {
  margin-right: 30px;
  padding-bottom: 30px;
}
.light {
  color: #8c98a4;
}
.dark {
  font-weight: 500;
}
.details {
  font-size: 15px;
  padding-right: 50px;
}
</style>
